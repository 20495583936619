import React from 'react';
import styles from './index.module.scss';
import classnames from 'classnames';
export default function Protocol(props) {
  return (
    <div className={styles.protocol}>
      <div className={styles.content}>
        <div className={styles.top}>
          <img
            onClick={() => props.history.push('/home')}
            src={require('@/assets/images/logo.png').default}
          />
          <span
            className={styles.btn}
            onClick={() => props.history.push('/home')}
          >
            {'<<'} 返回亞洲電視首頁
          </span>
        </div>
        <p className={styles.title}>用户服务协议</p>
        <p className={classnames(styles.right, styles.text, styles.jfsido)}>
          版本更新日期：2021年 1 月22日
        </p>
        <p className={classnames(styles.right, styles.text, styles.sdjfiosd)}>
          版本号：2021v1
        </p>
        <p>提示条款</p>
        <strong>
          欢迎您与各我方平台经营者
          （详见定义条款）共同签署本《用户服务协议》（下称“本协议”）并使用我方平台服务！
          各条款标题仅为帮助您理解该条款表达的主旨之用，不影响或限制本协议条款的含义或解释。为维护您自身权益，建议您仔细阅读各条款具体表述。
        </strong>
      </div>
    </div>
  );
}
