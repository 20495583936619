import React, { useEffect } from 'react';
import { connect } from 'dva';
import { message } from 'antd';
import { Header, LeftSide } from './components';
import { Switch, Route, Redirect } from 'dva/router';
import { isLogin } from '@/utils';
import MyFiles from './pages/Files';
import MyShare from './pages/Share';
import styles from './index.module.scss';

function Pan({ ...props }) {

  useEffect(() => {
    if (!isLogin()) {
      message.warning('未登录，请登录使用云盘')
      props.history.push('/');
    }
  }, [])

  return (
    <div className={styles.pan_page}>
      <Header onClick={() => props.history.push('/')} />
      <div className={styles.pan_wrapper}>
        <LeftSide {...props} />
        <div className={styles.pan_content}>
          <div className={styles.content_route}>
            <Switch>
              <Route component={MyFiles} path='/pan/files/:id' />
              <Route component={MyShare} path='/pan/share' />
              <Redirect to='/pan/files/0' />
            </Switch>
          </div>
          <div className={styles.footInfo}>Deer Network提供去中心化存储</div>
        </div>
      </div>
    </div>
  );
}
export default connect()(Pan);
