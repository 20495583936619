import React from 'react';
import { connect } from 'dva';
import ListOne from '../ListOne';
import ListTwo from '../ListTwo';
import ListThree from '../ListThree';
import LoadingLine from '../LoadingLine';
import { onToPlayer } from '../../../../utils';
import './index.scss';

function MainContent(props) {
  const wrapUis = [ListOne, ListTwo, ListThree];
  const getListUi = (index) => {
    return wrapUis[index % 3];
  };
  const { topicsDetaileds, loading, loadingB } = props;

  const isOneLine = topicsDetaileds.length == 1;
  const onUpdate = (index, id) => {
    props.dispatch({
      type: 'screen/updateTopicsDetailedItem',
      payload: {
        index,
        id,
      },
    });
  };

  return (
    <div className='main-content'>
      {topicsDetaileds
        .filter((i) => (i.data || []).length)
        .map((e, i) => {
          const Comp = getListUi(i);
          return (
            <Comp
              onUpdate={onUpdate}
              onToPlayer={(data) =>
                onToPlayer({
                  data,
                  history: props.history,
                  dispatch: props.dispatch,
                })
              }
              index={i}
              key={e.id}
              isOneList={isOneLine || e.infinite}
              {...e}
            />
          );
        })}

      {/*{topicsDetaileds.length === 0 ? (<Empty style={{width: 200}} description={''} image={Empty.PRESENTED_IMAGE_SIMPLE} />) : null}*/}
      {loading || loadingB ? <LoadingLine /> : null}
    </div>
  );
}

export default React.memo(
  connect((state) => {
    return {
      topicsDetaileds: state.screen.home.topics,
      loading: state.loading.effects['screen/getHomeAggregation'],
      loadingB: state.loading.effects['screen/getHomeTopicData'],
    };
  })(MainContent)
);
