import React, { useState } from 'react';
import ContentItemOne from '../ContentItemOne';
import ContentItemTwo from '../ContentItemTwo';
import { offsetIndexMove } from '@/utils';
import Loading from '@/components/Loading';
import { SpliceData } from '../../../../utils';
import { connect } from 'dva';
import classNames from 'classnames';
import './index.scss';

function ListTwo(props) {
  const { title, onToPlayer, onUpdate, loading, topicsDetailedsLoadingItem } =
    props;

  const COUNT = 13;
  const [offset, setOffset] = useState({ start: 0, end: COUNT });
  const newArr = SpliceData({
    datas: props.data,
    maxLength: offset.end,
    startIndex: offset.start,
  });

  const items = newArr[0];
  const data1 = newArr.slice(1, 1 + 6);
  const data2 = newArr.slice(1 + 6, 1 + 6 + 6);
  const onHuan = () => {
    if (loading) return;
    if (
      offset.end + COUNT > props.curCount * props.CurPage &&
      props.CurPage < props.TotalPage
    ) {
      onUpdate && onUpdate(props.index, props.id);
      setOffset({ start: offset.end, end: offset.end + COUNT });
    } else {
      const { startIndex, endIndex } = offsetIndexMove({
        start: offset.start,
        end: offset.end,
        num: COUNT,
        maxLength: props.data.length,
      });

      setOffset({ start: startIndex, end: endIndex });
    }
  };
  return (
    <div className='two content-list'>
      <div className='title'>
        <span className='title-text'>{title}</span>
        <div className={'huan_fds2s'} onClick={onHuan}>
          <div className={'huan_img_fdji2'} /> <span>換壹換</span>
        </div>
      </div>
      <div className={classNames('content', { loading: 'loading' })}>
        {loading && topicsDetailedsLoadingItem === props.id ? (
          <Loading />
        ) : (
          <>
            <div className='left'>
              <ContentItemTwo onToPlayer={onToPlayer} {...items} />
            </div>
            <div className='right'>
              <div style={{ display: 'flex' }}>
                {data1.map((e) => {
                  return (
                    <ContentItemOne onToPlayer={onToPlayer} key={e.ID} {...e} />
                  );
                })}
              </div>
              <div style={{ display: 'flex' }}>
                {data2.map((e) => {
                  return (
                    <ContentItemOne onToPlayer={onToPlayer} key={e.ID} {...e} />
                  );
                })}
              </div>
            </div>
          </>
        )}
      </div>
    </div>
  );
}

export default connect((state) => ({
  loading: state['loading']['effects']['screen/updateTopicsDetailedItem'],
  topicsDetailedsLoadingItem: state.screen.topicsDetailedsLoadingItem,
}))(ListTwo);
