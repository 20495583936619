import React from 'react';
import styles from './index.module.scss';

export default function ({ getRightIcon, onClickIcon, ...props }) {
  return (
    <div className={styles.input} style={props.style}>
      <input {...props} className={styles.input_fdsfds} />
      <div className={styles.line}></div>
      <div
        className={styles.right}
        onClick={(_) => onClickIcon && onClickIcon()}
      >
        {getRightIcon && getRightIcon()}
      </div>
    </div>
  );
}
