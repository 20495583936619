import { createComment } from '@/api';

// 管理全局加载状态
export default {
  namespace: 'comment',
  state: {},
  reducers: {},
  effects: {
    *createComment({ payload }, { call, put, apply }) {
      const res = yield call(createComment, payload);
      return res;
    },
  },
};
