import {
  getUserData,
  registerSendCode,
  signInVerifyCode,
  userLoginOrEmail,
  userRegister,
} from '@/api';
import Toast from '../components/Toast';
import { routerRedux } from 'dva/router';
import { getToken, isLogin } from '@/utils';

const savaUserData = (data) => {
  Object.keys(data).forEach((e) => {
    window.localStorage.setItem(e, data[e]);
  });
};
const clearUserData = () => {
  window.localStorage.clear();
};
export default {
  namespace: 'user',
  state: {
    userData: null,
  },
  reducers: {
    // 账号登录
    login(state, { payload }) {
      savaUserData(payload);
      return {
        ...state,
        userData: payload,
      };
    },
    r_outLogin(state, { payload }) {
      return {
        ...state,
        userData: payload,
      };
    },
  },
  effects: {
    // 账号或者邮箱 登录
    *userOrEmailLogin({ payload }, { call, put, apply }) {
      yield put({
        type: 'g_loading/setGLoading',
        payload: true,
      });
      const res = yield call(userLoginOrEmail, payload);
      yield put({
        type: 'g_loading/setGLoading',
        payload: false,
      });
      if (res.Code === 1000) {
        Toast('登錄成功', true, 2000);
        yield put({
          type: 'handleLoginOrRegisterSuccessBefore',
          payload: {
            res,
          },
        });
      }
    },
    // 验证码登录  或者 通过验证码更新密码
    *codoLogin({ payload: { type = 'login', ...payload } }, { call, put }) {
      yield put({
        type: 'g_loading/setGLoading',
        payload: true,
      });
      const res = yield call(signInVerifyCode, payload);
      yield put({
        type: 'g_loading/setGLoading',
        payload: false,
      });
      if (res.Code === 1000) {
        if (type === 'login') {
          Toast('登錄成功', true, 2000);
          yield put({
            type: 'handleLoginOrRegisterSuccessBefore',
            payload: {
              res,
            },
          });
        } else if (type === 'findPwd') {
          Toast('密碼修改成功', true, 2000);
          return true;
        }
      }
    },
    // 登录或者注册成功之后要做的事情
    *handleLoginOrRegisterSuccessBefore({ payload: { res } }, { call, put }) {
      savaUserData({
        token: res.Token,
      });

      yield put({
        type: 'getUserData',
      });
      yield put({
        type: 'modelState/setLoginOrRegister',
        payload: {
          state: false,
        },
      });
    },
    // 手机号注册
    *phoneRegister({ payload }, { call, put }) {
      yield put({
        type: 'g_loading/setGLoading',
        payload: true,
      });
      const res = yield call(userRegister, payload);
      yield put({
        type: 'g_loading/setGLoading',
        payload: false,
      });
      if (res.Code === 1000) {
        Toast('註冊成功, 已為您自動登錄', true, 2000);
        yield put({
          type: 'handleLoginOrRegisterSuccessBefore',
          payload: {
            res,
          },
        });
      }
      return res;
    },
    // 邮箱注册
    *emailRegister({ payload }, { call, put }) {
      yield put({
        type: 'g_loading/setGLoading',
        payload: true,
      });
      const res = yield call(userRegister, payload);
      yield put({
        type: 'g_loading/setGLoading',
        payload: false,
      });
      if (res.Code === 1000) {
        savaUserData({
          token: res.Token,
        });
        yield put({
          type: 'getUserData',
        });
        yield put({
          type: 'modelState/setLoginOrRegister',
          payload: {
            state: false,
          },
        });
      }
      return res;
    },
    // 用于邮箱或者手机注册，发送验证码
    *sendCode({ payload }, { call, put }) {
      const res = yield call(registerSendCode, payload);

      return res;
    },
    *getUserData(action, { call, put }) {
      const token = getToken();

      const res = yield call(getUserData, token);

      if (res.Code === 20202 || Object.keys(res).length === 0) {
        yield put({
          type: 'outLogin',
        });
        return;
      }
      yield put({
        type: 'login',
        payload: {
          ...res.UserInfo,
        },
      });
    },

    *outLogin(action, { put }) {
      if (!isLogin()) return;

      clearUserData();

      yield put({
        type: 'r_outLogin',
        payload: null,
      });
      yield put(routerRedux.push('/'));
      window.location.reload();
    },
  },
  subscriptions: {
    initUserData({ dispatch, history }) {
      if (getToken() !== null) {
        dispatch({
          type: 'getUserData',
        });
      }
    },
  },
};
