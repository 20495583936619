import React from 'react';
import styles from './index.module.scss';
import FindPwdPhoneForm from './FindPwdPhoneForm.js';
import FindPwdEmailForm from './FindPwdEmailForm';
import classnames from 'classnames';

// 这里的代码是从注册页复制粘贴过来的  会有命名跟功能不相符的地方 请更多的关注逻辑
export default function Register({
  setCurrentFindPwdType,
  setcurrentPage,
  currentFindPwdType,
  setCurrentLoginType,
}) {
  const isPhonepage = currentFindPwdType == 'phone';
  const isEmailpage = currentFindPwdType == 'email';

  const getContent = () => {
    if (isPhonepage) {
      return (
        <FindPwdPhoneForm
          setcurrentPage={setcurrentPage}
          setCurrentLoginType={setCurrentLoginType}
          setCurrentFindPwdType={setCurrentFindPwdType}
        />
      );
    }
    if (isEmailpage) {
      return (
        <FindPwdEmailForm
          setcurrentPage={setcurrentPage}
          setCurrentLoginType={setCurrentLoginType}
          setCurrentFindPwdType={setCurrentFindPwdType}
        />
      );
    }
  };
  return (
    <div className={classnames(styles.register)}>
      <div className={styles['btns']}>
        <div
          onClick={() => setCurrentFindPwdType('phone')}
          className={classnames({ [styles.active]: isPhonepage })}
        >
          手機密碼
        </div>
        <div
          onClick={() => setCurrentFindPwdType('email')}
          className={classnames({ [styles.active]: isEmailpage })}
        >
          郵箱密碼
        </div>
      </div>
      {getContent()}
      <div className={styles.msg}>
        <p>登錄或完成賬號註冊表示您已閱讀並同意亞視</p>
        <p>《服務條款》和 《隱私政策》</p>
      </div>
    </div>
  );
}
