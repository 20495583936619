import { Col, message, Modal, Radio } from 'antd';
import React, { useState } from 'react';
import { CreateNetworkDiskShare } from '@/api';
import { H5_URL } from '@/utils/constant';

function ModalShare({ datas, onChange }) {
  const [ExpireTime, setExpireTime] = useState(null);
  const { ID, Name } = datas || {};

  const handleSubmit = () => {
    if (!ExpireTime) return message.warning('请选择过期时间');
    CreateNetworkDiskShare({
      ID,
      ExpireTime: Math.floor(Date.now() / 1000) + ExpireTime * 24 * 60 * 60,
    }).then((res) => {
      const { ExpireTime, ID: IDs, SharePassword } = res.Data || {};
      const url = `${H5_URL}/netdisk/extract/${IDs}/${ExpireTime}  提取码：${SharePassword}`;
      onChange(url);
      setExpireTime(null);
    });
  };

  return (
    <Modal
      title='文件分享'
      visible={!!datas}
      onCancel={() => {
        setExpireTime(null);
        onChange();
      }}
      onOk={handleSubmit}
    >
      <Col
        style={{
          marginBottom: '12px',
          whiteSpace: 'nowrap',
          overflow: 'hidden',
          textOverflow: 'ellipsis',
        }}
      >
        文件名：{Name}
      </Col>
      <Col>
        有效期：
        <Radio.Group
          value={ExpireTime}
          onChange={(e) => setExpireTime(e.target.value)}
        >
          <Radio value={360}>360天</Radio>
          <Radio value={180}>180天</Radio>
          <Radio value={90}>90天</Radio>
        </Radio.Group>
      </Col>
    </Modal>
  );
}

export default ModalShare;
