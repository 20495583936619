import React from 'react';
import { Modal } from 'antd';

function Index({ visible, children, onClose }) {
  if (visible) {
    return (
      <Modal
        visible={visible}
        bodyStyle={{ padding: '0' }}
        width={'min-content'}
        footer={false}
        closable={false}
        onCancel={onClose}
      >
        {children}
      </Modal>
    );
  }
  return null;
}

export default Index;
