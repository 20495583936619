import React from 'react';
import * as AntIcons from '@ant-design/icons';

function IconFont({ type = '', suffix = 'Outlined', ...config }) {
  let typeArr = (type ?? '').split('-').filter((i) => i);
  if (!typeArr.length) return '';
  const nodeName = typeArr
    .map((i) => `${i.slice(0, 1).toLocaleUpperCase()}${i.slice(1)}`)
    .join('');
  const IconDom = AntIcons[`${nodeName}${suffix}`];
  return <IconDom {...config} />;
}

export default IconFont;
