import React, { useEffect, useState } from 'react';
import styles from './index.module.scss';
import Item from '../../components/Item';
import { connect } from 'dva';
import { getCollect } from '@/api';
import { fillDomWrapper, getToken } from '../../../../utils';
// 收藏页
function Collect({ loading, unLoading, ...props }) {
  const [listData, setListData] = useState([]);
  useEffect(() => {
    (async () => {
      loading();
      const res = await getCollect(getToken());
      unLoading();
      setListData(res.Resources || []);
    })();
  }, []);
  const onGetToItem = (data) => {
    props.history.push('/player/' + data.ID);
  };
  let lis = listData.map((e) => {
    return (
      <Item
        {...e}
        onGetToItem={onGetToItem}
        key={e.ID}
        name={e.Name}
        url={e.Preview}
      />
    );
  });
  lis = fillDomWrapper({ arr: lis, colNum: 7, width: '10.9375vw' });
  return (
    <div className={styles.annal}>
      <p className={styles.text}>全部</p>
      <ul>{lis}</ul>
    </div>
  );
}

export default connect(null, (dispatch) => ({
  loading() {
    dispatch({
      type: 'g_loading/setGLoading',
      payload: true,
    });
  },
  unLoading() {
    dispatch({
      type: 'g_loading/setGLoading',
      payload: false,
    });
  },
}))(Collect);
