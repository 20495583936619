import React from 'react';
import { Switch, Route, Redirect } from 'dva/router';
import { NavLink } from 'dva/router';
import Annals from './pages/Annals';
import Collect from './pages/Collect';
import { getAvatar } from '@/utils';
import { connect } from 'dva';
import { ifNotLoginToHome } from '../../utils';
import styles from './index.module.scss';

function History({ user, ...props }) {
  if (ifNotLoginToHome(props.history)) return null;
  const onGoHuiYuan = () => {
    props.dispatch({
      type: 'modelState/setCharge',
      payload: {
        state: true,
        data: {
          buySomething: 'member',
        },
      },
    });
  };
  return (
    <div className={styles.history}>
      <div className={styles.content}>
        <div className={styles.left}>
          {user ? (
            <div className={styles.top}>
              <img src={getAvatar()} />
              <p className={styles.user}>賬號：{user.UserName}</p>
              <p className={styles.timer}>
                {user.Level === 2
                  ? `VIP會員 ${user.VipExpire.split(' ')[0]} 到期`
                  : ''}
              </p>
              <div onClick={() => onGoHuiYuan()} className={styles.xu_fei}>
                {user.Level === 2 ? '立即續費' : '開通會員'}
              </div>
            </div>
          ) : null}

          <div className={styles.bottom}>
            <NavLink activeClassName={styles.active} to='/history/annals'>
              歷史播放
            </NavLink>
            <NavLink activeClassName={styles.active} to='/history/collect'>
              我的收藏
            </NavLink>
          </div>
        </div>
        <div className={styles.right}>
          <Switch>
            <Route component={Annals} path='/history/annals' />
            <Route component={Collect} path='/history/collect' />
            <Redirect to='/history/annals' />
          </Switch>
        </div>
      </div>
    </div>
  );
}
export default connect((state) => ({
  user: state.user.userData,
}))(History);
