import React from 'react';
import { handleSum } from '@/utils';
import classNames from 'classnames';
import styles from './index.module.scss';

export default function Item({
  Name,
  Price,
  ShowPrice,
  selectItem,
  i,
  onselect,
}) {
  return (
    <div
      onClick={() => onselect(i)}
      className={classNames(styles.item, { [styles.active]: i === selectItem })}
    >
      <p className={styles.fjsidoq2}>{Name}</p>
      <p className={styles.fjio2cc}>
        <span className={styles.tfd}>HK$</span>
        <span className={styles.jdsi23}>{handleSum(Price)}</span>
      </p>
      <p className={styles.dsjio2oic}>HK${handleSum(ShowPrice)}</p>
    </div>
  );
}
