import React, { useEffect, useState } from 'react';
import styles from './index.module.scss';
import Item from '../History/components/Item';
import { fillDomWrapper } from '@/utils';
import { connect } from 'dva';
import Paging from '../../components/Paging';
import Top from '@/components/Top';
import qs from 'query-string';

function Search(props) {
  const [pageData, setPageData] = useState({
    curPage: 1,
    limits: 18,
    maxShowPageNum: 6,
    total: 0,
  });
  const key = qs.parse(props.location.search).key;
  const listData = props.searchData.Resources;
  useEffect(() => {
    (async function () {
      const res = await props.dispatch({
        type: 'screen/searchResource',
        payload: {
          searchKeyWord: key,
          pageNo: pageData.curPage,
          pageSize: pageData.limits,
        },
      });

      setPageData({
        ...pageData,
        total: res.TotalCount,
      });
    })();
  }, [pageData.curPage, key]);

  const onGetToItem = (data) => {
    props.history.push('/player/' + data.ID);
  };
  const onChangePage = (page) => {
    setPageData({
      ...pageData,
      curPage: page,
    });
  };
  let lis = listData
    ? listData.map((e) => {
        return (
          <Item
            {...e}
            onGetToItem={onGetToItem}
            key={e.ID}
            name={e.Name}
            url={e.Preview}
          />
        );
      })
    : [];
  lis = fillDomWrapper({ arr: lis, colNum: 7, width: '10.9375vw' });
  return (
    <div className={styles.annal}>
      <Top />
      <div className={styles.content}>
        <ul>{lis}</ul>
      </div>
      <div className={styles.fjdsioj2}>
        <Paging
          changePageFun={onChangePage}
          maxShowPageNum={pageData.maxShowPageNum}
          currentPage={pageData.curPage}
          dataAllNum={pageData.total}
          limits={pageData.limits}
        />
      </div>
    </div>
  );
}
export default connect((state) => ({
  searchData: state.screen.searchResource,
}))(Search);
