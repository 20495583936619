import React, { useEffect, useState } from 'react';
import styles from './index.module.scss';
import Toast from '@/components/Toast';
import classNames from 'classnames';
import { connect } from 'dva';
import Hls from 'hls.js';
import { getUrlParams, isLogin, isVip } from '../../utils';
import HlsJsPlayer from 'xgplayer-hls.js';
import { LeftOutlined, RightOutlined } from '@ant-design/icons';
import { decrypt_aes } from '../../utils/helper';
import { GetTV } from '@/api';
import './index.scss';

let play;
function Tv({ userData, dispatch }) {
  const [tvs, setTvs] = useState({
    lists: [],
    active: {},
  });
  const [viewContent, setViewContent] = useState('tv');
  const [isShowRight, setIsShowRight] = useState(true);

  // useEffect(() => {
  //   if (tvs.active?.SourceURL) {
  //     const video = document.getElementById('hlsVedio');
  //     if (Hls.isSupported()) {
  //       const hls = new Hls();
  //       hls.loadSource(tvs.active.SourceURL);
  //       hls.attachMedia(video);
  //       hls.on(Hls.Events.MANIFEST_PARSED, function() {
  //         video.play();
  //       });
  //     } else if (video.canPlayType('application/vnd.apple.mpegurl')) {
  //       video.src = tvs.active.SourceURL;
  //       video.addEventListener('loadedmetadata', function() {
  //         video.play();
  //       });
  //     }
  //   }
  // }, [tvs]);

  useEffect(() => {
    GetTV().then((d) => {
      const lists = d.TVList.map((i) => ({
        ...i,
        SourceURL: decrypt_aes(i.SourceURL),
      }));
      const p_id = getUrlParams('i');
      const current = lists.find((i) => i.ID == p_id) || lists[0];

      setTvs({
        lists: lists,
        active: current,
      });
    });
  }, []);

  // https 转 http
  // useEffect(() => {
  //   const href = window.location.href;
  //   const { active } = tvs || {};
  //   const { SourceURL, ID } = active || {};
  //
  //   if (SourceURL) {
  //     const url_h = SourceURL.replace(/:\/\/\S+/g, '://');
  //     if (href.indexOf(url_h) === -1) {
  //       window.location.href = `${url_h}${window.location.host}${window.location.pathname}?i=${ID}`;
  //     }
  //   }
  // }, [tvs]);

  const destory = () => {
    if (!play) return;
    const dom = document.getElementById('player-con12');
    play.emit();
    play = undefined;
    if (dom !== null) {
      dom.innerHTML = '';
    }
  };

  // TODO: http转https
  const onHttpToHttps = (url) => {
    if (url) {
      let uri = url;
      if (!url.includes('https')) {
        uri = url.replace('http', 'https');
      }
      return uri;
    }
    return '';
  };

  useEffect(() => {
    if (play !== undefined) {
      const liveUrl = onHttpToHttps(tvs.active.SourceURL);
      play.src = liveUrl;
      return;
    }
    if (tvs.active.SourceURL === undefined || viewContent === 'hint') return;

    Promise.resolve().then((d) => {
      const liveUrl = onHttpToHttps(tvs.active.SourceURL);
      play = new HlsJsPlayer({
        el: document.querySelector('#player-con12'),
        url: liveUrl,
        isLive: true,
        autoplay: true,
        playsinline: true,
        height: '41.8229vw',
        width: '100%',
        hlsOpts: {
          xhrSetup: function (xhr, url) {
            xhr.open('GET', url);
          },
        },
        lang: 'en',
      });
    });

    return () => destory();
  }, [tvs]);

  const getVipIcon = (e) => {
    if (e.Permission === undefined) return false;
    if (e.Permission === 2) {
      return (
        <img
          src={require('@/assets/images/vip.png').default}
          className={styles['vip_icon_fds23']}
        />
      );
    }
    return null;
  };

  const onSelect = (e) => {
    if (e.Permission === 2 && !isVip(userData)) {
      setViewContent('hint');
    } else {
      setViewContent('tv');
    }
    setTvs({
      ...tvs,
      active: e,
    });
  };

  const getContent = () => {
    if (viewContent === 'tv') {
      return null;
    } else if (viewContent === 'hint') {
      destory();
      return (
        <div className={classNames(styles.hint)}>
          <p>本片是亚洲电视VIP会员专享内容</p>
          <div
            className={styles.btn}
            onClick={() => {
              if (!isLogin()) {
                Toast('請先登錄');
                return;
              }
              dispatch({
                type: 'modelState/setCharge',
                payload: {
                  state: true,
                  data: {
                    buySomething: 'member',
                  },
                },
              });
            }}
          >
            开通VIP会员
          </div>
        </div>
      );
    }
    return null;
  };

  return (
    <div className={styles.tv}>
      <div className={styles.left} id='player-con12'>
        {getContent()}
        {/*<video className={styles.left} id="hlsVedio"></video>*/}
      </div>
      {
        <div
          className={styles.right}
          style={{ width: isShowRight ? '336px' : '1px' }}
        >
          <div
            className={styles.shouqi}
            onClick={() => setIsShowRight(!isShowRight)}
          >
            {isShowRight ? <RightOutlined /> : <LeftOutlined />}
          </div>
          <ul>
            {tvs.lists.map((e) => {
              return (
                <li
                  key={e.ID}
                  onClick={() => {
                    onSelect(e);
                  }}
                  className={classNames({
                    [styles.active]: e.ID === tvs.active.ID,
                  })}
                >
                  {e.Title}
                  {getVipIcon(e)}
                </li>
              );
            })}
          </ul>
        </div>
      }
    </div>
  );
}

export default connect((state) => ({
  userData: state.user.userData,
}))(Tv);
