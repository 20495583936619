import React from 'react';
import UserLogin from './UserLogin';
import { connect } from 'dva';
import Explain from './Explain';
import Charge from './Charge';
import ChargeOld from './Charge/indexOld';

function Models( {modelState, user }) {
  const { loginOrRegister, charge, explain } = modelState;

  const renderCharge = () => {
    return <Charge />
    if (user?.userData?.UserName) {
      const WL = ['19142383725', '2755499409@qq.com', '18899850965']
      if (WL.includes(user?.userData?.UserName)) {
        return <Charge />
      }
    }
    return <ChargeOld />
  }
  
  return (
    <>
      {charge.state ? renderCharge() : null}
      {loginOrRegister.state ? <UserLogin /> : null}
      {explain.state ? <Explain /> : null}
    </>
  );
}
const mapStateToProps = ({ modelState, user }) => {
  return {
    modelState,
    user
  };
};
export default connect(mapStateToProps, null)(Models);