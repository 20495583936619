import OSS from 'ali-oss';
import { random } from 'lodash';
import { getAliToken } from '@/utils/helper';

const ALI_OSS_PREFIX = 'atv_ipfs/encrypt_txt';
export const UploadToOSS = async (self, file, name, prefix, onProgress) => {
  return new Promise((resolve, reject) => {
    new OSS(self)
      .multipartUpload(`${prefix || ALI_OSS_PREFIX}/${name}`, file, {
        progress: (p) => {
          const result = {
            key: name,
            percent: Math.round(p * 100),
          };
          onProgress(result);
        },
      })
      .then((data) => {
        const { res = {}, name } = data || {};
        if (res.status === 200) {
          resolve(`${name}`);
        } else {
          reject(data);
        }
      })
      .catch((error) => reject(error));
  });
};

export const UploadOSS = async ({
  file,
  fileKey,
  prefix,
  onProgress = () => {},
  onFinish = () => {},
}) => {
  await getAliToken();
  const { uploadToken } = global;
  if (!uploadToken) return;
  const reader = new FileReader();
  reader.readAsDataURL(file);
  reader.onloadend = () => {
    const { cdnUrl, ...OSSKEY } = uploadToken;
    UploadToOSS(OSSKEY, file, fileKey, prefix, onProgress)
      .then((data) => {
        onFinish('done', {
          key: fileKey,
          url: `${cdnUrl}/${data}`,
        });
      })
      .catch((err) => {
        console.log('上传error===', err);
        onFinish('error', {
          key: fileKey,
          url: '',
        });
      });
  };
};

export const createFileKey = (file) => {
  if (file) {
    const { type = '', name = '' } = file || {};
    const typeSuffix = type.toString().split('/')[1];
    const nameSuffix = name.toString().split('.').reverse()[0];
    const suffix = `.${typeSuffix || nameSuffix}`;
    const prefix = String(Date.now()) + random(1000000, 9999999);
    return `${prefix}${suffix}`;
  }
  return '';
};
