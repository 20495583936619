import React, { useState } from 'react';
import Input from '../../../components/Input';
import styles from './index.module.scss';
import check from '@/utils/checkFrom';
import GetCode from '../GetCode';
import { connect } from 'dva';
import SliderCheck from '../../../components/SliderCheck';
import Toast from '@/components/Toast';
import classnames from 'classnames';

function RegisterEmailForm({ dispatch, setcurrentPage, setCurrentLoginType }) {
  const [isShowCheckSlider, setIsShowCheckSlider] = useState(false);
  const [formValue, setFormValues] = useState({
    pwdType: 'password',
    email: '',
    code: '',
    password: '',
    anginPwd: '',
    setpwdType: (_) =>
      setFormValues((cur) => {
        return {
          ...cur,
          pwdType: cur.pwdType == 'text' ? 'password' : 'text',
        };
      }),
    setEmail: ({ target: { value: email } }) => {
      setFormValues((cur) => {
        return {
          ...cur,
          email,
        };
      });
    },
    setCode: ({ target: { value: code } }) => {
      setFormValues((cur) => {
        return {
          ...cur,
          code,
        };
      });
    },
    setPassword: ({ target: { value: password } }) => {
      setFormValues((cur) => {
        return {
          ...cur,
          password,
        };
      });
    },
    setAnginPwd: ({ target: { value: anginPwd } }) => {
      setFormValues((cur) => {
        return {
          ...cur,
          anginPwd,
        };
      });
    },
  });
  const checkForm = ({
    email = '',
    code = '',
    password = '',
    anginPwd = '',
  }) => {
    const fd = check([
      {
        targetStr: email,
        rules: {
          isNull: {
            params: '請輸入郵箱號',
          },
        },
      },
      {
        targetStr: email,
        rules: {
          minLength: {
            params: [6, '郵箱長度不可以小於 6 位'],
          },
        },
      },
      {
        targetStr: email,
        rules: {
          checkFormat: {
            params: [
              /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
              '郵箱格式不正確',
            ],
          },
        },
      },
    ]);
    if (fd !== true) return fd;
    let sdf = check([
      {
        targetStr: code,
        rules: {
          isNull: {
            params: '請輸入驗證碼',
          },
        },
      },
      {
        targetStr: code,
        rules: {
          minLength: {
            params: [6, '驗證碼長度不能小於 6 位'],
          },
        },
      },
    ]);
    if (sdf !== true) return sdf;
    let affsdfsd = check([
      {
        targetStr: password,
        rules: {
          isNull: {
            params: '請輸入密碼',
          },
        },
      },
      {
        targetStr: password,
        rules: {
          minLength: {
            params: [6, '密碼長度不可以小於 6 位'],
          },
        },
      },
    ]);
    if (affsdfsd !== true) return affsdfsd;

    let adfjiodf = check([
      {
        targetStr: anginPwd,
        rules: {
          isNull: {
            params: '請再次輸入密碼',
          },
        },
      },
    ]);
    if (adfjiodf !== true) return adfjiodf;
    else {
      if (anginPwd !== password) {
        return {
          state: false,
          msg: '前後輸入的密碼不一致',
        };
      }
      return true;
    }
  };
  const onFindPwd = () => {
    const state = checkForm({
      email: formValue.email,
      code: formValue.code,
      password: formValue.password,
      anginPwd: formValue.anginPwd,
    });
    if (state !== true) {
      Toast(state.msg);
      return;
    }
    dispatch({
      type: 'user/codoLogin',
      payload: {
        Operation: 2,
        VerifyCode: formValue.code + '',
        Password: formValue.password,
        Kind: 2,
        Email: formValue.email,
        type: 'findPwd',
      },
    }).then((d) => {
      if (d === true) {
        setcurrentPage('login');
        setCurrentLoginType('user');
      }
    });
  };
  // 发送验证码
  const onSend = ({ sig, csessionid, value, token, ...data }) => {
    dispatch({
      type: 'user/sendCode',
      payload: {
        Sig: sig,
        Token: token,
        SessionId: csessionid,
        Email: formValue.email,
        Operation: 2,
        Kind: 2,
      },
    });
  };
  return (
    <div
      className={classnames(styles.content)}
      onKeyDown={(e) => {
        if (e.keyCode === 13) onFindPwd();
      }}
    >
      <Input
        onChange={formValue.setEmail}
        value={formValue.email}
        placeholder='請輸入郵箱'
      />
      <p className={classnames(styles.sdfsd)}></p>
      <Input
        onChange={formValue.setCode}
        value={formValue.code}
        placeholder='請輸入驗證碼'
        getRightIcon={() => (
          <GetCode
            type='email'
            email={formValue.email}
            onStart={() => setIsShowCheckSlider(true)}
          />
        )}
      />

      <p className={classnames(styles.sdfsd)}></p>
      <Input
        onChange={formValue.setPassword}
        value={formValue.password}
        getRightIcon={() => {
          return (
            <img
              src={
                require(`../image/${
                  formValue.pwdType == 'text' ? 'yj' : 'by'
                }.png`).default
              }
            />
          );
        }}
        type={formValue.pwdType}
        placeholder='請輸入登錄密碼'
        onClickIcon={formValue.setpwdType}
      />
      <p className={classnames(styles.sdfsd)}></p>
      <Input
        onChange={formValue.setAnginPwd}
        value={formValue.anginPwd}
        type={formValue.pwdType}
        onClickIcon={formValue.setpwdType}
        placeholder='請確認登錄密碼'
        getRightIcon={() => {
          return (
            <img
              src={
                require(`../image/${
                  formValue.pwdType == 'text' ? 'yj' : 'by'
                }.png`).default
              }
            />
          );
        }}
      />
      <div
        onClick={() => {
          onFindPwd();
        }}
        className={styles.btn_login}
      >
        找回密碼
      </div>
      {isShowCheckSlider ? (
        <SliderCheck
          onEndCheck={(data) => {
            setIsShowCheckSlider(false);
            onSend(data);
          }}
        />
      ) : null}
    </div>
  );
}

export default connect()(RegisterEmailForm);
