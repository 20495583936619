import React, { useState } from 'react';
import classnames from 'classnames';
import Login from './Login/index';
import { CloseOutlined } from '@ant-design/icons';
import Register from './Register';
import { connect } from 'dva';
import FindPwd from './FindPwd';
import './index.scss';

// 登陆与注册共用一个弹框
function UserLogin({ closeModel }) {
  // 当前是登录 login 还是注册 register 还是找回密码页 findPwd
  const [currentPage, setcurrentPage] = useState('login');
  // 当前的一个登陆方式  user 表示 账号密码  email 表示邮箱登陆  phone 表示手机号验证登陆
  const [currentLoginType, setCurrentLoginType] = useState('user');
  // 当前的一个注册方式    email 表示邮箱注册  phone 表示手机号验证注册
  const [currentRegisterType, setCurrentRegisterType] = useState('phone');
  // 当前找回密码的方式  手机号 跟 邮箱
  const [currentFindPwdType, setCurrentFindPwdType] = useState('phone');
  const getPage = () => {
    if (currentPage === 'login') {
      return (
        <Login
          onGoFindPwd={() => {
            setcurrentPage('findPwd');
          }}
          setCurrentLoginType={setCurrentLoginType}
          currentLoginType={currentLoginType}
        />
      );
    }
    if (currentPage === 'register') {
      return (
        <Register
          currentRegisterType={currentRegisterType}
          setCurrentRegisterType={setCurrentRegisterType}
        />
      );
    }
    return (
      <FindPwd
        currentFindPwdType={currentFindPwdType}
        setcurrentPage={setcurrentPage}
        setCurrentLoginType={setCurrentLoginType}
        setCurrentFindPwdType={setCurrentFindPwdType}
      />
    );
  };
  const onUsePhone = () => {
    setCurrentLoginType('phone');
  };
  const onUsePwd = () => {
    setCurrentLoginType('user');
  };
  const getBottomContent = () => {
    if (currentPage == 'login') {
      if (currentLoginType == 'user') {
        return (
          <>
            <span onClick={() => setcurrentPage('register')}>免費註冊</span>
            <span onClick={onUsePhone}>使用驗證碼登錄</span>
          </>
        );
      }
      if (currentLoginType == 'phone') {
        return (
          <>
            <span onClick={() => setcurrentPage('register')}>免費註冊</span>
            <span onClick={onUsePwd}>密碼登錄</span>
          </>
        );
      }
      if (currentLoginType == 'email') {
        return (
          <>
            <span onClick={() => setcurrentPage('register')}>免費註冊</span>
            <span onClick={onUsePhone}>使用驗證碼登錄</span>
          </>
        );
      }
    }
    if (currentPage == 'register') {
      return <span onClick={() => setcurrentPage('login')}>登錄</span>;
    }
    if (currentPage === 'findPwd') {
      return (
        <span>
          <span onClick={() => setcurrentPage('login')}>登錄 </span>丨
          <span onClick={() => setcurrentPage('register')}> 免費註冊</span>
        </span>
      );
    }
  };
  const getMsgText = () => {
    if (currentPage == 'login') return '登錄';
    else if (currentPage == 'register') return '註冊';
    else return '找回密碼';
  };
  const onClose = () => {};
  return (
    <div className={'wrapper_fjds234'}>
      <div className={classnames('user-login-model', currentPage)}>
        <div className={'top_fdsf23'}>
          <div className={'left'}>
            <img src={require('@/assets/images/logo.png').default} alt='' />
            <span>· {getMsgText()}</span>
          </div>
          <div className={'right'} onClick={() => closeModel && closeModel()}>
            <CloseOutlined />
          </div>
        </div>
        {getPage()}

        <div className={'bottom_fio23j'}>{getBottomContent()}</div>
      </div>
    </div>
  );
}
const mapDispatchToProps = (dispatch) => {
  return {
    closeModel() {
      dispatch({
        type: 'modelState/setLoginOrRegister',
        payload: {
          state: false,
        },
      });
    },
  };
};
export default connect(null, mapDispatchToProps)(UserLogin);
