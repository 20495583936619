import Axios from 'axios';
import env from '@/config/env.js';
import Toast from '../components/Toast';
import { getToken } from '@/utils';

const { baseurl } = env;

const axios = Axios.create({
  baseURL: baseurl,
  timeout: 0,
});

axios.interceptors.request.use(
  function (config) {
    config.headers = {
      ...(config.headers || {}),
      'device-id': window.deviceId ?? window.localStorage.getItem('deviceId') ?? '',
    };
    if (getToken()) {
      config = {
        ...config,
        headers: {
          ...(config.headers || {}),
          token: getToken(),
        },
      };
    }
    return config;
  },
  function (error) {
    return Promise.reject(error);
  }
);

axios.interceptors.response.use(
  function (response) {
    const { data } = response || {};
    const getCode = (d) => {
      if (d.code) return d.code;
      return d.Code;
    };
    if (getCode(data) !== 1000) {
      const msg = data.Msg.detail ? data.Msg.detail : data.Msg;
      Toast(msg);
      return {};
    }

    return response.data;
  },
  function (error) {
    const { status } = error.response || {};
    if (status === 401) {
      window.localStorage.clear();
    }
    return Promise.reject(error);
  }
);

export default axios;
