import React from 'react';
import { Tooltip } from 'antd';
import { connect } from 'dva';
import { isVip } from '@/utils';
import { panLogo, panDefaultAvatar, panIsVip, panIsNotVip } from '../../assets';
import styles from './index.module.scss';
function Header({ user = {}, setCharge, onClick }) {
  user = user || {};
  const { Portrait, UserName } = user || {};

  return (
    <header className={styles.pan_header}>
      <div className={styles.pan_header_info}>
        <Tooltip
          placement='top'
          title={<span style={{ fontSize: '12px' }}>返回官网</span>}
        >
          <img
            src={panLogo}
            className={styles.pan_header_info_logo}
            onClick={onClick}
          />
        </Tooltip>
        <div className={styles.pan_header_info_name}>
          <span>亚视区块链云盘</span> - <span>去中心化區块鏈加密存储</span>
        </div>
      </div>
      <div className={styles.pan_header_rightBar}>
        <div className={styles.pan_header_userInfo}>
          <img
            src={Portrait || panDefaultAvatar}
            className={styles.userInfo_avatar}
          />
          <span className={styles.userInfo_name}>{UserName}</span>
          <img
            src={user && isVip(user) ? panIsVip : panIsNotVip}
            className={styles.userInfo_vip}
          />
        </div>
        <div
          className={styles.become_vip}
          onClick={() => {
            setCharge();
          }}
        >
          <span>{user && isVip(user) ? '续费会员' : '开通会员'}</span>
        </div>
        <div className={styles.vertical_line}></div>
      </div>
    </header>
  );
}
export default connect(
  (state) => ({
    user: state.user.userData,
  }),
  (dispatch) => {
    return {
      setCharge() {
        dispatch({
          type: 'modelState/setCharge',
          payload: {
            state: true,
            data: {
              buySomething: 'member',
            },
          },
        });
      },
    };
  }
)(Header);
