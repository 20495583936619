import { connect } from 'dva';
import React, { useState, useEffect, useRef } from 'react';
import { getNetworkDiskFiles, createNetworkDiskFiles, DeleteNetworkDiskFiles } from '@/api';
import { downLoadUrl } from '@/utils/helper';
import { copyText } from '@/utils/helper';
import { message, Modal } from 'antd';
import ModalShare from '../../components/ModalShare';
import Previews from '../../components/Previews';
import {
  ContainerBox,
  ModalCreateFolder,
  TopBar,
  TopNav,
  ModalReName,
  ModalMove,
} from '../../components';
import styles from './index.module.scss';

//扁平化数组
const getDelayering = (data, field = 'Child') => {
  const list = [];
  data.forEach((i) => {
    let arr = [];
    if (i[field]) arr = getDelayering(i[field], field);
    list.push({ ...i, [field]: null }, ...arr);
  });
  return list;
};

function MyFiles({ user = {}, ...props }) {
  user = user || {};
  const { ID: UserID } = user;
  const {
    match: {
      params: { id },
    },
  } = props || {};
  const iptRef = useRef(null);

  const initPageId = Number(id) >= 0 ? Number(id) : undefined;
  const [pageId, setPageId] = useState(initPageId);
  const [pageLevel, setPageLevel] = useState();
  const FileType = Number(id) >= 0 ? undefined : id;

  const [fileList, setFileList] = useState(); //扁平话后所有文件夹信息数组
  const [pageData, setPageData] = useState(); //当前文件信息数组
  const [navTree, setNavTree] = useState([{ id: 0, name: '我的云盘' }]);
  const [selectedIds, setSelectedIds] = useState([]);

  const [showUploadbtn, setShowUploadbtn] = useState(true);
  const [createModal, setCreateModal] = useState(false);
  const [reNameModal, setReNameModal] = useState(false);
  const [moveModal, setMoveModal] = useState(false);

  const [moveItems, setMoveItems] = useState();
  const [reNameItem, setReNameItem] = useState();

  const [ways, setWays] = useState('icon'); // list 列表顯示 icon // 圖標顯示
  const [sort, setSort] = useState(true); // true 正序 false 倒敘

  const [secret, setSecret] = useState({});

  const [previews, setPreviews] = useState([]);
  const [shareData, setShareData] = useState(null);

  //根据pageId生成导航数
  const findNavTree = (pid) => {
    pid = parseInt(pid);
    let narTreeArr = [];
    const obj = fileList && fileList.find((item) => item.ID === pid); //找出开始选中项目的parent
    if (obj) {
      const { ParentID: newPid, Level: newLevel, Name: newName, ID: id } = obj;
      const arr = findNavTree(newPid);
      arr.push({ id, name: newName, level: newLevel });
      narTreeArr = [].concat(arr);
    } else {
      narTreeArr = [].concat([{ id: 0, name: '我的云盘', level: 0 }]);
    }

    return narTreeArr;
  };

  useEffect(() => {
    if (FileType) {
      setShowUploadbtn(false);
    }
  }, [FileType]);

  useEffect(() => {
    setPageId(initPageId);
  }, [initPageId]);

  useEffect(() => {
    const arr = fileList && fileList.filter((o) => o.ParentID === pageId);
    if (arr && arr.length > 0) {
      const level = arr[0].Level;
      setPageLevel(level);
    }
    setPageData(arr);

    setNavTree(findNavTree(pageId));
  }, [pageId, fileList]);

  const getAllFiles = () => {
    const body = {
      UserID,
      FileType,
      SortType: sort ? 3 : 4,
    };
    getNetworkDiskFiles({ body }).then((res) => {
      const { NetworkDiskFiles, PrivateKey, ShareKey } = res;
      const arr = NetworkDiskFiles && JSON.parse(NetworkDiskFiles);
      const delayeringArr = getDelayering(arr);
      setFileList(delayeringArr);
      setSecret({ PrivateKey, ShareKey });
    });
  };

  useEffect(() => {
    UserID && getAllFiles();
  }, [UserID, FileType, sort]);

  //打开文件/文件夹
  const handleOpen = (o) => {
    const { ParentID, ID, Name, FileType: type } = o || {};
    switch (type) {
      case 'folder':
        props.history.push(`/pan/files/${ID}`);
        break;
      case 'img':
        break;
      case 'voice':
        break;
      case 'doc':
        break;
    }
  };

  const onSuccess = () => {
    getAllFiles();
  };

  const handleRename = (item) => {
    setReNameItem(item);
    setReNameModal(true);
  };

  const handleMove = (items) => {
    setMoveItems(items);
    setMoveModal(true);
  };

  const onUploadSuccess = (list) => {
    if (!Array.isArray(list) || list.length === 0) return;
    createNetworkDiskFiles({
      NetworkDiskFiles: list,
    }).then(() => {
      onSuccess();
    });
  };

  const handleMiltiChange = (item, value) => {
    switch (item) {
      case 'insert':
        setCreateModal(true);
        break;
      case 'sort':
        setSort(value);
        break;
      case 'ways':
        setWays(value);
        break;
      case 'upload':
        iptRef.current.onChange();
        break;
      case 'download': {
        const { PrivateKey, ShareKey } = secret;
        if (value) {
          const { SignURL, Name, EncodeType, FileType } = value;
          const resSecret =
            EncodeType === 1 ? PrivateKey : EncodeType === 2 ? ShareKey : '';
          downLoadUrl(
            SignURL,
            Name,
            resSecret,
            EncodeType === 3 && FileType === 'video'
          );
        } else {
          selectedIds.forEach(({ SignURL, Name, EncodeType, FileType }) => {
            const resSecret =
              EncodeType === 1 ? PrivateKey : EncodeType === 2 ? ShareKey : '';
            downLoadUrl(
              SignURL,
              Name,
              resSecret,
              EncodeType === 3 && FileType === 'video'
            );
          });
        }
        break;
      }
      case 'preview':
        const pre = value
          ? [value]
          : selectedIds.filter((i) => ['img', 'video'].includes(i.FileType));
        setPreviews(pre);
        break;
      case 'delete':
        Modal.confirm({
          title: '確認刪除該文件？',
          content: '（請仔細核對，確認無誤再操作）',
          okType: 'danger',
          okText: '刪除',
          cancelText: '取消',
          onOk: () => {
            const IDs = value ? [value.ID] : selectedIds.map((i) => i.ID);
            DeleteNetworkDiskFiles({
              IDs,
            }).then(() => {
              message.success('刪除成功');
              onSuccess();
            });
          },
        });
        break;
      case 'refresh':
        message.loading('刷新中', 1);
        onSuccess();
        break;
      case 'rename':
        handleRename(value);
        break;
      case 'moveto':
        handleMove(value);
        break;
      case 'checkId':
        setSelectedIds(value);
        break;
      case 'uploadSuccess':
        onUploadSuccess(value);
        break;
      case 'share':
        setShareData(value);
        break;
      default:
        break;
    }
  };

  return (
    <div className={styles.main_content}>
      <TopBar
        ways={ways}
        sort={sort}
        check={!!selectedIds.length}
        onChange={handleMiltiChange}
      />
      <TopNav
        pageId={pageId}
        navTree={navTree}
        {...props}
        onChange={handleMiltiChange}
      />
      <ContainerBox
        ways={ways}
        sort={sort}
        secret={secret}
        iptRef={iptRef}
        pageId={pageId}
        data={pageData}
        onOpen={handleOpen}
        showUpload={showUploadbtn}
        onChange={handleMiltiChange}
      />
      <ModalCreateFolder
        visible={createModal}
        onClose={() => setCreateModal(false)}
        ParentID={pageId}
        Level={pageLevel}
        onSuccess={onSuccess}
      />
      <ModalReName
        visible={reNameModal}
        onClose={() => setReNameModal(false)}
        item={reNameItem}
        onSuccess={onSuccess}
      />
      <ModalMove
        visible={moveModal}
        onClose={() => setMoveModal(false)}
        data={moveItems}
        onSuccess={onSuccess}
        allFiles={fileList}
      />
      <Previews
        secret={secret}
        urls={previews}
        onChange={() => setPreviews([])}
      />
      <ModalShare
        datas={shareData}
        onChange={(res) => {
          if (res) {
            Modal.success({
              title: '分享链接',
              content: res,
              okText: '复制',
              onOk: () => copyText(res),
            });
          }
          setShareData(null);
        }}
      />
    </div>
  );
}
export default connect((state) => ({
  user: state.user.userData,
}))(MyFiles);
