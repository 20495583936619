import React, { useState, useEffect, useRef, useImperativeHandle } from 'react';
import { Col, Modal, notification, Radio } from 'antd';
import IconFont from '@/components/IconFont';
import { encrypt_with_aes } from '@/utils/helper';
import { UploadOSS } from '@/utils/aliOss';
import { getFileType } from '@/utils/helper';
import styles from './index.module.scss';

function UploadFiles({ iptRef, secret, props = {}, onChange }) {
  const inputRef = useRef();

  const [fileVal, setFileVal] = useState('');
  const [fileList, setFileList] = useState({});
  const [visible, setVisible] = useState(false);
  const [EncodeType, setEncodeType] = useState(3);

  useImperativeHandle(iptRef, () => ({
    onChange: () => setVisible(true),
  }));

  const handleChange = (e) => {
    const { files } = e.target;
    setFileVal('');
    if (files.length) {
      for (const file of files) {
        const { name, size } = file;
        const { type } = getFileType(name);
        const reader = new FileReader();
        reader.onload = (res) => {
          const { result } = res.target;
          if (!result) return;
          const params = {
            Name: name.replace(/\s+/g, ''),
            Size: size,
            ...props,
            EncodeType,
            Suffix: `.${name.split('.').reverse()[0]}`,
          };
          const fileFlag = EncodeType === 3 && type === 'video';
          getCid(result, type, params, file, fileFlag);
        };
        reader.readAsDataURL(file);
      }
    }
  };

  const compressImage = (base64, key, name, type) => {
    const oldNode = document.getElementById('compress_thumbnail');
    if (oldNode) document.body.removeChild(oldNode);
    const nodes = new Image();
    document.body.appendChild(nodes);
    nodes.src = base64;
    nodes.style.display = 'none';
    nodes.id = 'compress_thumbnail';
    setTimeout(() => {
      const { width: originWidth, height: originHeight } = nodes;
      const maxWidth = 240,
        maxHeight = 240;
      let targetWidth = originWidth,
        targetHeight = originHeight;
      if (originWidth > maxWidth || originHeight > maxHeight) {
        if (originWidth / originHeight > 1) {
          targetWidth = maxWidth;
          targetHeight = Math.round(maxWidth * (originHeight / originWidth));
        } else {
          targetHeight = maxHeight;
          targetWidth = Math.round(maxHeight * (originWidth / originHeight));
        }
      }
      const canvas = document.createElement('canvas');
      const context = canvas.getContext('2d');
      canvas.width = targetWidth;
      canvas.height = targetHeight;
      context.clearRect(0, 0, targetWidth, targetHeight);
      context.drawImage(nodes, 0, 0, targetWidth, targetHeight);
      canvas.toBlob((blob) => {
        UploadOSS({
          file: blob,
          fileKey: name,
          prefix: `atv_ipfs/${type}_thumbnail`,
          onFinish: (_, { url }) => {
            setFileList((his) => ({
              ...his,
              [key]: { ...his[key], Thumbnail: url },
            }));
          },
        });
      }, 'image/jpeg');
    }, 300);
  };

  const compressVideo = (base64, key, name, type) => {
    const oldNode = document.getElementById('compress_thumbnail');
    if (oldNode) document.body.removeChild(oldNode);
    var nodes = document.createElement('VIDEO');
    nodes.setAttribute('width', '320');
    nodes.setAttribute('height', '240');
    nodes.setAttribute('controls', 'controls');
    nodes.setAttribute('id', 'compress_thumbnail');
    nodes.setAttribute('style', 'display: none');
    nodes.setAttribute('src', base64);
    document.body.appendChild(nodes);
    nodes.currentTime = 1000;
    nodes.addEventListener('canplay', function (e) {
      const canvas = document.createElement('canvas');
      const context = canvas.getContext('2d');
      const { videoWidth: originWidth, videoHeight: originHeight } = this;
      const maxWidth = 240,
        maxHeight = 240;
      let targetWidth = originWidth,
        targetHeight = originHeight;
      if (originWidth > maxWidth || originHeight > maxHeight) {
        if (originWidth / originHeight > 1) {
          targetWidth = maxWidth;
          targetHeight = Math.round(maxWidth * (originHeight / originWidth));
        } else {
          targetHeight = maxHeight;
          targetWidth = Math.round(maxHeight * (originWidth / originHeight));
        }
      }
      canvas.width = targetWidth;
      canvas.height = targetHeight;
      context.clearRect(0, 0, targetWidth, targetHeight);
      context.drawImage(this, 0, 0, targetWidth, targetHeight);
      canvas.toBlob((blob) => {
        UploadOSS({
          file: blob,
          fileKey: name,
          prefix: `atv_ipfs/${type}_thumbnail`,
          onFinish: (_, { url }) => {
            setFileList((his) => ({
              ...his,
              [key]: { ...his[key], Thumbnail: url },
            }));
          },
        });
      }, 'image/jpeg');
    });
  };

  const getCid = async (base64, type, params, file, fileFlag) => {
    try {
      let cid = null;
      if (!window.Ipfs) return;
      if (!window.createIPFS) {
        window.createIPFS = await window.Ipfs.create();
      }
      let resBase = fileFlag ? file : base64.split('base64,').reverse()[0];
      if (params.EncodeType < 3) {
        const { PrivateKey, ShareKey } = secret;
        const str = resBase.substring(0, 10);
        const ciphertext = encrypt_with_aes(
          str,
          params.EncodeType === 1 ? PrivateKey : ShareKey
        );
        resBase = ciphertext + '+atv+' + resBase.substring(10, resBase.length);
      }
      const { cid: CID } = await window.createIPFS.add(resBase);
      cid = CID.toString();
      handleCreateFile(base64, cid, type, params, file, fileFlag, resBase);
    } catch (error) {}
  };

  const handleCreateFile = (
    base,
    cid,
    type,
    params,
    file,
    fileFlag,
    resBase
  ) => {
    const { Suffix } = params || {};
    const key = `${cid}_${type}${fileFlag ? Suffix : '.txt'}`;
    setFileList((his) => ({
      ...his,
      [key]: { ...params, Cid: cid, Thumbnail: '' },
    }));
    if (type === 'video') {
      compressVideo(base, key, `${cid}_thumbnail.jpg`, 'video');
    } else if (type === 'image') {
      compressImage(base, key, `${cid}_thumbnail.jpg`, 'image');
    }
    let blob = null;
    if (!fileFlag) {
      blob = new Blob([resBase]);
      blob.lastModifiedDate = new Date();
      blob.name = key;
    } else {
      blob = file;
    }
    notification.open({
      key,
      icon: <IconFont type='loading' />,
      message: `文件正在传输中`,
      description: params.Name,
      className: styles.notificationBox,
      duration: 0,
    });
    UploadOSS({
      file: blob,
      fileKey: key,
      onFinish: (_, { key: KEY, url }) => {
        blob = undefined;
        notification.close(key);
        notification.success({
          message: `文件传输完成`,
          description: params.Name,
          className: styles.notificationBox,
          duration: 3,
        });
        setFileList((his) => ({
          ...his,
          [KEY]: { ...his[KEY], SignURL: url, FileType: type },
        }));
      },
    });
  };

  useEffect(() => {
    const resArr = Object.values(fileList);
    if (
      resArr.length &&
      resArr.every((i) =>
        ['image', 'video'].includes(i.FileType)
          ? i['Thumbnail'] && i['SignURL']
          : i['SignURL']
      )
    ) {
      onChange(resArr);
      setFileList({});
      setEncodeType(3);
    }
  }, [fileList]);

  return (
    <div className={styles.upload_block}>
      <div
        className={styles.upload_square}
        onClick={() => setVisible(true)}
      ></div>
      <div className={styles.text}>上傳文件</div>
      <input
        multiple
        type='file'
        ref={inputRef}
        value={fileVal}
        style={{ display: 'none' }}
        onChange={handleChange}
      />
      <Modal
        width={360}
        visible={visible}
        title='文件传输'
        onCancel={() => {
          setVisible(false);
          setEncodeType(3);
        }}
        onOk={() => {
          setVisible(false);
          inputRef?.current.click();
        }}
      >
        <Col style={{ padding: '12px 6px' }}>
          加密方式：
          <Radio.Group
            value={EncodeType}
            onChange={(e) => setEncodeType(e.target.value)}
          >
            <Radio value={1}>私有</Radio>
            <Radio value={2}>分享</Radio>
            <Radio value={3}>公开</Radio>
          </Radio.Group>
        </Col>
      </Modal>
    </div>
  );
}
export default UploadFiles;
