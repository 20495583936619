import React, { useEffect, useState } from 'react';
import { connect } from 'dva';
import { panActiveHomeIcon } from '../../assets';
import styles from './index.module.scss';

const mainList = [
  {
    name: '',
    text: '我的文件',
    pathName: '/pan/files',
  },
  {
    name: '',
    text: '我的分享',
    pathName: '/pan/share',
  },
];

function LeftSide({ ...props }) {
  const {
    location: { pathname },
  } = props || {};

  const [subIndex, setSubIndex] = useState(0);
  // const [dorpdownIndex, setDorpdownIndex] = useState();
  const [dropdown, setDropdown] = useState(false);
  const [level, setLevel] = useState(0);

  const changeSubItem = (i, item) => {
    setLevel(1);
    const { pathName: link } = item || {};
    if (i === subIndex) {
      setDropdown(!dropdown);
      link && props.history.push(link);
      return;
    }
    setSubIndex(i);
    link && props.history.push(link);
  };

  useEffect(() => {
    const path = pathname.toLocaleLowerCase();
    const idx = mainList.findIndex((i) => path.indexOf(i.pathName) > -1);
    setSubIndex(idx);
  }, []);

  // const changeFilesType = (e, i, o) => {
  //   const { pathName } = o || {};
  //   e.stopPropagation();
  //   setDorpdownIndex(i);
  //   setLevel(2);
  //   pathName && props.history.push(pathName);
  // };

  const renderSubItemIcon = (i, item) => {
    const { text, subItems: subList } = item || {};
    const isActive = subIndex === i && level < 2;
    return (
      <div
        key={i}
        className={`${styles.sub_item} ${isActive && styles.active}`}
        onClick={() => changeSubItem(i, item)}
      >
        <div
          className={`${styles.sub_item_content} ${isActive && styles.active}`}
        >
          <div className={styles.sub_item_content_panel}>
            <div
              className={`${styles.sub_item_icon} ${
                styles.sub_item_iconSamll
              } ${dropdown && styles.dropdown}`}
            >
              {/* {src && <img src={src} style={{ width: '100%' }} />} */}
            </div>
            <span>{text}</span>
          </div>
        </div>
        {/* {subList && subList.length > 0 && dropdown && (
          <div className={styles.sub_dropdown}>
            {subList.map((o, j) => {
              const {
                icon: sub_icon,
                activeIcon: sub_activeIcon,
                text: sub_text,
              } = o || {};
              const isActive = j === dorpdownIndex && level === 2;
              const src = isActive ? sub_activeIcon : sub_icon;
              return (
                <div
                  className={`${styles.sub_sub_item} ${
                    isActive && styles.active
                  }`}
                  key={j}
                  onClick={(e) => changeFilesType(e, j, o)}
                >
                  <div className={styles.sub_sub_icon}>
                    <img src={src} style={{ width: '100%' }} />
                  </div>
                  <span>{sub_text}</span>
                </div>
              );
            })}
          </div>
        )} */}
      </div>
    );
  };

  return (
    <div className={styles.pan_LeftSide}>
      <div className={styles.pan_leftSide_main}>
        <img src={panActiveHomeIcon} />
        <span>云盘</span>
      </div>
      {mainList.map((item, i) => renderSubItemIcon(i, item))}
    </div>
  );
}

export default connect()(LeftSide);
