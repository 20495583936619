import React from 'react';
import Mask from '@/components/Mask';
import styles from './index.module.scss';

function Guide(props) {
  return (
    <div className={styles['mask-content']} onClick={props.onClick}>
      <img
        className={styles['jiantou']}
        src={require('./image/jiantou.png')}
      ></img>
      <p>1.点击右上角</p>
      <img src={require('./image/step.png')} className={styles['step']}></img>
      <p>2.选择“在浏览器中打开”</p>
    </div>
  );
}

export default (props) => (
  <Mask opacity='.8' content={<Guide onClick={() => props.onClick()} />}></Mask>
);
