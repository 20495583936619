import React, { useState, useEffect } from 'react';
import classnames from 'classnames';
import Toast from '../../../components/Toast';
import styles from './index.module.scss';

let timer = null;
export default function GetCode({ onStart, phone, email, type = 'phone' }) {
  const [state, setState] = useState({
    state: 'ready',
    text: '獲取驗證碼',
  });
  //  wait
  useEffect(() => {
    if (state.state !== 'wait') return;
    if (typeof state.text == 'number' && state.text > 0) {
      timer = setTimeout(() => {
        setState((cur) => ({
          state: 'wait',
          text: cur.text - 1,
        }));
      }, 1000);
    } else {
      timer = setTimeout(() => {
        setState({
          state: 'ready',
          text: '獲取驗證碼',
        });
      }, 1000);
    }
    return () => {
      clearTimeout(timer);
    };
  }, [state.state, state.text]);

  const check = () => {
    if (type == 'phone') {
      if (phone == '') return { msg: '請輸入手機號' };
      return true;
    } else if (type == 'email') {
      if (
        !/^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(
          email
        )
      ) {
        return { msg: '郵箱格式不正確' };
      }
      return true;
    }
  };
  const click = () => {
    const res = check();
    if (res !== true) {
      Toast(res.msg);
      return;
    }
    if (state.state === 'wait') return;
    if (state.state === 'ready') {
      setState({
        state: 'wait',
        text: 60,
      });
    }

    onStart && onStart();
  };
  return (
    <span onClick={click} className={classnames(styles.get_code_check)}>
      {typeof state.text == 'number' ? state.text + '秒后重发' : state.text}
    </span>
  );
}
