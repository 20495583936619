import React, { useState, useEffect } from 'react';
import { getFileTypeIcon } from '@/utils/helper';
import IconFont from '@/components/IconFont';
import { updateFiles } from '@/api';
import { message } from 'antd';
import Modal from '../Modal';
import Loading from '../Loading';
import styles from './index.module.scss';

function ModalReName({ item, visible, onClose, onSuccess }) {
  const { ID, Level, ParentID, Name, FileType, Suffix } = item || {};
  const fileIcon = getFileTypeIcon(item);

  const [newName, setNewName] = useState('');
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (!Name) return;
    setNewName(Name.replaceAll(Suffix, ''));
  }, [Name]);

  const onChangeName = (e) => {
    setNewName(e.target.value);
  };

  const onConfirm = () => {
    const resName = newName.replace(/\s+/g, '');
    if (!resName) {
      message.warning('請輸入文件夾名字！');
      return;
    }
    if (resName == Name) {
      message.warning('文件名未更改');
      return;
    }
    onClose();
    setLoading(true);
    const NetworkDiskFiles = [
      {
        Name: resName + Suffix,
        ParentID: parseInt(ParentID),
        ID: parseInt(ID),
        Level: parseInt(Level - 1),
      },
    ];
    const body = { NetworkDiskFiles };
    updateFiles(body)
      .then(() => {
        setLoading(false);
        onSuccess();
      })
      .catch((err) => {
        setLoading(false);
      });
  };

  return (
    <>
      <Modal visible={visible} onClose={onClose}>
        <div className={styles.modal_content}>
          <div className={styles.modal_header}>
            <div className={styles.modal_title}>重命名</div>
            <IconFont
              type='close'
              className={styles.modal_close}
              onClick={onClose}
            />
          </div>
          <div className={styles.modal_container}>
            <div className={styles.modal_container_img}>
              <img src={fileIcon[FileType]} width='100%' />
            </div>
            <input
              type='text'
              maxLength={22}
              value={newName || ''}
              onChange={onChangeName}
              className={styles.create_folder_name_input}
            />
          </div>
          <div className={styles.modal_bottom}>
            <div className={styles.modal_confirm_btn} onClick={onConfirm}>
              <span>確定</span>
            </div>
          </div>
        </div>
      </Modal>
      <Loading visible={loading} />
    </>
  );
}
export default ModalReName;
