import React from 'react';
import IconFont from '@/components/IconFont';
import styles from './index.module.scss';

function TopBar({ check, ways, sort, onChange }) {
  return (
    <div className={styles.top_bar}>
      {check ? (
        <div className={styles.top_bar_files}>
          <div
            className={styles.right_block_item}
            onClick={() => onChange('upload')}
          >
            <IconFont type='cloud-upload' className={styles.item_icon} />
            <span>上傳</span>
          </div>
          <div className={styles.right_block_divider}></div>
          <div
            className={styles.right_block_item}
            onClick={() => onChange('download')}
          >
            <IconFont type='cloud-download' className={styles.item_icon} />
            <span>下載</span>
          </div>
          <div className={styles.right_block_divider}></div>
          {/* <div
            className={styles.right_block_item}
            onClick={() => onChange('preview')}
          >
            <IconFont type='eye' className={styles.item_icon} />
            <span>預覽</span>
          </div>
          <div className={styles.right_block_divider}></div> */}
          <div
            className={styles.right_block_item}
            onClick={() => onChange('delete')}
          >
            <IconFont type='delete' className={styles.item_icon} />
            <span>刪除</span>
          </div>
        </div>
      ) : (
        <div className={styles.top_bar_files}>
          <div
            className={styles.right_block_item}
            onClick={() => onChange('insert')}
          >
            <IconFont type='folder' className={styles.item_icon} />
            <span>新建文件夾</span>
          </div>
          <div className={styles.right_block_divider}></div>
          <div
            className={styles.right_block_item}
            onClick={() => onChange('upload')}
          >
            <IconFont type='cloud-upload' className={styles.item_icon} />
            <span>上傳</span>
          </div>
          <div className={styles.right_block_divider}></div>
          {/* <div
            className={styles.right_block_item}
            onClick={() => onChange('sort', !sort)}
          >
            <IconFont type='bars' className={styles.item_icon} />
            <span>{sort ? '正序' : '倒敘'}</span>
          </div>
          <div className={styles.right_block_divider}></div> */}
          <div
            className={styles.right_block_item}
            onClick={() => onChange('ways', ways === 'list' ? 'icon' : 'list')}
          >
            <IconFont
              type={ways !== 'list' ? 'menu' : 'appstore'}
              className={styles.item_icon}
            />
            <span>{ways !== 'list' ? '列表' : '圖標'}</span>
          </div>
        </div>
      )}
      <span>*雙擊打开文件夾</span>
    </div>
  );
}

export default TopBar;
