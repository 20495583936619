import React, { useState } from 'react';
import Input from '@/components/Input';
import check from '@/utils/checkFrom';
import { connect } from 'dva';
import Toask from '@/components/Toast';
import styles from './index.module.scss';

// 账号密码登录
function UserLoginFrom({ onGoFindPwd, dispatch }) {
  const [formValue, setformValue] = useState({
    user: '',
    password: '',
    pwdType: 'password',
    setpwdType: () => {
      return setformValue((cur) => {
        return {
          ...cur,
          pwdType: cur.pwdType === 'text' ? 'password' : 'text',
        };
      });
    },
    onChangeUser: ({ target: { value: user } }) => {
      setformValue((cur) => ({
        ...cur,
        user,
      }));
    },
    onChangePwd: ({ target: { value: password } }) =>
      setformValue((cur) => ({
        ...cur,
        password,
      })),
  });
  const checkForm = ({ user = '', password = '' }) => {
    const fd = check([
      {
        targetStr: user,
        rules: {
          isNull: {
            params: '請輸入賬號',
          },
        },
      },
      {
        targetStr: user,
        rules: {
          minLength: {
            params: [6, '賬號長度不可以小於 6 位'],
          },
        },
      },
    ]);
    if (fd != true) {
      return fd;
    }

    return check([
      {
        targetStr: password,
        rules: {
          isNull: {
            params: '請輸入密碼',
          },
        },
      },
      {
        targetStr: password,
        rules: {
          minLength: {
            params: [6, '密碼長度不可以小於 6 位'],
          },
        },
      },
    ]);
  };
  const onLogin = () => {
    const state = checkForm({
      user: formValue.user,
      password: formValue.password,
    });
    if (state !== true) {
      Toask(state.msg);
      return;
    }
    dispatch({
      type: 'user/userOrEmailLogin',
      payload: {
        UserName: formValue.user,
        Password: formValue.password,
        // AccountType: 1
      },
    });
  };

  return (
    <div
      className={styles.content}
      onKeyDown={(e) => {
        if (e.keyCode === 13) onLogin();
      }}
    >
      <Input
        onChange={formValue.onChangeUser}
        value={formValue.user}
        placeholder='請輸入賬號'
      />
      <p className={styles.sdfsd}></p>
      <Input
        onClickIcon={formValue.setpwdType}
        getRightIcon={() => {
          return (
            <img
              src={
                require(`../image/${
                  formValue.pwdType == 'text' ? 'yj' : 'by'
                }.png`).default
              }
            />
          );
        }}
        onChange={formValue.onChangePwd}
        value={formValue.password}
        type={formValue.pwdType}
        placeholder='請輸入登錄密碼'
      />
      <p className={styles.fsjido}>
        <span
          onClick={() => {
            onGoFindPwd && onGoFindPwd();
          }}
          className={styles.forgetPwd}
        >
          忘記密碼?
        </span>
      </p>
      <div
        onClick={() => {
          onLogin();
        }}
        className={styles.btn_login}
      >
        登錄
      </div>
    </div>
  );
}

export default connect()(UserLoginFrom);
