import React, { useState } from 'react';
import classnames from 'classnames';
import styles from './index.module.scss';

function Imagea({ src = '', onLoaded = (_) => 1, ...props } = {}) {
  const [state, setState] = useState('loading');
  const image = new Image();
  image.src = src;
  image.onload = () => {
    setState('idle');
    onLoaded();
  };
  if (src === '') {
    return <img src='' className={styles.loading}></img>;
  }
  return (
    <img
      {...props}
      className={classnames({ loading: styles.state === 'loading' })}
      src={src}
    />
  );
}
export default React.memo(Imagea);
