// 管理全局加载状态
export default {
  namespace: 'mussy',
  state: {
    isShowTop: true,
    isShowBottom: true,
  },
  reducers: {
    setItem(state, { payload }) {
      return {
        ...state,
        ...payload,
      };
    },
  },
};
