import React from 'react';
import Loading from '@/components/Loading';
import styles from './index.module.scss';

export default function LoadingLine(props) {
  return (
    <div className={styles.loading_line}>
      <Loading />
    </div>
  );
}
