import React from 'react';
import { Image, Spin } from 'antd';
import { LoadingOutlined } from '@ant-design/icons';
import './index.scss';

const antIcon = <LoadingOutlined style={{ fontSize: 36 }} spin />;

function ContentItemTwo(props) {
  const { Preview, Name, Describe } = props;
  return (
    <div
      className='content-item-two'
      id='dsjif232od'
      onClick={() => {
        props.onToPlayer(props);
      }}
    >
      {/*<img className='img' src={Preview} />*/}
      <Image
        preview={false}
        src={Preview}
        placeholder={
          <div className={'image-placeholder'}>
            <Spin
              style={{color: 'rgba(255, 255, 255, 0.8)'}}
              size={'default'}
              indicator={antIcon}
            />
          </div>
        }
      />
      <div className='text'>
        <p className='title_' title={Name}>
          {Name}
        </p>

        <p className='msg_' title={Describe}>
          {Describe}
        </p>
      </div>
    </div>
  );
}
export default React.memo(ContentItemTwo);
