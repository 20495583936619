import React, { useState } from 'react';
import IconFont from '@/components/IconFont';
import { createNetworkDiskFiles } from '@/api';
import { message } from 'antd';
import Modal from '../Modal';
import Loading from '../Loading';
import { folder } from '../../assets';
import styles from './index.module.scss';

function ModalCreateFolder({
  ParentID = 0,
  Level = 1,
  visible,
  onClose,
  onSuccess,
}) {
  const [name, setName] = useState('新建文件夾');
  const [loading, setLoading] = useState(false);

  const onChangeName = (e) => {
    setName(e.target.value);
  };

  const onConfirm = () => {
    const resName = name.replace(/\s+/g, '');
    if (!resName) {
      message.warning('请输入文件名称');
      return;
    }
    onClose();
    setLoading(true);
    const NetworkDiskFiles = [
      {
        Name: resName,
        ParentID,
        FileType: 'folder',
        Level,
      },
    ];
    const body = { NetworkDiskFiles };
    createNetworkDiskFiles(body)
      .then((res) => {
        if (res.Code === 1000) {
          const id = res.IDs[0];
          setLoading(false);
          onSuccess(id);
          setName('新建文件夾');
        }
      })
      .catch(() => {});
  };

  return (
    <>
      <Modal visible={visible} onClose={onClose}>
        <div className={styles.modal_content}>
          <div className={styles.modal_header}>
            <div className={styles.modal_title}>新建文件夾</div>
            <IconFont
              type='close'
              className={styles.modal_close}
              onClick={onClose}
            />
          </div>
          <div className={styles.modal_container}>
            <div className={styles.modal_container_img}>
              <img src={folder} width='100%' />
            </div>
            <input
              type='text'
              value={name}
              maxLength={22}
              onChange={onChangeName}
              className={styles.create_folder_name_input}
            />
          </div>
          <div className={styles.modal_bottom}>
            <div className={styles.modal_confirm_btn} onClick={onConfirm}>
              <span>確定</span>
            </div>
          </div>
        </div>
      </Modal>
      <Loading visible={loading} />
    </>
  );
}
export default ModalCreateFolder;
