import { getUploadToken } from '@/api';
import { message, notification } from 'antd';
import IconFont from '@/components/IconFont';
import CryptoJS from 'crypto-js';
import { audio, files, folder } from '../pages/Pan/assets';
import styles from './index.module.scss';

export const getAliToken = () => {
  return new Promise((r, j) => {
    const { uploadToken } = global || {};
    const { expiration } = uploadToken || {};
    const nowTime = Date.now() + 5 * 60 * 1000;
    const expira = new Date(expiration).getTime();
    if (expira <= nowTime || !uploadToken) {
      getUploadToken()
        .then((res) => {
          const {
            AccessKeyId,
            AccessKeySecret,
            Bucket,
            Code,
            Endpoint,
            Expiration,
            RegionID,
            SecurityToken,
            cdnUrl,
          } = res;
          if (Code === 1000) {
            const OSSKEY = {
              accessKeyId: AccessKeyId,
              accessKeySecret: AccessKeySecret,
              bucket: Bucket,
              endpoint: Endpoint,
              expiration: Expiration,
              region: RegionID,
              stsToken: SecurityToken,
              cdnUrl,
            };
            global.uploadToken = OSSKEY;
            r(OSSKEY);
          } else {
            j();
          }
        })
        .catch((err) => {
          j(err);
        });
    } else {
      r(uploadToken);
    }
  });
};

export const imageSuffix = [
  'jpg',
  'jpeg',
  'png',
  'gif',
  'svg',
  'ipg',
  'heic',
  'webp',
  'bmp',
  'tif',
];
export const videoSuffix = [
  'mp4',
  'webm',
  'm3u8',
  'avi',
  'mov',
  'wmv',
  'flv',
  'mkv',
];
export const audioSuffix = [
  'mp3',
  'wav',
  'ogg',
  'mpeg',
  'aac',
  'flac',
  'ape',
  'alac',
];

export function getFileType(resource) {
  if (!resource) return resource;
  const resourceArr = resource.toLocaleLowerCase().split('.');
  const suffix = resourceArr[resourceArr.length - 1];
  const isPicture = imageSuffix.includes(suffix);
  const isAudio = audioSuffix.includes(suffix);
  const isVideo = videoSuffix.includes(suffix);

  if (isPicture) {
    return { type: 'image' };
  }
  if (isAudio) {
    return { type: 'audio' };
  }
  if (isVideo) {
    return { type: 'video' };
  }
  return { type: 'file' };
}

export const getFileTypeIcon = (fileItem) => {
  const { Name, FileType, Thumbnail } = fileItem || {};
  if (FileType === 'folder') return folder;
  const suffix = (Name ?? '').split('.').reverse()[0].toLocaleLowerCase();
  const fileGroup = [
    {
      key: 'image',
      type: imageSuffix,
      icon: Thumbnail,
    },
    {
      key: 'video',
      type: videoSuffix,
      icon: Thumbnail,
    },
    {
      key: 'audio',
      type: audioSuffix,
      icon: audio,
    },
  ];
  const result = fileGroup.find((i) => i.type.includes(suffix));
  return result && result.icon ? result.icon : files;
};

/**
 * 文本复制
 * @param {string} val
 */
export const copyText = (val) => {
  var input = document.createElement('textarea');
  input.value = val;
  document.body.appendChild(input);
  input.select();
  input.setSelectionRange(0, input.value.length), document.execCommand('Copy');
  document.body.removeChild(input);
  message.success('复制成功');
};

export const encrypt_with_aes = (msg, key) => {
  if (!msg || !key) return msg;
  key = CryptoJS.enc.Utf8.parse(key);
  var encrypted = CryptoJS.AES.encrypt(msg, key, {
    iv: key,
    mode: CryptoJS.mode.CBC,
    padding: CryptoJS.pad.Pkcs7,
  });
  const hexStr = encrypted.ciphertext.toString(CryptoJS.enc.Hex);
  var oldHexStr = CryptoJS.enc.Hex.parse(hexStr);
  var base64Str = CryptoJS.enc.Base64.stringify(oldHexStr);
  return base64Str;
};

/**
 * 解密
 * @param {*} msg
 * @param {*} key
 * @param {*} max
 * @returns
 */
export const decrypt_with_aes = (msg, key) => {
  if (!msg || !key) return msg ?? '';
  key = CryptoJS.enc.Utf8.parse(key);
  var decrypted = CryptoJS.AES.decrypt(msg, key, {
    iv: key,
    mode: CryptoJS.mode.CBC,
    padding: CryptoJS.pad.Pkcs7,
  });
  return decrypted.toString(CryptoJS.enc.Utf8);
};

export const downLoadBlob = (name, blob, key) => {
  const urls = window.URL.createObjectURL(blob);
  const link = document.createElement('a');
  link.style.display = 'none';
  link.setAttribute('href', urls);
  link.setAttribute('download', name);
  document.body.appendChild(link);
  link.click();
  window.URL.revokeObjectURL(link.href);
  document.body.removeChild(link);
  notification.close(key);
};

export const downLoadUrl = (url, name, key, flag) => {
  notification.open({
    key: url,
    icon: <IconFont type='loading' />,
    message: `文件正在下载中`,
    description: name,
    className: styles.notificationBox,
    duration: 0,
  });
  const x = new XMLHttpRequest();
  x.open('GET', url, true);
  x.responseType = 'blob';
  x.onload = function () {
    if (!flag) {
      const reader = new FileReader();
      reader.onload = function () {
        let resBase = reader.result;
        const [base64, dense] = resBase.split('+atv+').reverse();
        if (dense && key) {
          resBase = decrypt_with_aes(dense, key) + base64;
        } else {
          resBase = base64;
        }
        const bytes = window.atob(resBase);
        const ab = new ArrayBuffer(bytes.length);
        const ia = new Uint8Array(ab);
        for (let i = 0; i < bytes.length; i++) {
          ia[i] = bytes.charCodeAt(i);
        }
        const blob = new Blob([ab]);
        downLoadBlob(name, blob, url);
      };
      reader.readAsText(x.response);
    } else {
      downLoadBlob(name, x.response, url);
    }
  };
  x.send();
};

/**
 * byte转换
 * @param {*} byte
 */
export const transByte = (byte) => {
  if (!byte) return `0B`;
  let resutl = byte;
  if (byte / 1024 < 1) return `${resutl}B`;
  resutl = resutl / 1024;
  if (resutl / 1024 < 1) return `${resutl.toFixed(2)}KB`;
  resutl = resutl / 1024;
  if (resutl / 1024 < 1) return `${resutl.toFixed(2)}MB`;
  resutl = resutl / 1024;
  return `${resutl.toFixed(2)}GB`;
};

const AES_KEY = '4kqvNg8LyIe1WQTs';

export const encrypt_aes = (msg, key = AES_KEY) => {
  if (!msg) {
    return msg;
  }
  key = CryptoJS.enc.Utf8.parse(key);
  var encrypted = CryptoJS.AES.encrypt(msg, key, {
    iv: key,
    mode: CryptoJS.mode.CBC,
    padding: CryptoJS.pad.Pkcs7,
  });
  const hexStr = encrypted.ciphertext.toString(CryptoJS.enc.Hex);
  var oldHexStr = CryptoJS.enc.Hex.parse(hexStr);
  var base64Str = CryptoJS.enc.Base64.stringify(oldHexStr);
  return base64Str;
};

export const decrypt_aes = (msg, key = AES_KEY) => {
  if (!msg) {
    return msg;
  }
  key = CryptoJS.enc.Utf8.parse(key);
  var decrypted = CryptoJS.AES.decrypt(msg, key, {
    iv: key,
    mode: CryptoJS.mode.CBC,
    padding: CryptoJS.pad.Pkcs7,
  });
  return decrypted.toString(CryptoJS.enc.Utf8);
};
