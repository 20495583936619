import React, { useState } from 'react';
import { SpliceData } from '../../../../utils';
import ContentItemTwo from '../ContentItemTwo';
import { offsetIndexMove } from '@/utils';
import { connect } from 'dva';
import Loading from '@/components/Loading';
import './index.scss';
import classNames from 'classnames';
function ListThree(props) {
  const { title, onToPlayer, onUpdate, loading, topicsDetailedsLoadingItem } =
    props;
  const listNum = 4;
  const [offset, setOffset] = useState({ start: 0, end: listNum });
  const data = SpliceData({
    datas: props.data,
    maxLength: offset.end,
    startIndex: offset.start,
  });

  const onHuan = () => {
    if (loading) return;
    if (
      offset.end + listNum >= props.curCount * props.CurPage &&
      props.CurPage < props.TotalPage
    ) {
      onUpdate && onUpdate(props.index, props.id);
      setOffset({ start: offset.end, end: offset.end + listNum });
    } else {
      const { startIndex, endIndex } = offsetIndexMove({
        start: offset.start,
        end: offset.end,
        num: listNum,
        maxLength: props.data.length,
      });
      setOffset({
        start: startIndex,
        end: endIndex,
      });
    }
  };
  return (
    <div className='three content-list'>
      <div className='title'>
        <span className='title-text'>{title}</span>
        <div onClick={onHuan} className={'huan_fds2s'}>
          <div className={'huan_img_fdji2'} /> <span>換壹換</span>
        </div>
      </div>
      <div className={classNames('content', { loading: loading })}>
        {loading && topicsDetailedsLoadingItem === props.id ? (
          <Loading />
        ) : (
          <>
            {data.map((e) => {
              return (
                <ContentItemTwo onToPlayer={onToPlayer} key={e.ID} {...e} />
              );
            })}
          </>
        )}
      </div>
    </div>
  );
}
export default React.memo(
  connect((state) => ({
    loading: state['loading']['effects']['screen/updateTopicsDetailedItem'],
    topicsDetailedsLoadingItem: state.screen.topicsDetailedsLoadingItem,
  }))(ListThree)
);
