import React, { useEffect, useState } from 'react';
import classnames from 'classnames';
import { areaCode } from '@/utils';
import Input from '../Input';
import './index.scss';

export default function PhoneInput(props) {
  const [area, setArea] = useState(null);
  const [isShowWrap, setIsShowWrap] = useState(false);
  const value = props.value.value ?? '';

  const onChange = ({ target: { value } }) => {
    props.onChange({
      value: value,
      area: area,
    });
  };

  useEffect(() => {
    if (!area) {
      setArea(props.value.area || 852)
    }
  }, [])

  return (
    <div className={'phone_input'}>
      <p onClick={() => setIsShowWrap(true)}>+{area ?? ''}</p>
      {isShowWrap ? (
        <div className={'phone_input_wrapper'}>
          {Object.keys(areaCode).map((e) => {
            return (
              <div
                key={e}
                onClick={() => {
                  setArea(e);
                  setIsShowWrap(false);
                  props.onChange({
                    phone: value,
                    area: e,
                  });
                }}
                className={classnames('item', {
                  global_active_bg_fdji23: e == area,
                })}
              >
                <span>{e}</span>
                <span>{areaCode[e]}</span>
              </div>
            );
          })}
        </div>
      ) : null}
      <Input
        {...props}
        value={value}
        onChange={onChange}
        style={{ width: '100%' }}
        placeholder='請輸入手機號碼'
      />
    </div>
  );
}
