// 弹框状态
export default {
  namespace: 'modelState',
  state: {
    loginOrRegister: {
      state: false,
      data: {},
    },
    charge: {
      state: false,
      data: {},
    },
    explain: {
      state: false,
      data: {
        contentName: 'clause',
      },
    },
  },
  reducers: {
    setLoginOrRegister(state, { payload }) {
      return {
        ...state,
        loginOrRegister: {
          ...state.loginOrRegister,
          ...payload,
        },
      };
    },
    setCharge(state, { payload }) {
      return {
        ...state,
        charge: {
          ...payload,
        },
      };
    },
    setExplain(state, { payload }) {
      return {
        ...state,
        explain: {
          ...state.explain,
          ...payload,
        },
      };
    },
  },
};
