import React, { useState } from 'react';
import Input from '../../../components/Input';
import check from '@/utils/checkFrom';
import Toask from '@/components/Toast';
import classnames from 'classnames';
import GetCode from '../GetCode';
import { connect } from 'dva';
import PhoneInput from '../../../components/PhoneInput';
import SliderCheck from '../../../components/SliderCheck';
import styles from './index.module.scss';

function PhoneLoginForm({ dispatch, ...props }) {
  const [formState, setFormState] = useState({
    phone: {
      value: '',
      area: 852,
    },
    code: '',
  });
  const [isShowCheckSlider, setIsShowCheckSlider] = useState(false);
  const checkForm = ({ phone = '', code = '' }) => {
    const fd = check([
      {
        targetStr: phone,
        rules: {
          isNull: {
            params: '請輸入手機號',
          },
        },
      },
    ]);
    if (fd !== true) return fd;
    return check([
      {
        targetStr: code,
        rules: {
          isNull: {
            params: '請輸入驗證碼',
          },
        },
      },
      {
        targetStr: code,
        rules: {
          minLength: {
            params: [6, '驗證碼長度不能小於 6 位'],
          },
        },
      },
    ]);
  };
  const sendCode = ({ sig, csessionid, value, token, ...data }) => {
    dispatch({
      type: 'user/sendCode',
      payload: {
        Sig: sig,
        Token: token,
        SessionId: csessionid,
        Mobile: formState.phone.value,
        Operation: 3,
        Kind: 1,
        Region: formState.phone.area + '',
      },
    });
  };
  const onLogin = () => {
    const state = checkForm({
      phone: formState.phone.value,
      code: formState.code,
    });
    if (state !== true) {
      Toask(state.msg);
      return;
    }
    dispatch({
      type: 'user/codoLogin',
      payload: {
        Mobile: formState.phone.value,
        VerifyCode: formState.code,
        Operation: 1,
        Kind: 1,
      },
    });
  };

  return (
    <div
      className={classnames(styles.content)}
      onKeyDown={(e) => {
        if (e.keyCode === 13) onLogin();
      }}
    >
      <PhoneInput
        onChange={(_) => {
          setFormState({ ...formState, phone: _ });
        }}
        value={formState.phone}
        placeholder='請輸入手機號碼'
      />
      <p className={styles.sdfsd}></p>
      <Input
        onChange={(_) => setFormState({ ...formState, code: _.target.value })}
        value={formState.code}
        placeholder='請輸入驗證碼'
        getRightIcon={() => (
          <GetCode
            type={'phone'}
            phone={formState.phone.value}
            onStart={() => setIsShowCheckSlider(true)}
          />
        )}
      />
      <p className={styles.fsjido}></p>
      <div
        onClick={() => {
          onLogin();
        }}
        className={styles.btn_login}
      >
        登錄
      </div>
      {isShowCheckSlider ? (
        <SliderCheck
          onEndCheck={(data) => {
            setIsShowCheckSlider(false);
            sendCode(data);
          }}
        />
      ) : null}
    </div>
  );
}

export default connect()(PhoneLoginForm);
