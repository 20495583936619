import React from 'react';
import App from './App';
import dva from 'dva';
import 'antd/dist/antd.css';
import user from './models/user.js';
import modelStates from './models/modelStates';
import { createBrowserHistory } from 'history';
import screen from './models/screen';
import loading from './models/loading';
import createLoading from 'dva-loading';
import comment from './models/comment';
import mussy from './models/mussy';

const app = dva({
  history: createBrowserHistory(),
});

app.router((props) => <App {...props} />);
app.model(comment);
app.model(modelStates);
app.model(user);
app.model(loading);
app.model(mussy);
app.use(createLoading());
app.model(screen);
app.start('#root');
