import CryptoJS from 'crypto-js';
import React from 'react';
export const SpliceData = ({
  datas = [],
  maxLength,
  reverse,
  startIndex = 0,
}) => {
  if (datas.length < maxLength) return datas;
  const newArr = [];
  if (reverse) datas = datas.reverse();

  for (let i = startIndex; i < maxLength; i++) {
    newArr.push(datas[i]);
  }
  return newArr;
};
export const getRandom = (min = 0, max = 100) =>
  Math.random() * (max - min) + min;
export const pxToRem = (doc, win) => {
  var docEl = doc.documentElement,
    resizeEvt = 'orientationchange' in window ? 'orientationchange' : 'resize',
    recalc = function () {
      var clientWidth = docEl.clientWidth;
      if (!clientWidth) return;
      if (clientWidth >= 750) {
        docEl.style.fontSize = '150px';
      } else {
        docEl.style.fontSize = 150 * (clientWidth / 750) + 'px';
      }
      var html = document.getElementsByTagName('html')[0];

      var settedFs = parseInt(150 * (clientWidth / 750));
      var whileCount = 0;
      let settingFs = 0;
      while (true) {
        var realFs = parseInt(window.getComputedStyle(html).fontSize);
        var delta = realFs - settedFs;
        //不相等
        if (Math.abs(delta) >= 1) {
          if (delta > 0) settingFs--;
          else settingFs++;
          html.setAttribute('style', 'font-size:' + settingFs + 'px!important');
        } else break;
        if (whileCount++ > 150) break;
      }
    };
  if (!doc.addEventListener) return;
  win.addEventListener(resizeEvt, recalc, false);
  doc.addEventListener('DOMContentLoaded', recalc, false);
};
export const pollExecuteFunc = function () {
  let mfd = 0;
  let timers = null;
  return (func, timer) => {
    mian();

    async function mian() {
      func().then(
        (d) => {
          clearTimeout(timers);
          timers = setTimeout(() => {
            mian();
          }, timer);
        },
        () => {}
      );
    }
  };
};
export const offsetIndexMove = ({ start, end, num, maxLength }) => {
  let startIndex = start + num;
  let endIndex = end + num;

  if (startIndex >= maxLength) {
    startIndex = 0;
    endIndex = num;
  } else if (endIndex === maxLength) {
    endIndex = num;
    startIndex = 0;
  } else if (endIndex > maxLength) {
    startIndex = startIndex - (endIndex - maxLength);
    endIndex = maxLength;
  }

  return {
    startIndex,
    endIndex,
  };
};
export const getVipIcon = (e) => {
  if (isVip(e)) {
    return (
      <img
        src={require('@/assets/images/vip.png').default}
        className={styles.vip_icon}
      />
    );
  }
  if (isFufei(e)) {
    return (
      <img
        src={require('@/assets/images/fu_fei.png').default}
        className={styles.vip_icon}
      />
    );
  }
  return null;
};
export const asyncHide = (() => {
  let timer;
  return ({ dom, targetDoms, timerfds = 300, callback }) => {
    if (!Array.isArray(targetDoms)) targetDoms = [targetDoms];
    targetDoms.forEach((e) => {
      if (!e) return;
      e.addEventListener('mouseenter', () => {
        clearTimeout(timer);
      });
    });
    timer = setTimeout(() => {
      if (callback) {
        callback();
      } else dom.style.display = 'none';
    }, timerfds);
  };
})();
export const isVip = (data) => {
  if (data == undefined) return false;
  return data.Level === 2;
};
export function getClassifyId(pathname) {
  if (typeof pathname !== 'string') {
    throw '不是个字符串';
  }
  let classfiys = pathname.split('/').pop();
  if (!isNaN(+classfiys)) {
    classfiys = classfiys * 1;
  }

  return classfiys;
}
export function getRendomNum(min = 1, max = 100) {
  return Math.floor(Math.random() * (max - min) + min);
}
export const handleSum = (num) => {
  return num / 100;
};
export const getToken = () => {
  return window.localStorage.getItem('token');
};
export function isLogin() {
  return getToken() !== null;
}
export const getCondition = (TopicID, classiFyID) => {
  if (classiFyID == 1) {
    return {
      ClassiFyID: classiFyID * 1,
    };
  } else {
    return {
      TopicID: TopicID * 1,
    };
  }
};
export const areaCode = {
  86: '中国大陆',
  852: '香港',
  853: '澳门',
  886: '台湾',
  60: '马来西亚',
  62: '印度尼西亚',
  63: '菲律宾',
  65: '新加坡',
  66: '泰国',
  671: '关岛',
  673: '文莱',
  81: '日本',
  82: '韩国',
  84: '越南',
  850: '朝鲜',
  855: '柬埔寨',
  856: '老挝',
  880: '孟加拉国',
  90: '土耳其',
  91: '印度',
  92: '巴基期坦',
  94: '斯里兰卡',
  961: '马尔代夫',
  962: '黎巴嫩',
  963: '约旦',
  964: '叙利亚',
  965: '伊拉克',
  966: '科威物',
  967: '沙特',
  968: '也门',
  969: '阿曼',
  972: '阿联酋',
  973: '以色列',
  974: '巴林',
  975: '卡塔尔',
  976: '不丹',
  977: '蒙古',
  978: '尼泊尔',
  98: '伊朗',
  93: '阿富汗',
};

export const setItem = (key, value) => {
  if (typeof value === 'object') {
    window.localStorage.setItem(key, JSON.stringify(value));
  }
  window.localStorage.setItem(key, value + '');

  return true;
};
export const getItem = (key) => {
  const res = window.localStorage.getItem(key);
  if (/^{}$/.test(res) || /^\[\]$/.test(res)) {
    return JSON.parse(res);
  }
  return res;
};
export const getAvatar = () => {
  const a = window.localStorage.getItem('Portrait');
  if (a === null || a === '') {
    return require('@/assets/images/defaultAvatar.png').default;
  }
  return a;
};

export const encrypt_with_aes = (msg, key) => {
  if (!msg) {
    return msg;
  }
  key = PaddingLeft(key, 16);
  key = CryptoJS.enc.Utf8.parse(key);
  var encrypted = CryptoJS.AES.encrypt(msg, key, {
    iv: key,
    mode: CryptoJS.mode.CBC,
    padding: CryptoJS.pad.Pkcs7,
  });
  const hexStr = encrypted.ciphertext.toString(CryptoJS.enc.Hex);

  var oldHexStr = CryptoJS.enc.Hex.parse(hexStr);
  var base64Str = CryptoJS.enc.Base64.stringify(oldHexStr);
  return base64Str;
};

const PaddingLeft = (key, length) => {
  let pkey = key.toString();
  const l = pkey.length;
  if (l < length) {
    pkey = new Array(length - l + 1).join('0') + pkey;
  } else if (l > length) {
    pkey = pkey.slice(length);
  }
  return pkey;
};

export const decrypt_with_aes = (msg, key) => {
  if (!msg) {
    return msg;
  }
  key = PaddingLeft(key, 16);
  key = CryptoJS.enc.Utf8.parse(key);
  var decrypted = CryptoJS.AES.decrypt(msg, key, {
    iv: key,
    mode: CryptoJS.mode.CBC,
    padding: CryptoJS.pad.Pkcs7,
  });
  return decrypted.toString(CryptoJS.enc.Utf8);
};

export const ifNotLoginToHome = (history) => {
  if (!isLogin()) {
    history.push('/');
    return true;
  }
};

export const handleAccount = (num) => {
  return /\d{4}(?=)/;
};

export const fillDomWrapper = ({ arr, colNum, width, tag = 'li' }) => {
  const _arr = [...arr];

  while (true) {
    if (_arr.length % colNum === 0) return _arr;
    _arr.push(React.createElement(tag, { style: { width }, key: _arr.length }));
  }
};

export const onToPlayer = ({ data, history, dispatch }) => {
  if (isLogin()) {
    history.push('/player/' + data.ID);
  } else {
    dispatch({
      type: 'modelState/setLoginOrRegister',
      payload: {
        state: true,
      },
    });
  }
};

// 解析JSON数据
export const parseJSON = (str, res) => {
  let result = null;
  try {
    const data = JSON.parse(str);
    if (typeof data !== 'object') {
      if (Array.isArray(res)) {
        result = [data];
      } else {
        result = { _default: data };
      }
    } else {
      result = data || res || {};
    }
  } catch (error) {
    if (str && typeof str === 'string') {
      if (Array.isArray(res)) {
        result = [str];
      } else {
        result = { _default: str };
      }
    } else {
      result = res || {};
    }
  }
  return result;
};

export const getPathLastItem = (path) => {
  if (!path) return;
  const pathArr = path.split('/');
  return pathArr[pathArr.length - 1];
};

/**
 * 获取param参数
 */
export const getUrlParams = (key) => {
  let local = window.location.search;
  local = local.replace('?', '');
  const arr = local.split('&').filter((i) => i);
  const res = arr.find((i) => i.indexOf(`${key}=`) === 0) || '';
  return res.replace(`${key}=`, '');
};

export const throttle = (fn, delay, suspend) => {
  let timer = null;
  return function (...args) {
    if (!timer || suspend) {
      timer = setTimeout(() => {
        fn.apply(this, args);
        timer = null;
      }, delay);
    }
  };
};

// 获取首页分类数据聚合
export const getTopicData = (topic, topicDatas) => {
  const topics = [];
  for (let i = 0; i < topic.length; i++) {
    const temp = topic[i];
    const data = topicDatas[temp.ID] || [];
    topics.push({
      id: temp.ID,
      data,
      title: temp.Name,
      ...temp
    });
  }
  const hot = [{
    id: 0,
    data: topicDatas[0] || [],
    title: '熱播',
    classiFyID: 'tj'
  }];
  const recommend = [{
    id: 999,
    data: topicDatas[0] || [],
    title: '為妳推薦',
    classiFyID: 'tj'
  }];
  return { topics, hot, recommend };
};