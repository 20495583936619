import Router_w from './router/';
import zhCN from 'antd/es/locale-provider/zh_CN';
import { ConfigProvider, message, notification } from 'antd';
import { routerRedux } from 'dva/router';
import { Toast } from './components/Toast';
import React, { useEffect } from 'react';
import GetModels from './ModelVives';
import { pxToRem } from './utils';
import './global.scss';

message.config({ maxCount: 1 });
notification.config({
  // top: 16,
  duration: 0,
});

function App({ history }) {
  // 移动端判断
  if (/Android|webOS|iPhone|iPod|BlackBerry/i.test(navigator.userAgent)) {
    // return (window.location.href = 'https://atv.h5.hkatv.vip/');
    return (window.location.href = 'https://www3.hkatv.vip/');
  }

  useEffect(() => {
    // 转化rem单位
    if (window.location.pathname === '/download')
      pxToRem(window.document, window);
    else document.getElementsByTagName('html')[0].style.fontSize = '16px';
  }, []);

  return (
    <ConfigProvider locale={zhCN}>
      <div style={{ height: '100%' }}>
        <routerRedux.ConnectedRouter history={history}>
          <Router_w />
        </routerRedux.ConnectedRouter>
        <GetModels />
        <Toast />
      </div>
    </ConfigProvider>
  );
}

export default App;
