import React from 'react';
import { Image, Spin } from 'antd';
import { LoadingOutlined } from '@ant-design/icons';
import './index.scss';

ContentItemOne.defaultProps = {
  url: '//liangcang-material.alicdn.com/prod/upload/0a7e145e19fc4197bc3c505845982233.jpg?x-oss-process=image/resize,w_290/interlace,1/quality,Q_80',
  title: '恋爱先生',
  msg: '恋爱先生教你谈恋爱',
};

const antIcon = <LoadingOutlined style={{ fontSize: 24 }} spin />;

function ContentItemOne(props) {
  const { Preview, Name, Describe } = props;
  return (
    <li
      className='content-item-one'
      onClick={() => {
        props.onToPlayer(props);
      }}
    >
      <Image
        preview={false}
        src={Preview}
        placeholder={
          <div className={'image-placeholder'}>
            <Spin
              style={{color: 'rgba(255, 255, 255, 0.8)'}}
              size={'default'}
              indicator={antIcon}
            />
          </div>
        }
      />
      <p className='title_' title={Name}>
        {Name}
      </p>
      <p className='msg' title={Describe}>
        {Describe || <span style={{ color: 'transparent' }}>1</span>}
      </p>
    </li>
  );
}
export default React.memo(ContentItemOne);
