import classNames from 'classnames';
import React, { useState } from 'react';
import Toask from '../../../../components/Toast';
import { isVip } from '../../../../utils';
import styles from './index.module.scss';
export default function CommentSetting({
  onChangeColor,
  currentColor,
  userData,
}) {
  const [view, setView] = useState(0);
  const getContent = () => {
    if (view == 0) {
      return (
        <>
          <p className={styles.msg}>會員彈幕尊享色</p>
          <div className={styles.btns_row}>
            {[
              { text: '金', color: '#FED388' },
              { text: '绿', color: 'green' },
              { text: '藍', color: '#5FC9FF' },
              { text: '橙', color: '#FF7F00' },
              { text: '紅', color: '#FF6E99' },
              { text: '紫', color: '#AC8CFB' },
            ].map((e) => {
              return (
                <div
                  key={e.text}
                  className={classNames(styles.color_botton)}
                  style={{
                    color: e.color,
                    border: `1px solid ${
                      e.color === 'white' ? '#FED388' : e.color
                    }`,
                  }}
                  onClick={() => {
                    if (isVip(userData)) onChangeColor(e.color);
                    else Toask('请先开通会员');
                  }}
                >
                  {e.text}

                  {currentColor === e.color ? (
                    <div
                      className={styles.current_style}
                      style={{
                        background: e.color === 'white' ? '#E95555' : e.color,
                      }}
                    >
                      <img src={require('./images/g.png').default} />
                    </div>
                  ) : null}
                  <img
                    className={styles.vip_icon}
                    src={require('../../images/vip.png').default}
                  />
                </div>
              );
            })}
          </div>
          <p className={styles.msg}>彈幕顏色</p>
          <div className={styles.btns_row}>
            {[{ text: '默认', color: 'white' }].map((e) => {
              return (
                <div
                  key={e.text}
                  className={classNames(styles.color_botton)}
                  style={{
                    color: e.color,
                    border: `1px solid ${
                      e.color === 'white' ? '#E95555' : e.color
                    }`,
                  }}
                  onClick={() => onChangeColor(e.color)}
                >
                  {e.text}
                  {currentColor === e.color ? (
                    <div
                      className={styles.current_style}
                      style={{
                        background: e.color === 'white' ? '#E95555' : e.color,
                      }}
                    >
                      <img src={require('./images/g.png').default} />
                    </div>
                  ) : null}
                </div>
              );
            })}
          </div>
        </>
      );
    }
  };
  return (
    <div className={styles.comment_setting}>
      <div className={styles.header}>
        <div
          onClick={() => setView(0)}
          className={classNames(styles.style, styles.btns, {
            [styles.active_view]: view === 0,
          })}
        >
          弹幕样式
        </div>
        {/* <div onClick={() => setView(1)} className={classNames(styles.setting, styles.btns, { [styles.active_view]: view === 1 })}>
                    观看设置
                </div> */}
      </div>
      <div className={styles.sdfasfsa}>{getContent()}</div>
    </div>
  );
}
