import {
  getResources,
  getClassifys,
  getTopicsDetail,
  getBanner,
  getTopics,
  searchResource,
  getHomeAggregation,
  getHomeTopicData
} from "@/api";
import { getCondition, getTopicData } from '@/utils';

export default {
  namespace: 'screen',
  state: {
    classifysItemText: 'tj',
    // 大分类
    classifys: [],
    // 推荐页 资源
    resources: {},
    // 轮播图资源
    banner: [],
    // 主题资源项
    topicsDetaileds: [],
    currentClassifyId: null,
    searchResource: [],
    // 没有数据 只有title 跟 id
    currentTopics: [],
    // 记录哪一项在加载中
    topicsDetailedsLoadingItem: null,
    // 首页聚合数据
    home: {
      classifys: [],
      banner: [],
      nav: [],
      topics: [],
      tjData: [],
      recommend: [],
    },
  },
  reducers: {
    // 设置主页聚合数据
    setHomeAggregation(state, { payload }) {
      return {
        ...state,
        ...payload
      };
    },
    setItem(state, { payload }) {
      return {
        ...state,
        ...payload,
      };
    },
    // 添加一个 主题系列
    addTopicsDetailedsItem(state, { payload, select }) {
      if (payload.classiFyID !== state.currentClassifyId) return state;
      return {
        ...state,
        topicsDetaileds: [...state.topicsDetaileds, payload],
      };
    },
    updateTopicsDetailedsItem(state, { payload: { index, data }, select }) {
      const preData = state.topicsDetaileds[index].data;
      const newData = [].concat(preData, data.Resources);
      const newPage = data.CurPage;
      const leftArr = state.topicsDetaileds.slice(0, index);
      const rightArr = state.topicsDetaileds.slice(
        index + 1,
        state.topicsDetaileds.length
      );
      const newArr = [].concat(
        leftArr,
        {
          ...state.topicsDetaileds[index],
          Resources: newData,
          data: newData,
          CurPage: newPage,
        },
        rightArr
      );

      return {
        ...state,
        topicsDetaileds: newArr,
      };
    },
    clearTopicsDetailedsItem(state) {
      return {
        ...state,
        topicsDetaileds: [],
      };
    },
  },
  effects: {
    // 获取首页分类数据聚合
    *getHomeTopicData({ payload: { classiFyID } }, { call, put, select}) {
      // 取出首页缓存数据
      const home = yield select((state) => state.screen.home);

      if (classiFyID === 'tj') {
        const { tjData } = home;
        yield put({
          type: 'setHomeAggregation',
          payload: {
            home: { ...home, topics: tjData },
            classifysItemText: classiFyID,
          }
        });
      } else {
        const result = yield call(getHomeTopicData, classiFyID);

        if (result.Code === 1000) {
          const topic = result.Data.Topics || [];
          const topicDatas = result.Data.GetResources || [];
          const { topics, recommend } = getTopicData(topic, topicDatas);

          yield put({
            type: 'setHomeAggregation',
            payload: {
              home: { ...home, topics: topics.concat(recommend) },
              classifysItemText: classiFyID,
            }
          });
        }
      }
    },
    // 获取首页聚合数据
    *getHomeAggregation({ payload }, { call, put }) {
      const data = yield call(getHomeAggregation, {});
      if (data.Code === 1000) {
        // 获取大分类
        const d = data.Data.GetClassifys;
        const classifys = d && d.length ? d.map(e => ({
          id: e.ID,
          name: e.Name,
          Extra: e.Extra
        })) : [];

        // 轮播图资源
        const b = data.Data.GetResources;
        const bt = Object.keys(b || {});
        const banner = bt.length ? (b[-1] || []) : [];

        // 获取首页分类数据聚合
        const { topics, hot, recommend } = getTopicData(d, b);
        const topicsArray = hot.concat(topics, recommend);

        // 快捷入口标签数据
        const nav = data.Data.GetCategory;
        yield put({ type: 'setHomeAggregation', payload: {
          home: {
            classifys,
            banner,
            nav,
            topics: topicsArray,
            tjData: topicsArray,
          }}
        });
      }
    },
    // 获取大分类
    *getClassifys({ payload: {} }, { call, put }) {
      let data = yield call(getClassifys);
      if (data.Code !== 1000) return;

      data = data.Classifys.map((e) => {
        return {
          id: e.ID,
          name: e.Name,
          Extra: e.Extra,
        };
      });
      yield put({
        type: 'setItem',
        payload: {
          classifys: data,
        },
      });
    },
    *updateTopicsDetailedItem(
      { payload: { index, id } },
      { call, put, select, fork }
    ) {
      yield put({
        type: 'setItem',
        payload: {
          topicsDetailedsLoadingItem: id,
        },
      });
      const state = yield select((state) => state.screen);
      const type = state.classifysItemText;

      if (type === 'tj') {
        const resa = yield call(
          getResources,
          state.topicsDetaileds[index].classiFyItemId,
          state.topicsDetaileds[index].CurPage + 1
        );
        yield put({
          type: 'updateTopicsDetailedsItem',
          payload: {
            index: index,
            data: resa,
          },
        });
      } else {
        const pro = yield call(
          getTopicsDetail,
          getCondition(state.currentTopics.Topics[index].ID, type),
          state.topicsDetaileds[index].CurPage + 1
        );

        yield put({
          type: 'updateTopicsDetailedsItem',
          payload: {
            index: index,
            data: pro,
          },
        });
      }
    },
    // 单独获取推荐页 数据
    *getResources(a, { call, put, select, fork }) {
      let arr = [];
      let i = 0;
      let res;
      function a(arr) {
        return Promise.race(arr);
      }
      yield put({
        type: 'clearTopicsDetailedsItem',
      });
      const d = yield call(getClassifys);

      if (d.Code !== 1000) return;
      for (let j = 0; j < d.Classifys.length; j++) {
        const resa = yield call(getResources, d.Classifys[j].ID);
        arr.push(resa);
      }
      arr.unshift(getResources());

      d.Classifys.unshift({
        Name: '熱播',
      });

      while (i < arr.length) {
        res = yield call(() => arr[i]);

        yield put({
          type: 'addTopicsDetailedsItem',
          payload: {
            data: res.Resources || [],
            title: d.Classifys[i].Name,
            curCount: res.CurCount,
            totalCount: res.TotalCount,
            classiFyID: 'tj',
            id: i,
            classiFyItemId: d.Classifys[i].ID,
            ...res,
          },
        });
        i++;
      }

      const res_ = yield call(getTopicsDetail, { ClassiFyID: 0 }, 1, 24);

      yield put({
        type: 'addTopicsDetailedsItem',
        payload: {
          data: res_.Resources || [],
          title: '猜你喜歡',
          classiFyID: 'tj',
          infinite: true,
          curCount: res.CurCount,
          totalCount: res.TotalCount,
          classiFyItemId: 0,
          id: 999,
          ...res,
        },
      });
    },
    // 获取轮播数据
    *getBanner({ payload: { classfiys } }, { call, put }) {
      function getCondition(classiFyID) {
        if (classfiys == 'tj' || classfiys == 'home') {
          return {
            RecommendBannerType: 1,
          };
        } else {
          return {
            BannerType: 1,
            classiFyID: classfiys,
          };
        }
      }
      const condition = getCondition();
      let res = yield call(getBanner, condition);
      if (res.Code !== 1000) return;
      yield put({
        type: 'setItem',
        payload: {
          banner: res.Resources || [],
        },
      });
    },
    // 获取主题数据源列表
    *getTopics({ payload: { classiFyID } }, { select, call, put, fork }) {
      if (classiFyID != 1 && classiFyID != 20) {
        // 获取主题
        let data = yield call(getTopics, {
          ClassiFyID: classiFyID * 1,
        });
        // 储存 topics
        yield put({
          type: 'setItem',
          payload: {
            currentTopics: data,
          },
        });
        if (data.Code !== 1000) return;

        if (data.Topics === undefined || !data.Topics) return;

        let arr = [];
        let res;
        let i = 0;
        // 轮询去得到每一个主题项的数据
        for (let i = 0; i < data.Topics.length; i++) {
          const pro = getTopicsDetail(
            getCondition(data.Topics[i].ID, classiFyID)
          );
          arr.push(pro);
        }

        while (i < arr.length) {
          res = yield call(() => arr[i]);

          yield put({
            type: 'addTopicsDetailedsItem',
            payload: {
              data: res.Resources,
              title: data.Topics[i].Name,
              classiFyID: classiFyID,
              curCount: res.CurCount,
              totalCount: res.TotalCount,
              id: i,
              ...res,
            },
          });
          i++;
        }
        const res_ = yield call(
          getTopicsDetail,
          { ClassiFyID: classiFyID },
          1,
          24
        );

        yield put({
          type: 'addTopicsDetailedsItem',
          payload: {
            data: res_.Resources,
            title: '為妳推薦',
            classiFyID: classiFyID,
            infinite: true,
            curCount: res.CurCount,
            totalCount: res.TotalCount,
            id: 99,
            ...res,
          },
        });
      } else {
        const res_ = yield call(
          getTopicsDetail,
          { ClassiFyID: classiFyID },
          1,
          80
        );
        yield put({
          type: 'addTopicsDetailedsItem',
          payload: {
            data: res_.Resources,
            title: '為妳推薦',
            classiFyID: classiFyID,
            id: 998,
          },
        });
      }
    },
    *searchResource({ payload }, { call, put }) {
      const res = yield call(searchResource, { ...payload });
      if (res.Code === 1000) {
        yield put({
          type: 'setItem',
          payload: {
            searchResource: res,
          },
        });
        return res;
      }
    },
    *selectState({ payload }, { call, put, select }) {
      return yield select((state) => state.screen);
    },
  },
  // subscriptions: {
  //   listenHistory({ dispatch, history }, done) {
  //     history.listen(async (locatin, action) => {
  //       const pathname = location.pathname;
  //       if (/^\/$/.test(pathname)) {
  //         const res = await dispatch({
  //           type: 'selectState',
  //         });
  //         let classfiys = res.classifysItemText;
  //         if (res.classifys[0] === undefined) {
  //           dispatch({
  //             type: 'getClassifys',
  //             payload: {},
  //           });
  //         }
  //
  //         dispatch({
  //           type: 'setItem',
  //           payload: {
  //             currentClassifyId: classfiys,
  //           },
  //         });
  //         // 更新轮播图
  //         dispatch({
  //           type: 'getBanner',
  //           payload: {
  //             classfiys,
  //           },
  //         });
  //
  //         if (classfiys == 'tj') {
  //           dispatch({
  //             type: 'getResources',
  //           });
  //         } else {
  //           // 清空原有数据
  //           dispatch({
  //             type: 'clearTopicsDetailedsItem',
  //           });
  //           dispatch({
  //             type: 'getTopics',
  //             payload: {
  //               classiFyID: classfiys,
  //             },
  //           });
  //         }
  //       }
  //     });
  //   },
  // },
};
