import xml from '../utils/xml';

export const GetTV = async () => {
  return await xml.post('/TVHandler/GetTV', {
    Offset: 1,
    Limit: 100,
    Conditions: {
      Status: 2,
      Aes: 1,
    },
  });
};

export const userLoginOrEmail = async (data) => {
  return await xml.post('/UserHandler/SignIn', data);
};

export const userRegister = async (data) => {
  return await xml.post('/UserHandler/SignUp', {
    ...data,
  });
};

export const registerSendCode = async (data) => {
  return await xml.post('/UserHandler/SendVerifyCode', {
    ...data,
  });
};

export const getUserData = async (token) => {
  return await xml.post(
    '/UserHandler/GetUserInfo',
    {},
    {
      headers: {
        token: token,
      },
    }
  );
};

// 得到我的收藏
export const getCollect = async (token) => {
  return await xml.post(
    '/Screen/ScreenHandler/GetCollectResource',
    {
      Limit: 10,
      Offset: 1,
    },
    {
      headers: {
        token: token,
      },
    }
  );
};

// 开通会员接口
export const startMember = async ({ token, body }) => {
  return await xml.post('/UserHandler/Payment', body, {
    headers: {
      token,
    },
  });
};

export const pollPayState = async (body) => {
  return await xml.post('/UserHandler/PaymentRecord', body);
};

// 验证码登录
export const signInVerifyCode = async (body) => {
  return await xml.post('/UserHandler/SignInVerifyCode', body);
};

export const getVideo = async (id, Offset = 1) => {
  id = id !== undefined ? { CategoryID: id } : {};
  return await xml.post('/Item/ItemHandler/GetItems', {
    Limit: 18,
    Offset,
    Conditions: {
      CategoryType: 1,
      ItemType: 2,
      ...id,
    },
  });
};

export const saveVideoHistory = async (reqBody) => {
  return await xml.post('/Screen/ScreenHandler/SaveVideoHistory', reqBody);
};

export const getCategoryService = async () => {
  return await xml.post('/Item/ItemHandler/GetCategory', {
    Offset: 1,
    Limit: 10,
    Conditions: {
      CategoryType: 1,
      CategoryKind: 5,
    },
  });
};

export const getPayConfig = async (token) => {
  return xml.post(
    '/UserHandler/GetBecomeVipConfig',
    {},
    {
      headers: {
        token,
      },
    }
  );
};

export const createComment = async (data) => {
  return await xml.post('/CommentHandler/CreateComment', data);
};

export const GetComments = async (data) => {
  return await xml.post('/CommentHandler/GetComments', data);
};

export const getNetworkDiskFiles = async (reqBody) => {
  return await xml.post('/UserHandler/GetNetworkDiskFile', reqBody);
};

export const createNetworkDiskFiles = async (reqBody) => {
  return await xml.post('/UserHandler/CreateNetworkDiskFiles', reqBody);
};

export const getUploadToken = async (reqBody) => {
  return await xml.post('/UserHandler/GetAliyunToken', reqBody);
};

export const updateFiles = async (reqBody) => {
  return await xml.post('/UserHandler/UpdateNetworkDiskFiles', reqBody);
};

export const DeleteNetworkDiskFiles = async (reqBody) => {
  return await xml.post('/UserHandler/DeleteNetworkDiskFiles', reqBody);
};

export const GetNetworkDiskShare = async (reqBody) => {
  return await xml.post('/UserHandler/GetNetworkDiskShare', reqBody);
};

export const GetMyNetworkDiskShare = async (reqBody) => {
  return await xml.post('/UserHandler/GetMyNetworkDiskShare', reqBody);
};

export const GetNetworkDiskPayURL = async (reqBody) => {
  return await xml.post('/UserHandler/GetNetworkDiskPayURL', reqBody);
};

export const CreateNetworkDiskShare = async (reqBody) => {
  return await xml.post('/UserHandler/CreateNetworkDiskShare', reqBody);
};

export const DeleteMyNetworkDiskShare = async (reqBody) => {
  return await xml.post('/UserHandler/DeleteMyNetworkDiskShare', reqBody);
};

// 大分类源
export const getClassifys = async () => {
  return await xml.post('/Screen/ScreenHandler/GetClassifys', {}, {});
};

// 获取广告列表
export const getADList = async (query = {}) => {
  return await xml.get('/Util/UtilHandler/GetAD', {
    params: {
      Limit: 1,
      Offset: 1,
      ScreenOpen: 1,
      ...query,
    },
  });
};

export const getADDetail = async (data) => {
  return await xml.get('/Util/UtilHandler/GetADByID', data);
};

export const postADStat = async (reqBody) => {
  return await xml.post('/Util/UtilHandler/ADStat', reqBody);
};

// 获取推荐页数据源
export const getResources = async (classiFyID, Offset = 1, Limit = 30) => {
  let condition = { RecommendType: 1, classiFyID };
  if (classiFyID === undefined) {
    condition = {
      RecommendHotType: 1,
    };
  }
  return await xml.post('/Screen/ScreenHandler/GetResources', {
    Conditon: condition,
    Offset,
    Limit,
  });
};

// 获取首页聚合数据
export const getHomeAggregation = async (Conditon) => {
  return await xml.post('/Aggregation/Web/Index', {});
};

// 获取首页分类数据聚合
export const getHomeTopicData = async (id) => {
  return await xml.post('/Aggregation/Web/TopicData', { ClassiFyID: id });
};

// 获取首页推荐轮播图
export const getBanner = async (conditon) => {
  let r = {};
  return await xml.post('/Screen/ScreenHandler/GetResources', {
    Conditon: conditon,
    Offset: 1,
    Limit: 10,
  });
};

// 获取主题数据源
export const getTopics = async (Conditon) => {
  return await xml.post('/Screen/ScreenHandler/GetTopics', {
    Conditon,
  });
};

// POST 获取主题资源
export const getTopicsDetail = async (Conditon, Offset = 1, size = 30) => {
  return await xml.post('/Screen/ScreenHandler/GetResources', {
    Conditon,
    Offset,
    Limit: size,
  });
};

// 获取资源详情
export const getDetailResource = async (id) => {
  return await xml.post('/Screen/ScreenHandler/DetailResource', {
    ID: id,
    Aes: 1,
  });
};

// 得到播放权限ID
export const getCookie = async () => {
  return await xml.post('/Util/UtilHandler/GetCdnCookies', {});
};

// 得到播放记录
export const GetVideoHistory = async (token) => {
  return await xml.post(
    '/Screen/ScreenHandler/GetVideoHistory',
    {
      Limit: 10,
      Offset: 1,
    },
    {
      headers: {
        token,
      },
    }
  );
};

export const searchResource = async ({
  pageNo = 1,
  pageSize = 99,
  searchKeyWord = '',
}) => {
  return await xml.post('/Screen/ScreenHandler/GetResources', {
    Offset: pageNo,
    Limit: pageSize,
    Conditon: {
      UpFlag: 1,
      Name: searchKeyWord,
    },
  });
};

// 收藏资源
export const collectResource = async (reqBody) => {
  return await xml.post('/Screen/ScreenHandler/CollectResource', reqBody);
};

export const likeResource = async (reqBody) => {
  return await xml.post('/Screen/ScreenHandler/LikeResource', reqBody);
};

// 获取资源视频
export const GetVideoPlayURL = async (reqBody) => {
  return await xml.post('/Screen/ScreenHandler/GetVideoPlayURL', reqBody);
};
