import React, { useEffect, useState } from 'react';
import styles from './index.module.scss';
import Guide from '@/components/Guide';

const setTitle = (text) => {
  document.setTitle = function (t) {
    document.title = t;
    var i = document.createElement('iframe');
    i.src = '//m.baidu.com/favicon.ico';
    i.style.display = 'none';
    i.onload = function () {
      setTimeout(function () {
        i.remove();
      }, 9);
    };
    document.body.appendChild(i);
  };

  setTimeout(function () {
    document.setTitle(text);
  }, 1);
};

export default function DownLoad() {
  setTitle('亚洲电视APP下载');
  const [isShowMask, setIsShowMask] = useState(false);
  const androidDownLoadUrl = 'https://atv-cdn.hkatv.vip/atv-app-apk/HKATV.apk';
  
  useEffect(() => {
    const rootWrap = document.getElementsByClassName('root_wrap')[0];
    rootWrap.style.background = 'white';
  }, [])

  // Event mothods
  // 苹果 下载
  const iphoneDowdLoadHandle = () => {};
  const isWXbrowser = () => {
    const kernel = window.navigator.userAgent;
    return kernel.indexOf('MicroMessenger') > -1;
  };
  // 安卓  下载
  const androidDowdLoadHandle = (e) => {
    e.preventDefault();
    // 是否为微信端
    if (isWXbrowser()) {
      setIsShowMask(true);
    } else {
      window.open(androidDownLoadUrl);
    }
  };

  return (
    <div className={styles['down-load']}>
      <img
        className={styles['logo']}
        src={require('./image/logo.png')['default']}
      ></img>
      <img
        className={styles['msg']}
        src={require('./image/msg.png')['default']}
      ></img>
      <a
        onClick={androidDowdLoadHandle}
        href={androidDownLoadUrl}
        className={styles['down-botton'] + ' ' + styles['android']}
      >
        <img src={require('./image/anzuo-icon.png')['default']}></img>
        <span>Android下载(Apk)</span>
      </a>
      <a
        target='__break'
        href={'https://play.google.com/store/apps/details?id=com.hkatv.android'}
        className={styles['down-botton'] + ' ' + styles['android']}
      >
        <img src={require('./image/anzuo-icon.png').default}></img>
        <span className={styles['google-play']}>Android下载(GooglePlay)</span>
      </a>
      <a
        href={
          'https://apps.apple.com/cn/app/atv-%E4%BA%9E%E6%B4%B2%E9%9B%BB%E8%A6%96/id1262995639'
        }
        className={styles['down-botton'] + ' ' + styles['iphone']}
        onClick={iphoneDowdLoadHandle}
      >
        <img src={require('./image/iphone-icon.png').default}></img>
        <span>iPhone下载</span>
      </a>
      <div className={styles['code-wrap']}>
        <div className={styles['android'] + ' ' + styles['code-down']}>
          <img
            src={'https://atv-cdn.hkatv.vip/atv-app-apk/download_android.png'}
          />
          <span>Android下载</span>
        </div>
        <div className={styles['iphone'] + ' ' + styles['code-down']}>
          <img src={'https://atv-cdn.hkatv.vip/atv-app-apk/download_ios.png'} />
          <span>iPhone下载</span>
        </div>
      </div>
      {isShowMask ? (
        <Guide
          onClick={(a) => {
            setIsShowMask(false);
            // setTitle('亞洲電視')
          }}
        />
      ) : null}
    </div>
  );
}
// 旧版本APK二维码下载
// https://ttaf.oss-cn-hongkong.aliyuncs.com/att-apk/download_android.png
// https://ttaf.oss-cn-hongkong.aliyuncs.com/att-apk/download_ios.png
