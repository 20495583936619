import React, { useEffect, useState } from 'react';
import Top from './components/Top';
import Item from './components/Item';
import { getPayConfig, startMember, pollPayState } from '@/api';
import { getToken, pollExecuteFunc } from '../../utils';
import QRCode from 'qrcode.react';
import classNames from 'classnames';
import { H5_PREFIX } from '../../config';
import { connect } from 'dva';
import Toast from '../../components/Toast';
import styles from './index.module.scss';

let windowIframe = {};
let timer = null;
let pay_id;
let timer_Fdji23 = null;

function Charge({ user, setLoading, updateUserData, chargeData }) {
  const [data, setData] = useState([]);
  // 当前选中的项
  const [selectItem, setSelectItem] = useState(0);
  const [payType, setPayType] = useState('pay');
  const [content, setContent] = useState('');
  const [iframe, setIframe] = useState(null);
  const isMember = () => chargeData.data.buySomething === 'member';
  const isrResource = () => chargeData.data.buySomething === 'resource';
  // let { QRCodeValue } = self.state;
  useEffect(() => {
    (async function () {
      if (isMember()) {
        setLoading(true);
        const { Data } = await getPayConfig(getToken());
        setData(Data);
        setLoading(false);
      }
    })();

    return () => {
      clearInterval(timer);
      clearInterval(timer_Fdji23);
    };
  }, []);

  useEffect(() => {
    if (!data.length && isMember()) return
    if (['pay', 'pay_dl', 'wPay', 'wPay_dl'].includes(payType)) {
      setPayImg().then((d) => {
        startPoll();
      });
    }
  }, [selectItem, data]);
  
  // 刷新二维码
  const onUpdateCode = (type) => {
    onChangePayType(type)
  };
  const getPaypalBody = () => {
    if (isMember()) {
      return {
        Channel: 'paypal', //VIP、影片支付还是用paypal，礼物和商品用paypal2
        VipConfigID: data[selectItem].ID,
        Kind: 1,
      };
    }
    if (isrResource()) {
      return {
        Channel: 'paypal', //VIP、影片支付还是用paypal，礼物和商品用paypal2
        VideoID: chargeData.data.videoID,
        Kind: 2,
      };
    }
  };
  const paypalEnterPay = async () => {
    setLoading(true);
    let { Data } = await startMember({
      token: getToken(),

      body: getPaypalBody(),
    });

    setLoading(false);
    Data = JSON.parse(Data);
    const { clientId, currency, paypalId } = Data.credentials || {};
    const url = `${H5_PREFIX}/paypal?clientId=${clientId}&currency=${currency}&paypalId=${paypalId}`;
    windowIframe = window.open(url, '支付', undefined, false);
    timer = setInterval(() => {
      if (windowIframe.closed) {
        // 刷新用户信息
        updateUserData();
        alert('信息更新成功');
        clearInterval(timer);
      }
    }, 1000);
  };
  const getAlipayBody = (type) => {
    if (isMember()) {
      return {
        Channel: type === 'uPay' ? 'eft_unionpay_web' : type === 'pay' ? 'eft_alipay_hk_web' : 'eft_alipay_cn_web',
        VipConfigID: data[selectItem].ID,
        Kind: 1,
        device: 1,
        UserID: user?.ID,
      };
    }
    if (isrResource()) {
      return {
        Channel: type === 'uPay' ? 'eft_unionpay_web' : type === 'pay' ? 'eft_alipay_hk_web' : 'eft_alipay_cn_web',
        VideoID: chargeData.data.videoID,
        Kind: 2,
        device: 1,
        UserID: user?.ID,
      };
    }
  };
  const getWxPayBody = (type) => {
    if (isMember()) {
      return {
        // Channel: type === 'wPay' ? 'eft_wechat_hk_web' : 'eft_wechat_cn_web',
        Channel: type === 'wPay' ? 'eft_wechat_hk_wap_gzh' : 'eft_wechat_cn_wap_gzh',
        VipConfigID: data[selectItem].ID,
        Kind: 1,
        device: 1,
        UserID: user?.ID,
      };
    }
    if (isrResource()) {
      return {
        // Channel: type === 'wPay' ? 'eft_wechat_hk_web' : 'eft_wechat_cn_web',
        Channel: type === 'wPay' ? 'eft_wechat_hk_wap_gzh' : 'eft_wechat_cn_wap_gzh',
        VideoID: chargeData.data.videoID,
        Kind: 2,
        device: 1,
        UserID: user?.ID,
      };
    }
  };
  const getShowPrice = () => {
    if (isMember()) return data[selectItem].Price / 100;
    else if (isrResource()) return chargeData.data.price;
  };
  const setPayImg = async (type) => {
    type = type || payType;
    setLoading(true);
    let { Data, PayID } = await startMember({
      body: type.indexOf('wPay') > -1 ? getWxPayBody(type) : getAlipayBody(type),
    });
    pay_id = PayID;

    setLoading(false);
    let url = '';
    try {
      const { id, credentials } = JSON.parse(Data);
      // url = credentials?.alipay_wap?.url || ''
      url = credentials || ''
    } catch { }
    
    setContent(
      <>
        <div className={styles.dji92} onClick={() => onUpdateCode(type)}>
          {url && <QRCode value={url} />}
        </div>
        {/* {type.indexOf('wPay') > -1 ? (
          <button disabled={!url} className={styles.asfeas} onClick={() => window.open(url)}>去支付</button>
        ) : (
          <div className={styles.dji92} onClick={() => onUpdateCode(type)}>
            {url && <QRCode value={url} />}
          </div>
        )} */}
        <p className={styles.stylefds}>
          支付金額：HK$ <span className={styles.money}>{getShowPrice()}</span>
        </p>
      </>
    );
  };
  const getContent = () => {
    // if (content === '')
    //   return (
    //     <div className={styles.enter_btn} onClick={() => paypalEnterPay()}>
    //       使用paypal支付{user && user.Level === 2 ? '續費會員' : '開通會員'}
    //     </div>
    //   );
    return content;
  };
  const poll = pollExecuteFunc();
  const getPollBody = (pay_id) => {
    if (isMember()) {
      return {
        // UserId: user.ID,
        PayID: pay_id + '',
        Kind: 1,
      };
    }
    if (isrResource()) {
      return {
        // UserId: user.ID,
        PayID: pay_id + '',
        Kind: 2,
      };
    }
  };
  // 开始轮询
  const stopPoll = () => clearInterval(timer_Fdji23);
  const startPoll = async () => {
    clearTimeout(timer_Fdji23);
    timer_Fdji23 = setInterval(async () => {
      const { Data } = await pollPayState(getPollBody(pay_id));
      const { Status } = Data?.[0] || {};
      if (Status === 20) {
        paySuccess();
        stopPoll();
        chargeData.callback && chargeData.callback();
      }
    }, 5000);
  };

  const paySuccess = () => {
    Toast('支付成功', true, 6000);
    // 刷新用户信息
    updateUserData();
  };
  // 是否是大陆
  const onChangePayType = async (type, isDaLu) => {
    setPayType(type);
    if (type === 'paypal') {
      setContent('');
    } else if (['pay', 'pay_dl', 'wPay', 'wPay_dl', 'uPay'].includes(type)) {
      setPayImg(type).then((d) => {
        startPoll();
      });
    }
  };

  const getOne = () => {
    if (isMember()) {
      return (
        <div className={styles.hui_yuan}>
          <p className={styles.title}>會員套餐</p>
          <div
            className={styles.options}
            style={{ display: 'flex', justifyContent: 'space-between' }}
          >
            {data &&
              data.map((e, i) => {
                return (
                  <Item
                    i={i}
                    onselect={(i) => setSelectItem(i)}
                    selectItem={selectItem}
                    key={e.ID}
                    {...e}
                  />
                );
              })}
          </div>
        </div>
      );
    }
    return null;
  };
  return (
    <div className={styles.charge}>
      {iframe ? <iframe {...iframe}></iframe> : null}
      <div className={styles.content}>
        <Top onCloseV={() => clearInterval(timer)} />
        <div className={styles.main_content}>
          {getOne()}
          <p className={styles.title}>支付方式</p>
          <div className={styles.sdij2d}>
            <div className={styles.left}>
              {/* <div
                onClick={() => onChangePayType('paypal')}
                className={classNames(styles.play, {
                  [styles.active]: payType === 'paypal',
                })}
              >
                <img src={require('./images/papal.png').default} />{' '}
                <span>paypal支付</span>
              </div> */}
              <div
                onClick={() => onChangePayType('pay')}
                className={classNames(styles.play, {
                  [styles.active]: payType === 'pay',
                })}
              >
                <img src={require('./images/zfb.png').default} />{' '}
                <span>支付宝国际支付</span>
              </div>
              <div
                onClick={() => onChangePayType('pay_dl', true)}
                className={classNames(styles.play, {
                  [styles.active]: payType === 'pay_dl',
                })}
              >
                <img src={require('./images/zfb.png').default} />{' '}
                <span>支付宝大陆支付</span>
              </div>
              <div
                onClick={() => onChangePayType('wPay')}
                className={classNames(styles.play, {
                  [styles.active]: payType === 'wPay',
                })}
              >
                <img src={require('./images/wx.png').default} />{' '}
                <span>微信国际支付</span>
              </div>
              <div
                onClick={() => onChangePayType('wPay_dl')}
                className={classNames(styles.play, {
                  [styles.active]: payType === 'wPay_dl',
                })}
              >
                <img src={require('./images/wx.png').default} />{' '}
                <span>微信大陆支付</span>
              </div>
              {/* <div
                onClick={() => onChangePayType('uPay')}
                className={classNames(styles.play, {
                  [styles.active]: payType === 'uPay',
                })}
              >
                <img src={require('./images/yl.png').default} />{' '}
                <span>银联支付</span>
              </div> */}
            </div>
            <div className={styles.right}>
              {getContent()}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
export default connect(
  (state) => ({
    user: state.user.userData,
    chargeData: state.modelState.charge,
  }),
  (dispatch) => ({
    setLoading(bool) {
      dispatch({
        type: 'g_loading/setGLoading',
        payload: bool,
      });
    },
    updateUserData() {
      dispatch({
        type: 'user/getUserData',
      });
    },
  })
)(Charge);
