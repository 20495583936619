import React from 'react';
import classnames from 'classnames';
import RegisterPhoneForm from './RegisterPhoneForm';
import RegisterEmailForm from './RegisterEmailForm';
import styles from './index.module.scss';

export default function Register({
  setCurrentRegisterType,
  currentRegisterType,
}) {
  const isPhonepage = currentRegisterType == 'phone';
  const isEmailpage = currentRegisterType == 'email';

  const getContent = () => {
    if (isPhonepage) {
      return <RegisterPhoneForm />;
    }
    if (isEmailpage) {
      return <RegisterEmailForm />;
    }
  };
  return (
    <div className={classnames(styles.register)}>
      <div className={styles['btns']}>
        <div
          onClick={() => setCurrentRegisterType('phone')}
          className={classnames({ [styles.active]: isPhonepage })}
        >
          手機註冊
        </div>
        <div
          onClick={() => setCurrentRegisterType('email')}
          className={classnames({ [styles.active]: isEmailpage })}
        >
          郵箱註冊
        </div>
      </div>
      {getContent()}
      <div className={styles.msg}>
        <p>登錄或完成賬號註冊表示您已閱讀並同意亞視</p>
        <p>《服務條款》和 《隱私政策》</p>
      </div>
    </div>
  );
}
