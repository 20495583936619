import React, { useState } from 'react';
import Input from '../../../components/Input';
import styles from './index.module.scss';
import Toask from '@/components/Toast';
import GetCode from '../GetCode';
import PhoneInput from '@/components/PhoneInput';
import check from '@/utils/checkFrom';
import { connect } from 'dva';
import classnames from 'classnames';
import SliderCheck from '../../../components/SliderCheck';

function RegisterPhoneForm({ dispatch, setCurrentLoginType, setcurrentPage }) {
  const [isShowCheckSlider, setIsShowCheckSlider] = useState(false);
  const [formValue, setFormValues] = useState({
    pwdType: 'password',
    phone: { value: '', area: 852 },
    code: '',
    password: '',
    anginPwd: '',
    setpwdType: (_) =>
      setFormValues((cur) => {
        return {
          ...cur,
          pwdType: cur.pwdType == 'text' ? 'password' : 'text',
        };
      }),
    setPhone: (data) => {
      setFormValues((cur) => {
        return {
          ...cur,
          phone: { ...cur.phone, ...data },
        };
      });
    },
    setCode: ({ target: { value: code } }) => {
      setFormValues((cur) => {
        return {
          ...cur,
          code,
        };
      });
    },
    setPassword: ({ target: { value: password } }) => {
      setFormValues((cur) => {
        return {
          ...cur,
          password,
        };
      });
    },
    setAnginPwd: ({ target: { value: anginPwd } }) => {
      setFormValues((cur) => {
        return {
          ...cur,
          anginPwd,
        };
      });
    },
  });
  const checkForm = ({
    phone = '',
    code = '',
    password = '',
    anginPwd = '',
  }) => {
    const fd = check([
      {
        targetStr: phone,
        rules: {
          isNull: {
            params: '請輸入手機號',
          },
        },
      },
    ]);
    if (fd !== true) return fd;
    let sdf = check([
      {
        targetStr: code,
        rules: {
          isNull: {
            params: '請輸入驗證碼',
          },
        },
      },
      {
        targetStr: code,
        rules: {
          minLength: {
            params: [6, '驗證碼長度不能小於 6 位'],
          },
        },
      },
    ]);
    if (sdf !== true) return sdf;
    let affsdfsd = check([
      {
        targetStr: password,
        rules: {
          isNull: {
            params: '請輸入密碼',
          },
        },
      },
      {
        targetStr: password,
        rules: {
          minLength: {
            params: [6, '密碼長度不可以小於 6 位'],
          },
        },
      },
    ]);
    if (affsdfsd !== true) return affsdfsd;

    let adfjiodf = check([
      {
        targetStr: anginPwd,
        rules: {
          isNull: {
            params: '請再次輸入密碼',
          },
        },
      },
    ]);
    if (adfjiodf !== true) return adfjiodf;
    else {
      if (anginPwd !== password) {
        return {
          state: false,
          msg: '前後輸入的密碼不一致',
        };
      }
      return true;
    }
  };
  const onCheck = () => {
    // 校验
    const state = checkForm({
      phone: formValue.phone.value,
      code: formValue.code,
      password: formValue.password,
      anginPwd: formValue.anginPwd,
    });
    if (state !== true) {
      Toask(state.msg);
      return false;
    }
    return true;
  };
  const onFindPwd = async () => {
    if (!onCheck()) return;
    // 移交给 dva

    dispatch({
      type: 'user/codoLogin',
      payload: {
        Operation: 2,
        VerifyCode: formValue.code + '',
        Password: formValue.password,
        Kind: 1,
        Mobile: formValue.phone.value,
        type: 'findPwd',
      },
    }).then((d) => {
      if (d === true) {
        setcurrentPage('login');
        setCurrentLoginType('user');
      }
    });
  };

  const onSend = async ({ sig, csessionid, value, token, ...data }) => {
    const res = await dispatch({
      type: 'user/sendCode',
      payload: {
        Sig: sig,
        Token: token,
        SessionId: csessionid,
        Mobile: formValue.phone.value,
        Operation: 2,
        Kind: 1,
      },
    });
    return res;
  };
  return (
    <div
      className={classnames(styles.content)}
      onKeyDown={(e) => {
        if (e.keyCode === 13) onFindPwd();
      }}
    >
      <PhoneInput value={formValue.phone} onChange={formValue.setPhone} />
      <p className={classnames(styles.sdfsd)}></p>
      <Input
        onChange={formValue.setCode}
        value={formValue.code}
        placeholder='請輸入驗證碼'
        getRightIcon={() => (
          <GetCode
            phone={formValue.phone.value}
            onStart={(_) => setIsShowCheckSlider(true)}
          />
        )}
      />

      <p className={classnames(styles.sdfsd)}></p>
      <Input
        onChange={formValue.setPassword}
        value={formValue.password}
        getRightIcon={() => {
          return (
            <img
              src={
                require(`../image/${
                  formValue.pwdType == 'text' ? 'yj' : 'by'
                }.png`).default
              }
            />
          );
        }}
        type={formValue.pwdType}
        placeholder='請輸入登錄密碼'
        onClickIcon={formValue.setpwdType}
      />
      <p className={classnames(styles.sdfsd)}></p>
      <Input
        onChange={formValue.setAnginPwd}
        value={formValue.anginPwd}
        type={formValue.pwdType}
        onClickIcon={formValue.setpwdType}
        placeholder='請確認登錄密碼'
        getRightIcon={() => {
          return (
            <img
              src={
                require(`../image/${
                  formValue.pwdType == 'text' ? 'yj' : 'by'
                }.png`).default
              }
            />
          );
        }}
      />
      <div
        onClick={() => {
          onFindPwd && onFindPwd();
        }}
        className={styles.btn_login}
      >
        找回密碼
      </div>
      {isShowCheckSlider ? (
        <SliderCheck
          onEndCheck={(data) => {
            setIsShowCheckSlider(false);
            onSend(data);
          }}
        />
      ) : null}
    </div>
  );
}
export default connect()(RegisterPhoneForm);
