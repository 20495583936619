// 管理全局加载状态
export default {
  namespace: 'g_loading',
  state: {
    g: false,
  },
  reducers: {
    setGLoading(state, { payload }) {
      return {
        ...state,
        g: payload,
      };
    },
  },
};
