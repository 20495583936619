import React, { useState } from 'react';
import { withRouter } from 'dva/router';
import { connect } from 'dva';
import Toast from '@/components/Toast';
import { getAvatar, isLogin, isVip } from '@/utils';
import classNames from 'classnames';
import Link from '@/components/Link';
import './index.scss';

function Top({
  userData = {},
  className,
  setLoginOrRegister,
  searchResource,
  setCharge,
  outLogin,
  ...props
}) {
  const [searchVal, setSearchVal] = useState('');
  const onGoToUser = (url) => {
    if (isLogin()) {
      props.history.push(url);
      return;
    }
    setLoginOrRegister({
      state: true,
    });
  };
  const onSearch = async () => {
    if (!isLogin()) {
      Toast('請先登錄');
      return;
    }
    props.history.push({
      pathname: '/search',
      search: '?key=' + searchVal,
    });
  };
  return (
    <>
      <div className='top_box'>
        <div className='top'>
          <img
            onClick={() => props.history.push('/')}
            src={require('@/assets/images/logo.png').default}
            alt=''
          />
          <div className='search'>
            <input
              type='text'
              maxLength={50}
              value={searchVal}
              onChange={(e) => setSearchVal(e.target.value)}
            />
            <div className='btn' onClick={onSearch}>
              <img src={require('./images/search.png').default} />
            </div>
          </div>
          <ul className='function_fdsf232'>
            {/* <Link
              tag='li'
              className='btn'
              onClick={() =>
                (window.location.href = 'https://apps.deernetwork.org/#/nft')
              }
            >
              <img src={require('./images/nftsc.png').default} />
              <span>NFT商城</span>
            </Link> */}
            <Link
              tag='li'
              className='btn'
              onClick={() => {
                if (isLogin()) {
                  setCharge();
                  return;
                }
                setLoginOrRegister();
              }}
            >
              <img src={require('./images/hy.png').default} />
              <span>
                {userData && isVip(userData) ? '續費會員' : '開通會員'}
              </span>
            </Link>
            {/* <Link
              tag='li'
              className='btn'
              onClick={() => onGoToUser('/pan/files/0')}
            >
              <img src={require('./images/pan.png').default} />
              <span>云盘</span>
            </Link> */}
            <Link
              tag='li'
              className='btn'
              onClick={() => onGoToUser('/history/annals')}
            >
              <img src={require('./images/jl.png').default} />
              <span>播放記錄</span>
            </Link>
            <Link
              tag='li'
              className='btn'
              onClick={() => onGoToUser('/history/collect')}
            >
              <img src={require('./images/sc.png').default} />
              <span>收藏</span>
            </Link>
            <Link
              tag='li'
              className='btn'
              onClick={() => window.open('https://missasia.hkatv.vip/')}
            >
              <img
                src={require('./images/yj.png').default}
                style={{
                  border: '1px solid #999',
                  borderRadius: '50%',
                  boxSizing: 'border-box',
                }}
              />
              <span>亚姐报名</span>
            </Link>
            <Link
              tag='li'
              className={classNames('btn', 'out_login', {
                user: userData === null,
              })}
            >
              <img
                src={getAvatar()}
                onClick={() => onGoToUser('/history/annals')}
              />
              <span onClick={() => outLogin()}>
                {userData === null ? null : '退出登錄'}
              </span>
            </Link>
          </ul>
        </div>
      </div>
      {/*<div className='top_box_bto'></div>*/}
    </>
  );
}
export default withRouter(
  connect(
    (state) => ({
      userData: state.user.userData,
    }),
    (dispatch) => {
      return {
        setLoginOrRegister() {
          dispatch({
            type: 'modelState/setLoginOrRegister',
            payload: {
              state: true,
              data: {},
            },
          });
        },
        setCharge() {
          dispatch({
            type: 'modelState/setCharge',
            payload: {
              state: true,
              data: {
                buySomething: 'member',
              },
            },
          });
        },
        outLogin() {
          dispatch({
            type: 'user/outLogin',
          });
        },
        searchResource(data) {
          dispatch({
            type: 'screen/searchResource',
            payload: data,
          });
        },
      };
    }
  )(Top)
);
