import React, { useEffect, useRef, useState } from 'react';
import { definitionList, getClassDom, getTimer, speedList } from './relyon';
import Hls from 'hls.js';
import Loading from './Loading';
import className from 'classnames';
import { withRouter } from 'dva/router';
import './index.scss';

let hls;
function addScreenChangeEvent(callback, getClassDom) {
  const f = () => {
    const isFullScreen =
      document.fullScreen ||
      document.mozFullScreen ||
      document.webkitIsFullScreen ||
      document.msFullscreenElement;
    if (!isFullScreen) {
      callback();
      getClassDom('full_screen').src = require('./images/qp.png').default;
    } else {
      getClassDom('full_screen').src = require('./images/sx.png').default;
    }
  };
  document.addEventListener('fullscreenchange', function () {
    f();
  });
  document.addEventListener('webkitfullscreenchange', function () {
    f();
  });
  document.addEventListener('mozfullscreenchange', function () {
    f();
  });
  document.addEventListener('MSFullscreenChange', function () {
    f();
  });
}

function Player({
  src: srcData,
  source = {},
  advertUrl = {},
  videoRef,
  inputFocus,
  onAdvert,
  onSullScreen,
  onExitFullscreen,
  onChangeLegibility,
  ...props
}) {
  if (!srcData) return null;
  let videoUrl = srcData?.src || srcData;
  let videoLoad = useRef(true); // 视频加载
  const [speed, setSpeed] = useState(1); // 倍速
  const [definition, setDefinition] = useState('480'); // 清晰度
  const [voice, setVoice] = useState(null); // 音量
  const [controlShow, setControlShow] = useState(null); // s-倍速 d-清晰度 v-音量
  const [voiceTop, setVoiceTop] = useState(0); // 音量调节器
  const [videoPause, setVideoPause] = useState(true); // 视频暂停
  const [controlOpen, setControlOpen] = useState(false); // 控制器开关
  const [barCache, setBarCache] = useState(0); // 缓存进度
  const [playRate, setPlayRate] = useState(0); // 播放进度
  const [timeBer, setTimeBar] = useState(null); // 进度时间显示
  const [duration, setDuration] = useState(0); // 视频总时间
  const [advVideoShow, setAdvVideoShow] = useState(true); // 视频广告
  const [advImageShow, setAdvImageShow] = useState(false); // 图片广告
  const [advVideoTime, setAdvVideoTime] = useState(null); // 视频广告时间
  const [timeupdate, setTimeupdate] = useState(false); // 视频计时开关

  const loadSource = (srcUrl) => {
    const [url, search = ''] = srcUrl.split('?');
    if (Hls.isSupported()) {
      hls = new Hls({
        xhrSetup: function (xhr, urls) {
          xhr.open('GET', urls.indexOf('?') > -1 ? urls : `${url}?${search}`);
        },
      });

      hls.loadSource(url);
      hls.attachMedia(videoRef.current);
      setTimeout(() => {
        if (videoLoad.current) videoLoad.current = false;
      }, 3000);
    }
  };

  const initVolume = (video) => {
    const v = video.volume * 100;
    setVoice(v);
    if (v === 100) setVoiceTop(132);
    else setVoiceTop(-(132 * (v / 1000)));
  };

  const initVideo = () => {
    const video = getClassDom('video_player');

    video.onwaiting = () => {
      videoLoad.current = true;
      props.onPause && props.onPause();
      if (timeupdate) setTimeupdate(false);
    };
    video.oncanplay = () => {
      props.onPlay && props.onPlay(video);
    };
    video.addEventListener('pause', () => {
      setVideoPause(true);
    });
    video.addEventListener('playing', () => {
      if (videoLoad.current) {
        videoLoad.current = false;
      }
      if (videoPause) setVideoPause(false);
      if (!timeupdate) setTimeupdate(true);
    });

    addScreenChangeEvent(() => {
      onExitFullscreen && onExitFullscreen();
    }, getClassDom);

    initVolume(video);
  };

  // 打开视频广告
  useEffect(() => {
    setAdvVideoShow(!!advertUrl?.begin);
    initVideo();
  }, []);

  const handleTimer = (event) => {
    if (!event) return;
    const dt = Math.floor(event.duration);
    const ct = Math.floor(event.currentTime);
    if (dt - ct <= 0 || isNaN(dt - ct)) {
      const video = getClassDom('video_advert');
      video.src = null;
      setAdvVideoShow(false);
      onAdvert && onAdvert('time', ct);
    }
    if (!isNaN(dt - ct)) {
      setAdvVideoTime({ dt, ct, t: dt - ct });
      onAdvert && onAdvert('setTime', ct);
    }
  };

  useEffect(() => {
    if (!advVideoTime) return;
    const timer = setTimeout(() => {
      const video = getClassDom('video_advert');
      if (video.currentTime) handleTimer(video);
    }, 1000);
    return () => clearTimeout(timer);
  }, [advVideoTime]);

  // 赋值广告时间
  const handleSetAdvTime = () => {
    const video = getClassDom('video_advert');
    if (isNaN(video.duration)) {
      const timeout = setTimeout(() => {
        handleSetAdvTime();
        clearTimeout(timeout);
      }, 50);
    } else {
      video.addEventListener('playing', (e) => handleTimer(e.target));
    }
  };

  useEffect(() => {
    return () => {
      const video = getClassDom('video_advert');
      video.src = null;
    };
  }, []);

  // 播放视频广告
  useEffect(() => {
    if (advVideoShow) {
      const video = getClassDom('video_advert');
      video.src = advertUrl?.begin;
      handleSetAdvTime();
    }
  }, [advVideoShow]);

  // 初始化清晰度
  const handleInitDefinition = () => {
    const { value } =
      definitionList.find((i) => videoUrl.indexOf(i.value) >= 0) || {};
    setDefinition(value || '480');
  };

  // 赋值时间
  const handleSetDuration = () => {
    const video = getClassDom('video_player');
    if (isNaN(video.duration)) {
      const timeout = setTimeout(() => {
        handleSetDuration();
        clearTimeout(timeout);
      }, 50);
    } else {
      video.currentTime = srcData?.currentTime || 0;
      setDuration(Math.floor(video.duration));
    }
  };

  // 播放视频
  useEffect(() => {
    if (videoUrl && !advVideoShow) {
      loadSource(videoUrl);
      handleInitDefinition();
      handleSetDuration();
    }
    return () => hls && hls.destroy();
  }, [videoUrl, advVideoShow]);

  //进入全屏
  const FullScreen = () => {
    var ele = document.documentElement;
    if (ele.requestFullscreen) {
      ele.requestFullscreen();
    } else if (ele.mozRequestFullScreen) {
      ele.mozRequestFullScreen();
    } else if (ele.webkitRequestFullScreen) {
      ele.webkitRequestFullScreen();
    }
    onSullScreen && onSullScreen();
  };

  //退出全屏
  const exitFullscreen = () => {
    var de = document;
    if (de.exitFullscreen) {
      de.exitFullscreen();
    } else if (de.mozCancelFullScreen) {
      de.mozCancelFullScreen();
    } else if (de.webkitCancelFullScreen) {
      de.webkitCancelFullScreen();
    }
    onExitFullscreen && onExitFullscreen();
  };

  const onPause = () => {
    setAdvImageShow(true);
    if (videoLoad.current) {
      videoLoad.current = false;
    }
    props.onPause && props.onPause(true);
    getClassDom('video_player').pause();
    setVideoPause(true);
  };

  const onPlay = () => {
    setAdvImageShow(false);
    getClassDom('video_player').play();
    props.onPlay && props.onPlay(getClassDom('video_player'));
    setVideoPause(false);
  };

  useEffect(() => {
    document.onkeydown = (e) => {
      const video = getClassDom('video_player');
      if (e.key === ' ' && !inputFocus) {
        e.preventDefault();
        if (video.paused) onPlay();
        else onPause();
      } else if (e.keyCode === 39) {
        const targetTime = video.currentTime + 3;
        video.currentTime = targetTime > duration ? duration : targetTime;
      } else if (e.keyCode === 37) {
        const targetTime = video.currentTime - 3;
        video.currentTime = targetTime < 0 ? 0 : targetTime;
      }
    };
  }, [inputFocus]);

  // 获取进度
  const handleGetSpeed = () => {
    const video = getClassDom('video_player');
    const { currentTime, buffered } = video || {};
    if (isNaN(buffered?.length) || buffered?.length - 1 < 0) return;
    let buff = buffered.end(buffered.length - 1);
    buff = ((buff / duration) * 100).toFixed(2);
    setPlayRate(currentTime);
    setBarCache(`${buff}%`);
  };

  useEffect(() => {
    if (videoPause || !timeupdate || videoLoad.current) return;
    const timeout = setTimeout(() => handleGetSpeed(), 1000);
    return () => clearTimeout(timeout);
  }, [playRate, videoPause, timeupdate]);

  // 倍速
  useEffect(() => {
    if (isNaN(Number(speed))) return;
    getClassDom('video_player').playbackRate = Number(speed);
  }, [speed]);

  const handleMouseEnter = (key) => {
    controlShow !== key && setControlShow(key);
  };

  const handleMouseLeave = (key) => {
    controlShow === key && setControlShow(null);
  };

  const handleMouseDown = (ev) => {
    const y1 = ev.clientY;
    const t = getClassDom('bar_mine').offsetTop;

    //给可视区域添加鼠标的移动事件
    getClassDom('video_voice').onmousemove = function (eve) {
      const y2 = eve.clientY;
      const y = y2 - y1;
      let lt = y + t + '';
      lt = lt.replace('-', ' ') * 1;
      if (lt >= 132) lt = 132;
      if (lt <= 14) lt = 14;
      setVoiceTop(lt);
      const bfb = lt / 132;
      const num = Math.floor(((lt - 14) / 118) * 100);
      setVoice(num);
      getClassDom('video_player').volume = bfb.toFixed(1) * 1 - 0.1;
    };

    //清除
    getClassDom('video_voice').onmouseup = function () {
      getClassDom('video_voice').onmousemove = null;
    };
  };

  const handleScreen = () => {
    const isFullScreen =
      document.fullScreen ||
      document.mozFullScreen ||
      document.webkitIsFullScreen ||
      document.msFullscreenElement;
    if (isFullScreen) {
      exitFullscreen();
    } else {
      FullScreen();
    }
  };

  return (
    <div
      className={'video_box'}
      onClick={() => {
        if (videoLoad.current || !duration || advVideoShow) return;
        if (videoPause) onPlay();
        else onPause();
      }}
      onMouseMove={() => setControlOpen(true)}
      onMouseLeave={() => setControlOpen(false)}
    >
      <div
        className='advert_box'
        style={{ display: advVideoShow ? 'block' : 'none' }}
        onClick={(e) => {
          e.stopPropagation();
          onAdvert && onAdvert('jump');
        }}
      >
        {advVideoTime?.t && (
          <div
            onClick={(e) => {
              e.stopPropagation();
              onAdvert && onAdvert('vip');
            }}
          >
            <span>{String(advVideoTime?.t || 0).padStart(2, '0')}s</span>
            <span onClick={() => {}}>会员可关闭广告</span>
          </div>
        )}
        <video
          muted
          controls
          autoPlay
          className='video_advert'
          controlsList='nodownload nofullscreen noremoteplayback noplaybackrate'
          disablePictureInPicture={true}
        />
      </div>
      <video
        autoPlay
        width='100%'
        height='100%'
        className='video_player'
        style={{ display: advVideoShow ? 'none' : 'block' }}
        ref={videoRef}
      />
      {/* 视频控制台 */}
      <div className={'style'}>
        <div
          className='control'
          style={{
            display: controlOpen ? 'flex' : 'none',
          }}
          onClick={(e) => e.stopPropagation()}
        >
          {timeBer && (
            <div className='time_bar' style={{ left: timeBer?.left }}>
              {timeBer?.time}
            </div>
          )}
          <div
            className='line_bar'
            onMouseMove={(event) => {
              const current = event.nativeEvent.offsetX;
              const width = getClassDom('line_bar').offsetWidth;
              const duration = getClassDom('video_player').duration;
              const obj = {
                left: current - 24,
                time: getTimer(Math.floor((current / width) * duration)),
              };
              setTimeBar(obj);
            }}
            onMouseLeave={() => setTimeBar(null)}
            onMouseDown={(event) => {
              const video = getClassDom('video_player');
              const current = event.nativeEvent.offsetX;
              const width = getClassDom('line_bar').offsetWidth;
              const duration = getClassDom('video_player').duration;
              const currentTime = Math.floor((current / width) * duration);
              video.currentTime = currentTime;
              setPlayRate(currentTime);
            }}
          >
            <div className='video_cache' style={{ width: barCache }}></div>
            <div
              className='video_rate'
              style={{
                width: `${((playRate / duration) * 100).toFixed(2)}%`,
              }}
            ></div>
          </div>
          <div className='control_left'>
            <div
              className={className('video_progress', 'img')}
              onClick={() => {
                if (videoPause) {
                  onPlay();
                } else {
                  onPause();
                }
              }}
            >
              <img
                src={
                  require(!videoPause
                    ? './images/suspend.png'
                    : './images/play.png').default
                }
              />
            </div>
            <span className='time'>
              {getTimer(playRate)} / {getTimer(duration)}
            </span>
          </div>
          <div className={'control_right'}>
            <span
              className='qing_xi'
              onMouseEnter={() => handleMouseEnter('d')}
              onMouseLeave={() => handleMouseLeave('d')}
            >
              清晰度
              {controlShow === 'd' && (
                <div className='qingxidu_list'>
                  {definitionList.map((item) => {
                    return (
                      <span
                        key={item.value}
                        className={`${
                          item.value === definition ? 'active' : ''
                        }`}
                        onClick={() => {
                          onChangeLegibility({
                            src: source[item.value],
                            currentTime:
                              getClassDom('video_player').currentTime - 3,
                          });
                        }}
                      >
                        {item.label}
                      </span>
                    );
                  })}
                </div>
              )}
            </span>
            <span
              className='bei_su'
              onMouseEnter={() => handleMouseEnter('s')}
              onMouseLeave={() => handleMouseLeave('s')}
            >
              倍速
              {controlShow === 's' && (
                <div className='video_speed'>
                  {speedList.map((item) => {
                    return (
                      <span
                        key={item.value}
                        className={`${item.value === speed ? 'active' : ''}`}
                        onClick={() => setSpeed(item.value)}
                      >
                        {item.label}
                      </span>
                    );
                  })}
                </div>
              )}
            </span>
            <div
              className='sound'
              onMouseEnter={() => handleMouseEnter('v')}
              onMouseLeave={() => handleMouseLeave('v')}
            >
              <img src={require('./images/s.png').default} />
              {controlShow === 'v' && (
                <div className={'video_voice'}>
                  <span className='video_voice_num'>{voice}%</span>
                  <div className={'bar_line'}>
                    <div
                      className='bg_color'
                      style={{ height: `${voice}%` }}
                    ></div>
                    <div className={'line'}>
                      <div
                        className={'bar_mine'}
                        style={{ top: `-${voiceTop}px` }}
                        onMouseDown={(e) => handleMouseDown(e)}
                      ></div>
                    </div>
                  </div>
                </div>
              )}
            </div>
            <img
              className='img'
              src={require('./images/qp.png').default}
              onClick={() => handleScreen()}
            />
          </div>
        </div>
      </div>
      {/* 视频暂停 */}
      {videoPause && !videoLoad.current && (
        <div className='video_pause' onClick={() => onPlay()}>
          <img
            className={'mask_play'}
            src={require('./images/play.png').default}
          />
          <div
            className='pause_image'
            style={{
              display: advImageShow && advertUrl?.pause ? 'flex' : 'none',
            }}
          >
            <div
              onClick={(e) => {
                e.stopPropagation();
                onAdvert && onAdvert('jump');
              }}
            >
              <img src={advertUrl?.pause} />
              <div
                onClick={(e) => {
                  e.stopPropagation();
                  setAdvImageShow(false);
                }}
              >
                <img src={require('./images/close.png').default} />
              </div>
            </div>
          </div>
        </div>
      )}
      {/* 视频加载 */}
      {videoLoad.current && (
        <div className='video_loading'>
          <Loading />
        </div>
      )}
    </div>
  );
}
export default withRouter(Player);
