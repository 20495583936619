import React, { useEffect } from 'react';
import styles from './index.module.scss';
let play;
export default function Player({ url, onClose }) {
  const destory = () => {
    if (!play) return;
    play.dispose();
    const dom = document.getElementById('player-con');
    if (dom !== null) {
      dom.innerHTML = '';
    }
  };
  useEffect(() => {
    if (play !== undefined) {
      destory();
    }

    play = new window.Aliplayer({
      id: 'player-con',
      source: url,
      width: '100%',
      height: '100%',
    });
  }, [url]);
  return (
    <div
      className={styles.player}
      onClick={() => {
        onClose && onClose();
      }}
    >
      <div className={styles.content}>
        <div id='player-con' onClick={(e) => e.stopPropagation()}></div>
        <span className={styles.close}>X</span>
      </div>
    </div>
  );
}
