import React from 'react';
import { Switch, Route, Redirect } from 'dva/router';
import Top from '@/components/Top';
import useScrollTop from '../useHooks/useScrollTop';
import Footer from '@/components/Footer';
import Loading_g from '../components/Loading_g';
import classnames from 'classnames';
import { withRouter } from 'dva/router';
import { connect } from 'dva';

import Home from '@/pages/Home';
import Protocol from '@/pages/Protocol';
import Player from '@/pages/Player';
import Tv from '@/pages/Tv';
import Video from '@/pages/Video';
import Pan from '@/pages/Pan';
import History from '@/pages/History';
import Search from '@/pages/Search';
import DownLoad from '@/pages/DownLoad';

// 页面级路由控件
function Router({ dispatch, mussy, loading, g_loading, ...props }) {
  const pathname = props.location.pathname;
  // 保证每个新页面都在最顶部
  useScrollTop();
  const getTopClass = () => {
    const isHomePage = /^\/$/.test(pathname);
    if (isHomePage) {
      return ['_g_home-page', 'home'];
    }
    return '_g_home-page';
  };
  const getTop = () => {
    const r = ['/$', '/player', '/history', '/tv'].some((e) => {
      return new RegExp('^' + e).test(pathname);
    });

    if (r && mussy.isShowTop)
      return <Top className={classnames(getTopClass())} />;
    return null;
  };
  const getFooter = () => {
    const r = ['/$', '/player', '/history', '/tv'].some((e) => {
      return new RegExp('^' + e).test(pathname);
    });
    if (r && mussy.isShowBottom) return <Footer />;
    return null;
  };
  return (
    <div className='root_wrap'>
      {getTop()}
      <Switch>
        <Route path='/' exact component={Home} />
        <Route path='/protocol' exact component={Protocol} />
        <Route path='/player/:id' exact component={Player} />
        <Route path='/history' component={History} />
        <Route path='/pan' component={Pan} />
        <Route path='/search' component={Search} />
        <Route path='/download' component={DownLoad} />
        <Route path='/tv' component={Tv} />
        <Route path='/video/:id(\d+)' component={Video} />
        <Redirect to='/' />
      </Switch>
      {getFooter()}
      {/* 全局加载条 */}
      {loading['screen/searchResource'] ? <Loading_g /> : null}
      {g_loading ? <Loading_g /> : null}
    </div>
  );
}
export default withRouter(
  connect((state) => ({
    loading: state.loading.effects,
    g_loading: state.g_loading.g,
    mussy: state.mussy,
  }))(Router)
);
