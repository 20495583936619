import React, { useEffect, useState } from 'react';
import Item from '../History/components/Item';
import { fillDomWrapper } from '@/utils';
import Paging from '../../components/Paging';
import Top from '@/components/Top';
import Player from './Player';
import { connect } from 'dva';
import { getVideo } from '@/api';
import styles from './index.module.scss';
import './index.scss';

function Video(props) {
  const [pageData, setPageData] = useState({
    curPage: 1,
    limits: 18,
    maxShowPageNum: 6,
    total: 0,
    data: [],
    isShowVideo: false,
    videoUrl: '',
  });
  const id = Number(props.match.params.id);
  let listData = [];
  useEffect(() => {
    props.dispatch({
      type: 'g_loading/setGLoading',
      payload: true,
    });
    getVideo(id, pageData.curPage).then((data) => {
      listData = data.List || [];
      setPageData({
        ...pageData,
        total: data.TotalCount,
        data: data.List || [],
      });

      props.dispatch({
        type: 'g_loading/setGLoading',
        payload: false,
      });
    });
  }, [pageData.curPage]);
  const onGetToItem = (data) => {
    const { JobStatus, Hls1080p, Content } = data;
    setPageData({
      ...pageData,
      isShowVideo: true,
      videoUrl: Hls1080p && JobStatus === 'COMPLETE' ? Hls1080p : Content,
    });
  };
  const onChangePage = (page) => {
    setPageData({
      ...pageData,
      curPage: page,
    });
  };
  const listDatas = pageData.data.map((e) => {
    return (
      <Item
        {...e}
        key={e.ID}
        name={e.Title}
        url={e.VideoPreviewPic}
        onGetToItem={onGetToItem}
      />
    );
  });
  const listDoms = fillDomWrapper({
    arr: listDatas,
    colNum: 7,
    width: '10.9375vw',
  });
  return (
    <div className={styles.annal}>
      <Top />
      <div className={styles.content}>
        <ul>{listDoms}</ul>
      </div>
      <div className={styles.fjdsioj2}>
        <Paging
          changePageFun={onChangePage}
          maxShowPageNum={pageData.maxShowPageNum}
          currentPage={pageData.curPage}
          dataAllNum={pageData.total}
          limits={pageData.limits}
        />
      </div>
      {pageData.isShowVideo ? (
        <Player
          onClose={() => setPageData({ ...pageData, isShowVideo: false })}
          url={pageData.videoUrl}
        />
      ) : null}
    </div>
  );
}
export default connect(null)(Video);
