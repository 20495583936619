import React, { useEffect } from 'react';
import { getRandom } from '@/utils';
import { GetComments } from '@/api';
import './index.scss';

class Comment {
  constructor({ dom, config = {} }) {
    this.wrapperDom = dom;
    this.wrapperDomWidth = dom.offsetWidth;
    this.wrapperDomHeight = dom.offsetHeight;
    this.config = {
      fontSize: 21,
      opacity: 1,
      velocity: 1, // 每16毫秒  1px
      ...config,
    };
    this.commentTiers = [];
    this.comments = [[], [], [], [], [], [], [], [], []];
    this.commentsVip = [];
    Comment.getCommentWidth = () => dom.offsetWidth;
  }
  clearComments() {
    for (let i = 0; i < this.comments.length; i++) {
      for (let j = 0; j < this.comments[i].length; j++) {
        const commentItem = this.comments[i][j];
        this.comments[i][j] = undefined;
        commentItem.dom.remove();
      }
      this.comments[i].length = 0;
    }
  }
  setConfig(config) {
    this.config = {
      ...this.config,
      ...config,
    };
  }
  removeCommentItem(x, y) {
    this.comments[y].splice(x, 1);
  }
  getCommentOffset(dom) {
    const domWidth = dom.offsetWidth;
    const domHeight = dom.offsetHeight;
    let y;
    for (let i = 0; i < this.comments.length; i++) {
      const item = this.comments[i];

      if (item.length === 0) {
        return {
          top: domHeight * i,
          y: i,
          left: -domWidth,
        };
      }
      const footComment = item[item.length - 1];
      const footCommentLeft = parseInt(getStyle(footComment.dom).left);
      if (
        footCommentLeft <
        this.wrapperDomWidth - dom.offsetWidth - getRandom(25, 50)
      ) {
        y = i;
        break;
      }
    }
    if (y === undefined) {
      return false;
    }
    return {
      top: domHeight * y,
      left: -domWidth,
      y,
    };
  }
  stepCommitTimes() {}
  asyncAddComment(config) {
    const timer = setTimeout(() => {
      this.addComment(config);
    }, getRandom(1, 4) * 1000);
    this.commentTiers.push(timer);
  }
  addComment(config) {
    const commentItem = new CommentItem({ ...this.config, ...config });
    const { dom } = commentItem;

    this.wrapperDom.appendChild(dom);
    const offset = this.getCommentOffset(dom);
    if (offset === false) {
      dom.remove();
      this.asyncAddComment(config);
      return;
    }
    const { left, y, top } = offset;

    {
      commentItem.left = left;
      commentItem.top = top;
      commentItem.width = left;
      commentItem.y = y;
      commentItem.x = this.comments[y].length;
    }

    this.comments[y].push(commentItem);

    commentItem.start();
  }
}
function getStyle(ele) {
  var style = null;

  if (window.getComputedStyle) {
    style = window.getComputedStyle(ele, null);
  } else {
    style = ele.currentStyle;
  }

  return style;
}
class CommentItem {
  init() {
    this.dom.classList.add('comment_item');
    this.dom.style.color = this.color;
    this.dom.style.opacity = this.opacity;
    this.dom.style.fontSize = this.fontSize;
    this.dom.innerText = this.content;
    this.dom.classList.add('normal');
    this.dom.style.position = 'absolute';
    this.dom.style.top = 0 + 'px';
    this.dom.style.cursor = 'pointer';
    this.dom.style.left = this.getCommentWidth() + getRandom(5, 70) + 'px';
    this.dom.style.zIndex = '888';
    this.dom.style.opacity = 0;
    if (this.isVip) {
      this.dom.classList.add('is_vip');
      this.dom.style.border = '1px solid ' + this.color;
    }
    this.dom.onmouseenter = (e) => {
      this.dom.style.transition = '';
    };
    this.dom.onmouseleave = () => {};
    this.timer = null;
  }
  setLeft(left) {
    this.dom.style.left = left + 'px';
  }
  getCurrentLeft() {
    let currentLeft = getStyle(this.dom).left;
    if (currentLeft === '-999px') {
      currentLeft = this.getCommentWidth();
    } else {
      currentLeft = parseInt(currentLeft);
    }
    return currentLeft;
  }
  end() {
    setTimeout(() => {
      this.dom.remove();
    }, 20000);
  }
  start() {
    this.dom.style.opacity = 1;
    this.dom.style.top = this.top + 'px';
    this.end();
    this.dom.style.left = this.width - 10 + 'px';
  }
  constructor({
    color,
    velocity = '1',
    opacity = '1',
    fontSize = '16',
    content = '',
    getCommentWidth = Comment.getCommentWidth,
    priority,
    isVip,
  }) {
    this.color = color;
    this.velocity = velocity;
    this.opacity = opacity;
    this.priority = priority;
    this.isVip = isVip;
    this.getCommentWidth = getCommentWidth;
    this.fontSize = fontSize + 'px';
    this.dom = document.createElement('span');
    this.content = content;

    this.init();
  }
}
let comment;
let BarrageBox;
let prevStartTime = null;
function Barrage({
  id,
  videoId,
  on,
  comment_interval,
  fontSize,
  velocity,
  opacity,
  commentRef,
}) {
  const { start_timer, end_timer } = comment_interval;
  if (start_timer === undefined || end_timer === undefined) return null;
  if (on === false) {
    comment.clearComments();
    return null;
  }

  const fnApi = (start_timer, end_timer) => {
    (async function () {
      const res = await GetComments({
        Offset: 1,
        Limit: 500,
        Condition: {
          TargetType: 5,
          TargetID: id,
          VideoTimeStart: start_timer,
          VideoTimeEnd: end_timer,
          VideoID: videoId,
        },
      });
      res.CommentList = res.CommentList || [];
      res.CommentList.map((e) => {
        comment.addComment({
          content: e.Content,
          getCommentWidth: Comment.getCommentWidth,
          opacity,
          velocity,
          fontSize,
          color: e.Color ? e.Color : 'white',
        });
      });
    })();
  };
  useEffect(() => {
    comment = new Comment({
      dom: document.getElementsByClassName('barrage')[0],
    });
    BarrageBox.selfSend = (config) => {
      comment.addComment({
        getCommentWidth: Comment.getCommentWidth,
        isVip: true,
        ...config,
      });
    };
  }, []);
  useEffect(() => {
    if (prevStartTime === start_timer) return;
    fnApi(start_timer, end_timer);
    prevStartTime = start_timer;
  }, [start_timer, end_timer]);
  return <div ref={commentRef} className={'barrage'}></div>;
}
BarrageBox = React.memo(Barrage);
export default BarrageBox;
