import React, { useState, useEffect, useRef } from 'react';
import { updateFiles, createNetworkDiskFiles } from '@/api';
import IconFont from '@/components/IconFont';
import Toast from '@/components/Toast';
import Modal from '../Modal';
import Loading from '../Loading';
import { folder } from '../../assets';
import styles from './index.module.scss';

function ModalMove({
  data,
  allFiles,
  visible,
  onClose,
  samePid = true,
  onSuccess,
}) {
  const { ParentID, Level } = (data && data.length && data[0]) || {};

  let cPid = 0;
  let cLevel = 1;
  if (samePid) {
    cPid = ParentID;
    cLevel = Level;
  }

  const newFloderNameRef = useRef();
  const [list, setList] = useState();
  const [loading, setLoading] = useState(false);
  const [tree, setTree] = useState([]);
  const [pageId, setPageId] = useState(cPid);
  const [pageFolder, setPageFolder] = useState();
  const [pageLevel, setPageLevel] = useState(cLevel);
  const [isCreateFolder, setIsCreateFolder] = useState(false);
  const [newFolderName, setNewFolderName] = useState('新建文件夾');

  useEffect(() => {
    setPageId(cPid);
    setPageLevel(cLevel);
  }, [data]);

  useEffect(() => {
    getPageFolder(pageId);
    setTree(findNavTree(pageId, allFiles));
  }, [pageId, allFiles, data]);

  //根据pageId获取当前需要显示的文件夹
  const getPageFolder = (id) => {
    let arr = allFiles?.filter(
      (o) => o.ParentID === id && o.FileType === 'folder'
    );
    if (samePid) {
      arr = arr?.filter((i) => !data.some((j) => j.ID == i.ID));
    }
    setPageFolder(arr);
  };

  //根据pageId生成导航数
  const findNavTree = (pid, all) => {
    pid = parseInt(pid);
    let narTreeArr = [];
    const obj = all && all.length && all.find((item) => item.ID === pid); //找出开始选中项目的parent;
    if (obj) {
      const { ParentID: newPid, Level: newLevel, Name: newName, ID: id } = obj;
      const arr = findNavTree(newPid, all);
      arr.push({ id, name: newName, level: newLevel });
      narTreeArr = [].concat(arr);
    } else {
      narTreeArr = [].concat([{ id: 0, name: '我的云盘', level: 1 }]);
    }
    return narTreeArr;
  };

  //找出同级不包括自己的文件夹

  useEffect(() => {
    setList(data);
  }, [data]);

  const navTo = (o) => {
    const { id, level } = o || {};
    setPageLevel(level);
    setPageId(id);
  };

  const openFolder = (o) => {
    const { ID } = o || {};
    setPageId(ID);
  };

  const handleMove = () => {
    if (isCreateFolder) return;
    onClose();

    if (cPid === pageId) {
      Toast('不能移动至同级目录');
      return;
    }
    setLoading(true);
    const NetworkDiskFiles = data.map((item) => {
      const { ID } = item || {};

      const obj = { ID, Level: pageLevel, ParentID: pageId };
      return obj;
    });
    const body = { NetworkDiskFiles };
    updateFiles(body).then((res) => {
      setLoading(false);
      onSuccess();
    });
  };

  const handleCreateFolder = () => {
    setIsCreateFolder(true);
  };
  const isNameExist = (name) => {
    console.log('pageFolder==', pageFolder, name);
    return pageFolder.find((item) => item.Name === name);
  };

  const onCreateFolderConfirm = () => {
    const newName = newFloderNameRef?.current.value;
    if (!newName) {
      Toast('文件夾名字不能為空');
      return;
    }
    if (isNameExist(newName)) {
      Toast('存在重名文件');
      return;
    }
    setLoading(true);
    const NetworkDiskFiles = [
      {
        Name: newName,
        ParentID,
        FileType: 'folder',
        Level: pageLevel,
      },
    ];
    const body = { NetworkDiskFiles };
    createNetworkDiskFiles(body)
      .then((res) => {
        if (res.Code === 1000) {
          const id = res.IDs[0];
          setPageId(id);
          setIsCreateFolder(false);
          setLoading(false);
          onSuccess();
        }
      })
      .catch((err) => {
        setLoading(false);
      });
  };

  const onCreateFolderCancel = () => {
    setIsCreateFolder(false);
  };

  const handleClose = () => {
    setIsCreateFolder(false);
    onClose();
  };

  return (
    <>
      <Modal visible={visible} onClose={handleClose}>
        <div className={styles.modal_content}>
          <div className={styles.modal_header}>
            <div className={styles.modal_title}>移動到</div>
            <IconFont
              type='close'
              className={styles.modal_close}
              onClick={handleClose}
            />
          </div>
          <div className={styles.modal_container}>
            <div className={styles.bread_content}>
              <div className={styles.breadcrumb}>
                {tree.map((o, i) => {
                  const { name, id } = o;
                  return (
                    <div
                      className={styles.breadcrumb_item}
                      key={i}
                      onClick={() => navTo(o)}
                    >
                      <div className={styles.breadcrumb_item_link}>{name}</div>
                      <IconFont
                        type='right'
                        className={styles.breadcrumb_item_spearator}
                      />
                    </div>
                  );
                })}
              </div>
            </div>
            <div className={styles.page_folders}>
              {isCreateFolder && (
                <div className={styles.page_create_folder_item}>
                  <div className={styles.folder_img}>
                    <img src={folder} width='100%' />
                  </div>
                  <input
                    maxLength={22}
                    defaultValue={newFolderName}
                    ref={newFloderNameRef}
                    className={styles.create_folder_input}
                  />
                  <div className={styles.create_folder_btn}>
                    <IconFont
                      type='check-circle'
                      suffix='Filled'
                      className={styles.create_confirm}
                      onClick={() => onCreateFolderConfirm()}
                    />
                    <IconFont
                      type='close-circle'
                      suffix='Filled'
                      className={styles.create_cancel}
                      onClick={() => onCreateFolderCancel()}
                    />
                  </div>
                </div>
              )}
              {pageFolder &&
                pageFolder.length > 0 &&
                pageFolder.map((o, i) => {
                  const { Name } = o;
                  return (
                    <div
                      key={i}
                      className={styles.page_folder_item}
                      onClick={() => openFolder(o)}
                    >
                      <img src={folder} className={styles.folder_img} />
                      <span>{Name}</span>
                    </div>
                  );
                })}
            </div>
          </div>
          <div className={styles.modal_footer}>
            <div
              className={styles.new_folder}
              onClick={() => handleCreateFolder()}
            >
              <span>新建文件夾</span>
            </div>
            <div className={styles.footer_btn}>
              <div
                className={`${styles.move_btn} ${styles.modal_footer_btn}`}
                onClick={() => handleMove()}
              >
                <span>移動到此處</span>
              </div>
            </div>
          </div>
        </div>
      </Modal>
      <Loading visible={loading} />
    </>
  );
}
export default ModalMove;
