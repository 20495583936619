import { Tooltip } from 'antd';
import { connect } from 'dva';
import React, { useState } from 'react';
import IconFont from '@/components/IconFont';
import styles from './index.module.scss';

function TopNav({ pageId, navTree, onChange, ...props }) {
  const [spin, setSpin] = useState(false);
  
  const goBack = () => {
    if (pageId <= 0) return;
    props.history.goBack();
  };

  const goForward = () => {
    props.history.goForward();
  };

  return (
    <div className={styles.nav_info_line}>
      <div className={styles.nav_info_block}>
        <div className={styles.nav_icon}>
          <Tooltip
            placement='top'
            title={<span style={{ fontSize: '12px' }}>返回</span>}
          >
            <IconFont
              type='left'
              style={{ marginRight: '4px' }}
              onClick={() => goBack()}
            />
          </Tooltip>
          <Tooltip
            placement='top'
            title={<span style={{ fontSize: '12px' }}>前进</span>}
          >
            <IconFont
              type='right'
              style={{ margin: '0 4px' }}
              onClick={() => goForward()}
            />
          </Tooltip>
          <Tooltip
            placement='top'
            title={<span style={{ fontSize: '12px' }}>刷新</span>}
          >
            <IconFont
              type='sync'
              spin={spin}
              style={{ margin: '0 4px' }}
              onClick={() => {
                onChange('refresh');
                setSpin(true);
                setTimeout(() => {
                  setSpin(false);
                }, 1500);
              }}
            />
          </Tooltip>
        </div>
        <div className={styles.nav_divider}></div>
        <div className={styles.nav_info}>
          {navTree &&
            navTree.length > 0 &&
            navTree.map((item, i) => {
              const { id, name, level } = item || {};
              return (
                <div className={styles.nav_info_text} key={i}>
                  {i > 0 && <IconFont type='right' />}
                  <div>{name}</div>
                </div>
              );
            })}
        </div>
      </div>
      <div className={styles.search_block}></div>
    </div>
  );
}
export default connect()(TopNav);
