import React from 'react';
import styles from './index.module.scss';

function Mask(props) {
  const opacity = props.opacity || '.3';
  return (
    <div
      style={{ backgroundColor: `rgba(0, 0, 0,${opacity})` }}
      onClick={props.onClick}
      className={styles['mask']}
    >
      {props.content}
    </div>
  );
}

export default Mask;
