import React from 'react';
import className from 'classnames';
import UserLoginFrom from './UserLoginFrom';
import EmailLoginForm from './EmailLoginForm';
import PhoneLoginForm from './PhoneLoginForm';
import styles from './index.module.scss';

export default function Login({
  setCurrentLoginType,
  currentLoginType,
  onGoFindPwd,
}) {
  const getContent = () => {
    if (currentLoginType === 'user') {
      return <UserLoginFrom onGoFindPwd={onGoFindPwd} />;
    }
    if (currentLoginType === 'email') {
      return <EmailLoginForm onGoFindPwd={onGoFindPwd} />;
    }
    if (currentLoginType === 'phone') {
      return <PhoneLoginForm />;
    }
  };

  return (
    <div className={styles['login']}>
      <div className={styles['btns']}>
        <div
          onClick={() => setCurrentLoginType('user')}
          className={className(
            styles.user_login,
            {
              [styles.active]:
                currentLoginType === 'user' || currentLoginType == 'phone',
            },
            styles.user
          )}
        >
          賬號登錄
        </div>
        <div
          onClick={() => setCurrentLoginType('email')}
          className={className(styles['email-login'], {
            [styles.active]: currentLoginType === 'email',
          })}
        >
          Email登錄
        </div>
      </div>
      {getContent()}
      <div className={styles.msg}>
        <p>登錄或完成賬號註冊表示您已閱讀並同意亞視</p>
        <p>《服務條款》和 《隱私政策》</p>
      </div>
    </div>
  );
}
