import React from 'react';
import { loadingGif } from '../../assets';
import styles from './index.module.scss';

function Loading({ visible }) {
  if (visible) {
    return (
      <div className={styles.loading}>
        <div className={styles.loading_body}>
          <img src={loadingGif} width='100%' />
        </div>
      </div>
    );
  }
  return null;
}

export default Loading;
