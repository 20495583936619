import React, { useEffect, useState } from 'react';
// import { getCategoryService } from '@/api';
import { withRouter } from 'dva/router';
import { parseJSON } from '@/utils';
import classnames from 'classnames';
import { connect } from 'dva';
import './index.scss';

function Nav(props) {
  const { classifysItemText, loading, home: { classifys, nav: categoryService } } = props;
  const [videoNavData, setVideoNavData] = useState({
    state: 'loading',
    data: [],
  });

  useEffect(() => {
    if (categoryService.length) {
      setVideoNavData({
        state: 'idle',
        data: categoryService.filter((e) => {
          return e.ID !== 13 && e.ID !== 12;
        }),
      });
    }
  }, [JSON.stringify(categoryService)]);

  let content = [];
  if (!loading) {
    content = classifys.map((e) => {
      const { Introduce = '電視熱播 猜你最愛' } =
        parseJSON(e.Extra || '') || {};
      return {
        title: e.name,
        msg: Introduce,
        id: e.id,
      };
    });
    content.unshift({
      title: '推薦',
      msg: '電視熱播   猜你最愛',
      id: 'tj',
    });
  }
  const goTo = (id) => {
    props.goTo(id);
  };
  const getToVideoPage = (id) => {
    props.history.push('/video/' + id);
  };
  return (
    <div className='nav'>
      <div className='dian_shi' onClick={() => props.history.push('/tv')}>
        <img src={require('@/assets/images/tv-icon.png').default} />
      </div>
      <ul className='fdsdfsdf'>
        {content.map(({ title, msg, id }, i) => {
          return (
            <a
              key={id}
              onClick={() => goTo(id)}
              className={classnames({ active: classifysItemText === id })}
              to={id + ''}
            >
              <span className='bg'>ASIATV</span>
              <span
                className={classnames('title', {
                  global_active_text_fdji23: classifysItemText === id,
                })}
              >
                {title}
              </span>
              <span
                className={classnames('msg', {
                  global_active_text_fdji23: classifysItemText === id,
                })}
              >
                {msg}
              </span>
            </a>
          );
        })}
      </ul>
      <div className='nav_news'>
        <div className='nav_news_item'>
          {videoNavData.data.slice(0, 3).map((e) => {
            return (
              <span key={e.ID} onClick={() => getToVideoPage(e.ID)}>
                {e.Name}
              </span>
            );
          })}
        </div>
        <div className='nav_news_item'>
          {videoNavData.data.slice(3, 6).map((e) => {
            return (
              <span key={e.ID} onClick={() => getToVideoPage(e.ID)}>
                {e.Name}
              </span>
            );
          })}
        </div>
      </div>
    </div>
  );
}

export default withRouter(
  connect(
    (state) => {
      return {
        loading: state.loading.effects['screen/getHomeAggregation'],
        classifysItemText: state.screen.classifysItemText,
        home: state.screen.home,
      };
    },
    (dispatch) => ({
      goTo(type) {
        dispatch({
          type: 'screen/getHomeTopicData',
          payload: { classiFyID: type }
        });
      },
    })
  )(Nav)
);
