export const definitionList = [
  {
    label: '超清',
    value: '1080',
  },
  {
    label: '高清',
    value: '720',
  },
  {
    label: '流畅',
    value: '480',
  },
];

export const speedList = [
  {
    label: '2.0X',
    value: 2,
  },
  {
    label: '1.5X',
    value: 1.5,
  },
  {
    label: '1.0X',
    value: 1,
  },
  {
    label: '0.5X',
    value: 0.5,
  },
];

export const getTimer = (time) => {
  if (isNaN(Number(time))) return '00:00';
  const mm = String(Math.floor(time / 60)).padStart(2, '0');
  const ss = String(Math.floor(time % 60)).padStart(2, '0');
  return `${mm}:${ss}`;
};

export const getClassDom = (name) => {
  const res = document.getElementsByClassName(name)[0];
  if (!res) return {};
  return res;
};
