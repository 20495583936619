import React, { useEffect } from 'react';
import './index.scss';

export default function SliderCheck({ onEndCheck }) {
  useEffect(() => {
    var nc_token = [
      'FFFF0N00000000009938',
      new Date().getTime(),
      Math.random(),
    ].join(':');

    var nc = window.NoCaptcha.init({
      renderTo: '#nc',
      appkey: 'FFFF0N00000000009938',
      scene: 'nc_message_h5',
      token: nc_token,
      trans: { key1: 'code200' },
      elementID: ['usernameID'],
      is_Opt: 0,
      language: 'cn',
      timeout: 10000,
      retryTimes: 5,
      errorTimes: 5,
      inline: false,
      apimap: {},
      bannerHidden: false,
      initHidden: false,
      callback: function (data) {
        onEndCheck && onEndCheck({ ...data, token: nc_token });
      },
      error: function (s) {},
    });
    window.NoCaptcha.setEnabled(true);
    nc.reset(); //请务必确保这里调用一次reset()方法

    window.NoCaptcha.upLang('cn', {
      LOADING: '加載中...', //加载
      SLIDER_LABEL: '請向右滑動驗證', //等待滑动
      CHECK_Y: '驗證通過', //通过
      ERROR_TITLE: '非常抱歉，這出錯了...', //拦截
      CHECK_N: '驗證未通過', //准备唤醒二次验证
      OVERLAY_INFORM: '經檢測你當前操作環境存在風險，請輸入驗證碼', //二次验证
      TIPS_TITLE: '驗證碼錯誤，請重新輸入', //验证码输错时的提示
    });
  }, []);
  return (
    <div
      className='fdsafijo'
      style={{
        display: 'flex',
        alignItems: 'center',
        height: '160px',
        background: 'white',
      }}
    >
      <div
        id='__nc'
        style={{
          marginLeft: 'auto',
          marginRight: 'auto',
          width: '100%',
          height: '55px',
        }}
      >
        <div id='nc'></div>
      </div>
    </div>
  );
}
