import React from 'react';
import './paging.css';
/**
 *
 * @param {Number} currentPage 当前显示第几页
 * @param {Number} dataAllNum 总数据量
 * @param {Number} limits 每页显示多少条数据
 * @param {Number} maxShowPageNum 最多显示多少页码
 * @param {Function} changePageFun 改变页码时执行的回调函数
 */
export default function Paging(props) {
  if (props.dataAllNum == null) return null;
  const maxPageNumber = getMaxPageNumber(props);
  const minPageNum = getMinPageNum(props, props.maxShowPageNum); // 获取到最少的页码
  const maxPageNum = getMaxPageNum(props, minPageNum, props.maxShowPageNum);
  const ReactArr = []; // React元素数组
  for (let i = minPageNum; i <= maxPageNum; i++) {
    let span = (
      <span
        className={i === props.currentPage ? 'item active' : 'item'}
        onClick={() => {
          props.changePageFun(i);
        }}
        key={i}
      >
        {' '}
        {i}{' '}
      </span>
    );

    if (!(i < 1 || i > maxPageNumber)) ReactArr.push(span);
  }
  return (
    <>
      <span
        className={props.currentPage <= 1 ? 'item not-click' : 'item'}
        onClick={() => {
          props.changePageFun(1);
        }}
      >
        首页
      </span>

      <span
        className={props.currentPage <= 1 ? 'item not-click' : 'item'}
        onClick={() => {
          props.changePageFun(
            props.currentPage === 1 ? 1 : props.currentPage - 1
          );
        }}
      >
        上一页
      </span>

      {ReactArr}
      <span
        className={
          props.currentPage >= maxPageNumber ? 'item not-click' : 'item'
        }
        onClick={() => {
          props.changePageFun(
            props.currentPage === maxPageNumber
              ? maxPageNumber
              : props.currentPage + 1
          );
        }}
      >
        下一页
      </span>
      <span
        className={
          props.currentPage >= maxPageNumber ? 'item not-click' : 'item'
        }
        onClick={() => {
          props.changePageFun(maxPageNumber);
        }}
      >
        尾页
      </span>
      <span className='wei'>
        <i className='current-page'>{props.currentPage}</i>/
        <i>{maxPageNumber}</i>
      </span>
    </>
  );
}

function getMaxPageNumber(props) {
  return Math.ceil(props.dataAllNum / props.limits);
}

function getMinPageNum(props, maxPageNumber) {
  return props.currentPage - Math.floor(maxPageNumber / 2);
}
function getMaxPageNum(props, minPageNum, maxPageNumber) {
  return minPageNum + (maxPageNumber - 1);
}
