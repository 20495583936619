import { connect } from 'dva';
import { message } from 'antd';
import React, { useState, useEffect } from 'react';
import IconFont from '@/components/IconFont';
import { transByte, getFileTypeIcon } from '@/utils/helper';
import styles from './index.module.scss';

function Item({ item, ways, check, onOpen, onCheck, onContextMenu }) {
  const { FileType, Name, ID, EncodeType, Size } = item || {};
  const fileCover = getFileTypeIcon(item);
  const [clickNum, setClickNum] = useState(0);

  useEffect(() => {
    if (!clickNum) return;
    const times = clickNum >= 2 || FileType !== 'folder' ? 0 : 210;
    const timeout = setTimeout(() => {
      if (clickNum > 1 && FileType === 'folder') {
        onOpen(item);
      } else if (FileType !== 'folder') {
        onCheck(ID);
      } else if (clickNum === 1 && FileType === 'folder') {
        message.warning('雙擊打开文件夾');
      } else {
        message.warning('该文件或文件夹无法选择');
      }
      setClickNum(0);
    }, times);
    return () => clearTimeout(timeout);
  }, [clickNum]);

  const handleGetText = () => {
    if (FileType === 'folder') {
      return '文件夹';
    }
    const t = EncodeType === 1 ? '私有' : EncodeType === 2 ? '分享' : '公共';
    return `${t}|${FileType}|${transByte(Size)}`;
  };

  if (ways === 'list') {
    return (
      <div
        className={styles.item_lists}
        style={{ backgroundColor: check ? '#daf5ff' : '' }}
        onClick={() => setClickNum((e) => (e += 1))}
        onContextMenu={onContextMenu}
      >
        <IconFont
          type='check-circle'
          suffix='Filled'
          style={{
            color: '#1890ff',
            fontSize: '14px',
            opacity: FileType !== 'folder' && check ? 1 : 0,
          }}
        />
        <img src={fileCover} />
        <span style={{ marginRight: '2px' }}>[{handleGetText()}]</span>
        <div title={Name}>{Name}</div>
      </div>
    );
  }

  if (ways === 'icon') {
    return (
      <div
        className={styles.item_box}
        style={{ backgroundColor: check ? '#daf5ff' : '' }}
        onClick={() => setClickNum((e) => (e += 1))}
        onContextMenu={onContextMenu}
      >
        <IconFont
          type='check-circle'
          suffix='Filled'
          className={styles.item_box_icon}
          style={{ opacity: FileType !== 'folder' && check ? 1 : 0 }}
        />
        <img src={fileCover} className={styles.item_cover} />
        <div className={styles.item_name} title={Name}>
          {Name}
        </div>
      </div>
    );
  }

  return null;
}

export default connect()(Item);
