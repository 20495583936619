import React, { useEffect } from 'react';
import { connect } from 'dva';
import Nav from './components/Nav';
import TakeTurns from './components/TakeTurns';
import MainContent from './components/MainContent';
import './index.scss';

const Home = function Home({ dispatch, ...props }) {
  const type = props.match.params.type;

  useEffect(() => {
    props.getHomeAggregation();
  }, []);

  return (
    <div id='home'>
      <div className='primary'>
        <TakeTurns history={props.history} />
        <div className='left-mask'></div>
        <div className='top-mask'></div>
        <div className='right-mask'></div>
        <div className='bottom-mask'></div>
      </div>
      <div className='_w'></div>
      <div style={{ height: 35 }}></div>
      <Nav />
      <MainContent history={props.history} type={type} />
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    ...state,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getHomeAggregation() {
      dispatch({type: 'screen/getHomeAggregation', payload: {}});
    }
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Home);
