import React, { useEffect, useState } from 'react';
import styles from './index.module.scss';
import { connect } from 'dva';
import Item from '../../components/Item';
import { GetVideoHistory } from '@/api';
import { fillDomWrapper, getToken } from '../../../../utils';
// 历史播放页
function Annal({ loading, unLoading, ...props }) {
  const [items, setItems] = useState([]);
  useEffect(() => {
    (async function () {
      loading();
      const { Data } = await GetVideoHistory(getToken());
      const resArr = Data || [];
      unLoading();
      setItems(resArr);
    })();
  }, []);
  const onGetToItem = (data) => {
    props.history.push('/player/' + data.Video.ResourceID);
  };
  let lis = items.map((e, i) => {
    return (
      <Item
        {...e}
        onGetToItem={onGetToItem}
        key={e.ID || i}
        name={e.Resource.Name}
        PlayTime={e.PlayTime}
        Duration={e.Video.Duration}
        url={e.Resource.Preview}
      />
    );
  });
  lis = fillDomWrapper({ arr: lis, colNum: 7, width: '10.9375vw' });
  return (
    <div className={styles.annal}>
      <p className={styles.text}>全部</p>
      <ul>{lis}</ul>
    </div>
  );
}
export default connect(null, (dispatch) => ({
  loading() {
    dispatch({
      type: 'g_loading/setGLoading',
      payload: true,
    });
  },
  unLoading() {
    dispatch({
      type: 'g_loading/setGLoading',
      payload: false,
    });
  },
}))(Annal);
