import React from 'react';
import { withRouter } from 'dva/router';
import { notLoginIsEnterRoute } from '@/config';
import Toast from '@/components/Toast';
import { isLogin } from '@/utils';

function Link({ to, history, tag = 'a', staticContext, ...props }) {
  const dom = React.createElement(
    tag,
    {
      href: to,
      onClick: (e) => {
        if (to === undefined) return;
        e.preventDefault();
        e.stopPropagation();
        const res = notLoginIsEnterRoute().some((e) => {
          return new RegExp('^' + to).test(to);
        });
        if (res && !isLogin()) {
          Toast('請先登錄');
          return;
        }
        history.push(to);
      },
      ...props,
    },
    props.children
  );
  return dom;
}

export default withRouter(Link);
