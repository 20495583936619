import React from 'react';
import { CloseOutlined } from '@ant-design/icons';
import { connect } from 'dva';
import { getAvatar } from '@/utils';
import styles from './index.module.scss';

function Top({ onClose, onCloseV, user }) {
  const close = () => {
    onClose();
    onCloseV && onCloseV();
  };
  return (
    <div className={styles.top}>
      <div className={styles.left}>
        <img src={getAvatar()} />
        <span>賬號：{user.UserName}</span>
        {user.Level === 2 ? (
          <>
            <img
              className={styles.icon}
              src={require('../../images/hy.png').default}
            />
            <span>VIP会员 {user.VipExpire.split(' ')[0]} 到期</span>
          </>
        ) : null}
      </div>
      <div onClick={close} className={styles.right}>
        <CloseOutlined />
      </div>
    </div>
  );
}
export default connect(
  (state) => ({
    user: state.user.userData || {},
  }),
  (dispatch) => ({
    onClose() {
      dispatch({
        type: 'modelState/setCharge',
        payload: {
          state: false,
        },
      });
    },
  })
)(Top);
