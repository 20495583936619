import { Button, message, Modal, Table } from 'antd';
import { GetMyNetworkDiskShare, DeleteMyNetworkDiskShare } from '@/api';
import { copyText, transByte, getFileTypeIcon } from '@/utils/helper';
import React, { useEffect, useState } from 'react';
import { H5_URL } from '@/utils/constant';
import moment from 'moment';
import { connect } from 'dva';
import styles from './index.module.scss';

function MyShare({}) {
  const [datas, setDatas] = useState([]);
  const [Offset, setOffset] = useState(1);
  const [flag, setFlag] = useState(false);

  const handleDelete = (ID) => {
    Modal.confirm({
      title: '確認刪除該分享资源？',
      content: '（請仔細核對，確認無誤再操作）',
      okType: 'danger',
      okText: '刪除',
      cancelText: '取消',
      onOk: () => {
        DeleteMyNetworkDiskShare({
          IDs: [ID],
        }).then(() => {
          message.success('刪除成功');
          setFlag((e) => !e);
        });
      },
    });
  };

  const columns = [
    {
      width: 300,
      title: '分享文件',
      dataIndex: 'NetworkDiskFile',
      className: styles.tableCol,
      ellipsis: true,
      render: (e) => {
        if (!e) return '[文件已删除]';
        const { Name = '-' } = e || {};
        const fileIcon = getFileTypeIcon(e);
        return (
          <div className={styles.nameBox}>
            <img src={fileIcon} />
            <div title={Name}>{Name}</div>
          </div>
        );
      },
    },
    {
      width: 150,
      align: 'center',
      title: '文件参数',
      className: styles.tableCol,
      render: ({ NetworkDiskFile }) => {
        const { FileType = '', Size = '' } = NetworkDiskFile || {};
        return `${FileType.toLocaleUpperCase() || '-'} | ${transByte(Size)}`;
      },
    },
    {
      width: 100,
      align: 'center',
      title: '加密方式',
      className: styles.tableCol,
      render: ({ NetworkDiskFile }) => {
        const { EncodeType } = NetworkDiskFile || {};
        return EncodeType === 3 ? '公共' : '分享';
      },
    },
    {
      width: 100,
      align: 'center',
      title: '下载次数',
      dataIndex: 'DownloadCount',
      className: styles.tableCol,
    },
    {
      width: 100,
      align: 'center',
      title: '浏览次数',
      dataIndex: 'BrowseCount',
      className: styles.tableCol,
    },
    {
      width: 100,
      align: 'center',
      title: '分享时间',
      dataIndex: 'CreateAt',
      className: styles.tableCol,
      render: (e) => moment(e * 1000).format('YYYY-MM-DD HH:mm:ss'),
    },
    {
      width: 100,
      align: 'center',
      title: '过期时间',
      dataIndex: 'ExpireTime',
      className: styles.tableCol,
      render: (e) => moment(e * 1000).format('YYYY-MM-DD HH:mm:ss'),
    },
    {
      width: 100,
      align: 'center',
      title: '文件操作',
      className: styles.tableCol,
      render: (e) => (
        <>
          <Button
            type='link'
            disabled={!e.NetworkDiskFile}
            className={styles.btns}
            onClick={() => {
              const url = `${H5_URL}/netdisk/extract/${e.ID}/${e.ExpireTime}  提取码：${e.SharePassword}`;
              Modal.success({
                title: '分享链接',
                content: url,
                okText: '复制',
                onOk: () => copyText(url),
              });
            }}
          >
            分享
          </Button>
          <Button
            type='link'
            danger
            disabled={!e.NetworkDiskFile}
            className={styles.btns}
            onClick={() => handleDelete(e.ID)}
          >
            删除
          </Button>
        </>
      ),
    },
  ];

  const handleGetData = () => {
    GetMyNetworkDiskShare({
      Offset,
      Limit: 15,
    }).then((res) => {
      setDatas(res);
    });
  };

  useEffect(() => {
    handleGetData();
  }, [Offset, flag]);

  return (
    <Table
      columns={columns}
      size='small'
      loading={!datas.Data}
      dataSource={datas.Data || []}
      rowKey={({ ID }) => ID}
      pagination={{
        size: 'small',
        showTotal: (e) => `总数据条数${e}`,
        pageSize: 15,
        hideOnSinglePage: true,
        current: Offset,
        total: datas.TotalCount || 0,
        onChange: (page) => setOffset(page),
      }}
    />
  );
}
export default connect()(MyShare);
