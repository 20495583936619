import React, { useState } from 'react';
import ContentItemOne from '../ContentItemOne';
import { connect } from 'dva';
import { SpliceData, offsetIndexMove } from '../../../../utils';
import classNames from 'classnames';
import Loading from '@/components/Loading';
import './index.scss';

function ListOne(props) {
  const COUNT = 8;
  const [offset, setOffset] = useState({
    start: 0,
    end: COUNT,
  });
  const {
    title,
    isOneList = false,
    onToPlayer,
    onUpdate,
    loading,
    topicsDetailedsLoadingItem,
  } = props;

  const data = isOneList
    ? props.data
    : SpliceData({
        datas: props.data,
        maxLength: offset.end,
        startIndex: offset.start,
      });

  const onHuan = () => {
    if (loading) return;

    if (
      offset.end + COUNT > props.curCount * props.CurPage &&
      props.CurPage < props.TotalPage
    ) {
      onUpdate && onUpdate(props.index, props.id);
      setOffset({ start: offset.end, end: offset.end + COUNT });
    } else {
      const { startIndex, endIndex } = offsetIndexMove({
        start: offset.start,
        end: offset.end,
        num: COUNT,
        maxLength: props.data.length,
      });
      setOffset({
        start: startIndex,
        end: endIndex,
      });
    }
  };
  return (
    <div className='one content-list'>
      <div className='title'>
        <span className='title-text'>{title}</span>
        <div className={'huan_fds2s'} onClick={onHuan}>
          {isOneList ? null : (
            <>
              <div className={'huan_img_fdji2'}></div>
              <span>換壹換</span>
            </>
          )}
        </div>
      </div>
      <div className={classNames('content', { loading: loading })}>
        {loading && topicsDetailedsLoadingItem === props.id ? (
          <Loading />
        ) : (
          <ul
            className={'list' + (isOneList ? ' one_fds232' : '')}
            style={{ flexWrap: isOneList ? 'wrap' : 'nowrap' }}
          >
            {data.map((e) => {
              e = e || {};
              return (
                <ContentItemOne onToPlayer={onToPlayer} key={e.ID} {...e} />
              );
            })}
          </ul>
        )}
      </div>
    </div>
  );
}
export default React.memo(
  connect((state) => ({
    loading: state['loading']['effects']['screen/updateTopicsDetailedItem'],
    topicsDetailedsLoadingItem: state.screen.topicsDetailedsLoadingItem,
  }))(ListOne)
);
