import React from 'react';
import { connect } from 'dva';
import Policy from './Policy';
import Clause from './Clause';
import styles from './index.module.scss';

function Explain({ explainModelState, close }) {
  const exapli = (name) => {
    if (explainModelState.data.contentName === name) return true;
    return false;
  };
  const onClose = () => {
    close();
  };
  return (
    <div className={styles.explain}>
      <div className={styles.content}>
        <div className={styles.close} onClick={onClose}>
          {' '}
          X{' '}
        </div>
        {exapli('policy') ? <Policy /> : null}
        {exapli('clause') ? <Clause /> : null}
      </div>
    </div>
  );
}

export default connect(
  (state) => ({
    explainModelState: state.modelState.explain,
  }),
  (dispatch) => ({
    close() {
      dispatch({
        type: 'modelState/setExplain',
        payload: {
          state: false,
        },
      });
    },
  })
)(Explain);
