import React from 'react';
import Loading from '../Loading';
import styles from './index.module.scss';

export default function LoadingG() {
  return (
    <div className={styles.loading_g}>
      <Loading />
    </div>
  );
}
