import React, { useEffect, useState } from 'react';
import { GetNetworkDiskPayURL } from '@/api';
import { decrypt_with_aes } from '@/utils/helper';
import { Modal } from 'antd';
import styles from './index.module.scss';

function Previews({ urls, secret, onChange }) {
  const [index, setIndex] = useState(0);
  const [url, setUrl] = useState('');
  const [type, setType] = useState('');

  const handleGetUrl = () => {
    const { FileType, SignURL, ID, EncodeType } = urls[index];
    setType(FileType);
    if (FileType === 'img') {
      const x = new XMLHttpRequest();
      x.open('GET', SignURL, true);
      x.responseType = 'blob';
      x.onload = function () {
        const reader = new FileReader();
        reader.onload = function () {
          let resBase = reader.result;
          const { ShareKey, PrivateKey } = secret || {};
          const [base64, dense] = resBase.split('+atv+').flat().reverse();
          const key =
            EncodeType === 1 ? PrivateKey : EncodeType === 2 ? ShareKey : '';
          if (dense && key) {
            resBase = decrypt_with_aes(dense, key) + base64;
          } else {
            resBase = base64;
          }
          resBase = `data:${
            FileType === 'video' ? 'video/mp4' : 'image/png'
          };base64,${resBase}`;
          setUrl(resBase);
        };
        reader.readAsText(x.response);
      };
      x.send();
    } else {
      GetNetworkDiskPayURL({ ID }).then((res) => {
        const URL = res.URL || '';
        setUrl(URL);
      });
    }
  };

  useEffect(() => {
    if (urls.length) {
      handleGetUrl();
    }
  }, [urls, index]);

  return (
    <Modal
      footer={null}
      closable={false}
      destroyOnClose
      onCancel={() => {
        onChange();
        setUrl('');
      }}
      visible={Array.isArray(urls) && !!urls.length}
      className={styles.modalBody}
    >
      <div
        className={styles.pre_box}
        onClick={() => {
          onChange();
          setUrl('');
        }}
      >
        {type === 'img' ? (
          <img src={url} onClick={(e) => e.stopPropagation()} />
        ) : (
          <video
            src={url}
            width='520'
            height='360'
            controls
            onClick={(e) => e.stopPropagation()}
          />
        )}
      </div>
    </Modal>
  );
}

export default Previews;
