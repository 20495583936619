import React from 'react';
import styles from './index.module.scss';
function Item(props) {
  const { name, CoverURL, url, Duration, PlayTime } = props;
  return (
    <li
      className={styles.item}
      onClick={() => props.onGetToItem && props.onGetToItem(props)}
    >
      <div className={styles.wrapper}>
        <img src={url || CoverURL} />
        <span></span>
      </div>
      <p className={styles.name} title={name}>
        {name}
      </p>
      {typeof PlayTime === 'number' ? (
        <p className={styles.msg}>
          观看至{Math.floor((PlayTime / Duration) * 100)}%
        </p>
      ) : null}
    </li>
  );
}

export default Item;
