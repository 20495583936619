function fdsf({ targetStr, rules }) {
  const wrapper = {
    isNull(msg) {
      if (targetStr.length === 0 || targetStr === null) {
        return {
          state: false,
          msg,
        };
      }
      return {
        state: true,
      };
    },
    minLength(minLength, msg = `不得少于${minLength}位`) {
      const length = targetStr.length;
      if (length < minLength) {
        return {
          state: false,
          msg,
        };
      }
      return {
        state: true,
      };
    },
    maxLength(maxLegnth, msg = `不得大于${maxLegnth}位`) {
      const length = targetStr.length;
      if (maxLegnth > length) {
        return {
          state: false,
          msg,
        };
      }
      return {
        state: true,
      };
    },
    checkFormat(regexp, msg = `不满足格式`) {
      if (!regexp.test(targetStr)) {
        return {
          state: false,
          msg,
        };
      }
      return true;
    },
  };
  const ruleKeys = Object.keys(rules);
  let result = null;
  let isStep = false;
  ruleKeys.forEach((e, i) => {
    if (isStep) return;
    e = rules[e];
    let resulta = null;
    if (!Array.isArray(e.params)) {
      e.params = [e.params];
    }
    resulta = wrapper[ruleKeys[i]](...e.params);
    if (resulta.state) {
      result = true;
      return true;
    }
    isStep = true;
    result = resulta.msg;
  });

  return {
    state: typeof result !== 'string',
    msg: result,
  };
}
export default (arr) => {
  if (!Array.isArray(arr)) return fdsf(arr);
  let isStep;
  let w_result;
  arr.forEach((e) => {
    if (isStep) return;
    const result = fdsf(e);
    if (!result.state) {
      w_result = result;
      isStep = true;
    }
  });
  if (isStep === undefined) {
    return true;
  }
  return w_result;
};
// const a = fdsf({
//     targetStr: 'ff',
//     rules: {
//         minLength: {
//             params: [3, '长度不得少于三位'],
//             msg: '长度不得小于6'
//         }

//     }
// })
