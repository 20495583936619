import React, { useState, useEffect } from 'react';
import styles from './index.module.scss';
let toastMsg;
Toast.msg = (msg = '', isShow = true, time = 1500) => {
  toastMsg({
    msg,
    isShow,
    time,
  });
};
let timer;
export function Toast({ msg = '', isShow = false }) {
  const [data, setData] = useState({
    msg,
    isShow,
  });
  toastMsg = setData;
  useEffect(() => {
    clearTimeout(timer);
    timer = setTimeout(() => {
      setData({
        ...data,
        isShow: false,
      });
    }, data.time);
  }, [data.isShow]);
  return data.isShow ? (
    <span
      onClick={() => {
        setData({
          ...data,
          isShow: false,
        });
      }}
      className={styles.toast}
    >
      {data.msg}
    </span>
  ) : null;
}
export default Toast.msg;
