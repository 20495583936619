import { connect } from 'dva';
import { message } from 'antd';
import React, { useState, useEffect, useRef } from 'react';
import IconFont from '@/components/IconFont';
import Item from './item';
import UploadFiles from '../UploadFiles';
import styles from './index.module.scss';

function ContainerBox({
  iptRef,
  data = [],
  ways,
  pageId,
  onOpen,
  secret,
  showUpload,
  onChange,
}) {
  const boxRef = useRef();
  const [selectedIds, setSelectedIds] = useState([]); //被选中的文件/文件夹id数组
  const [selectedFiles, setSelectedFiles] = useState([]); //被选中的文件/文件夹信息数组

  const [stopDefaultEvent, setStopDefaultEvent] = useState(true);
  const [modalStyle, setModalStyle] = useState();
  const [itemContext, setItemContext] = useState(false);
  const [currentItem, setCurrentItem] = useState({});

  useEffect(() => {
    //根据被选中的文件/文件夹id数组获取被选中的文件/文件夹信息数组
    const arr =
      (data && data.filter((i) => selectedIds.some((j) => j == i.ID))) || [];
    setSelectedFiles(arr);
  }, [selectedIds]);

  useEffect(() => {
    onChange('checkId', selectedFiles);
  }, [selectedFiles]);

  const handleClick = () => {
    setStopDefaultEvent(true);
    setItemContext(false);
  };

  const handleChange = (id) => {
    let idsArr = [];
    if (selectedIds.includes(id)) {
      idsArr = selectedIds.filter((i) => i !== id);
    } else {
      idsArr = selectedIds.concat([id]);
    }
    setSelectedIds(idsArr);
  };

  const handleOpen = (o) => {
    onOpen(o);
    setSelectedIds([]);
  };

  const handleOnContextMenu = (e) => {
    if (e.button === 2) {
      e.preventDefault();
      const {
        current: { offsetTop, offsetLeft, offsetWidth, offsetHeight },
      } = boxRef || {};
      const _x = e.clientX - offsetLeft;
      const _y = e.clientY - offsetTop;
      let objX, objY;
      if (offsetWidth - _x < 170) {
        objX = { right: `${offsetWidth - _x}px` };
      } else {
        objX = { left: `${_x}px` };
      }
      if (offsetHeight - _y < 120) {
        objY = { bottom: `${offsetHeight - _y}px` };
      } else {
        objY = { top: `${_y}px` };
      }
      const obj = { ...objX, ...objY };
      setModalStyle(obj);
      if (stopDefaultEvent) {
        setStopDefaultEvent(false);
        setItemContext(false);
      }
    }
  };

  const handleOnItemContextMenu = (e, item) => {
    setSelectedIds([]);
    setStopDefaultEvent(true);
    setCurrentItem(item);
    e.stopPropagation();
    e.preventDefault();
    const {
      current: { offsetTop, offsetLeft, offsetWidth, offsetHeight },
    } = boxRef || {};
    const _x = e.clientX - offsetLeft;
    const _y = e.clientY - offsetTop;
    let objX, objY;
    if (offsetWidth - _x < 170) {
      objX = { right: `${offsetWidth - _x}px` };
    } else {
      objX = { left: `${_x}px` };
    }
    if (offsetHeight - _y < 120) {
      objY = { bottom: `${offsetHeight - _y}px` };
    } else {
      objY = { top: `${_y}px` };
    }
    const obj = { ...objX, ...objY };
    setModalStyle(obj);
    setItemContext(true);
  };

  const handleMove = () => {
    const includeSelectArr = selectedFiles.filter(
      (o) => o.ID === currentItem.ID
    );
    if (includeSelectArr.length) {
      onChange('moveto', selectedFiles);
      return;
    }
    onChange('moveto', [currentItem]);
  };

  return (
    <div
      ref={boxRef}
      className={styles.container_box}
      onContextMenu={handleOnContextMenu}
      onClick={() => handleClick()}
    >
      {data.map((item, i) => (
        <Item
          key={i}
          ways={ways}
          item={item}
          check={selectedIds.includes(item.ID)}
          onOpen={handleOpen}
          onCheck={handleChange}
          onContextMenu={(e) => handleOnItemContextMenu(e, item)}
        />
      ))}
      <div
        style={{
          display: showUpload && ways === 'icon' ? 'block' : 'none',
          float: 'left',
        }}
      >
        <UploadFiles
          iptRef={iptRef}
          secret={secret}
          props={{ ParentID: pageId || 0, saveDay: 180 }}
          onChange={(res) => onChange('uploadSuccess', res)}
        />
      </div>
      {!stopDefaultEvent && (
        <div className={styles.right_button_modal} style={modalStyle}>
          <div
            className={styles.right_button_modal_item}
            onClick={() => onChange('insert')}
          >
            <IconFont type='folder' className={styles.item_icon} />
            <span>新建文件夾</span>
          </div>
          <div
            className={styles.right_button_modal_item}
            onClick={() => onChange('upload')}
          >
            <IconFont type='cloud-upload' className={styles.item_icon} />
            <span>上傳文件</span>
          </div>
          <div
            className={styles.right_button_modal_item}
            onClick={() => onChange('refresh')}
          >
            <IconFont type='cloud-sync' className={styles.item_icon} />
            <span>刷新頁面</span>
          </div>
        </div>
      )}
      {itemContext && (
        <div className={styles.right_button_modal} style={modalStyle}>
          {currentItem.FileType !== 'folder' && (
            <>
              <div
                className={styles.right_button_modal_item}
                onClick={() => onChange('download', currentItem)}
              >
                <IconFont type='cloud-download' className={styles.item_icon} />
                <span>下載</span>
              </div>
              {['img', 'video'].includes(currentItem.FileType) && (
                <div
                  className={styles.right_button_modal_item}
                  onClick={() => onChange('preview', currentItem)}
                >
                  <IconFont type='folder-view' className={styles.item_icon} />
                  <span>預覽</span>
                </div>
              )}
              <div
                className={styles.right_button_modal_item}
                onClick={() =>
                  currentItem.EncodeType > 1
                    ? onChange('share', currentItem)
                    : message.warning('私有文件禁止分享')
                }
              >
                <IconFont type='share-alt' className={styles.item_icon} />
                <span>
                  分享{currentItem.EncodeType === 1 && '（私有禁止）'}
                </span>
              </div>
            </>
          )}
          <div
            className={styles.right_button_modal_item}
            onClick={() => onChange('rename', currentItem)}
          >
            <IconFont type='file-text' className={styles.item_icon} />
            <span>重命名</span>
          </div>
          <div className={styles.right_button_modal_item} onClick={handleMove}>
            <IconFont type='select' className={styles.item_icon} />
            <span>移動到</span>
          </div>
          <div
            className={styles.right_button_modal_item}
            onClick={() => onChange('delete', currentItem)}
          >
            <IconFont type='delete' className={styles.item_icon} />
            <span>刪除</span>
          </div>
        </div>
      )}
    </div>
  );
}
export default connect()(ContainerBox);
